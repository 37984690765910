import React, { ReactElement } from 'react';

const HighPriorityIcon = (): ReactElement => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.7164 0.40801L15.915 14.8388C16.0399 15.0887 16.0262 15.3828 15.8799 15.6206C15.7352 15.8552 15.4776 16 15.2003 16H0.800081C0.522744 16 0.265216 15.8552 0.118928 15.619C-0.0258365 15.3828 -0.039551 15.0887 0.0838795 14.8404L7.28399 0.40801C7.55524 -0.136003 8.44363 -0.136003 8.7164 0.40801ZM8.00019 2.55816L2.09382 14.3969H13.9066L8.00019 2.55816ZM8.00019 11.3325C8.55182 11.3325 8.99983 11.782 8.99983 12.3336C8.99983 12.8853 8.55182 13.3333 8.00019 13.3333C7.44857 13.3333 7.00056 12.8853 7.00056 12.3336C7.00056 11.782 7.44857 11.334 8.00019 11.334V11.3325ZM8.66611 6.66644V10.6665H7.33428V6.66644H8.66763H8.66611Z"
      fill="#DD3900"
    />
  </svg>
);

export default HighPriorityIcon;
