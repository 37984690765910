import { getType } from 'typesafe-actions';

import { setConfigAuthValues, setRefreshOrdersInterval } from '../actions/config.actions';

import { RootAction } from '../actions/types';
import { ConfigState } from './types';

const ONE_MINUTE = 60 * 1000;
const TWENTY_MINUTES = 20 * 60 * 1000;

const initialState: ConfigState = {
  authValues: null,
  refreshOrdersShortInterval: ONE_MINUTE,
  refreshOrdersLongInterval: TWENTY_MINUTES,
  satToSatEnabled: false,
  smartSourcingEnabled: false,
  osPickingLocationsEnabled: false,
  dsPickingLocationsEnabled: false,
  overstockPickingEnabled: false,
  storeToStorePickingEnabled: false,
  recallPickingEnabled: false,
};

export default (state = initialState, action: RootAction) => {
  switch (action.type) {
    case getType(setConfigAuthValues): {
      return {
        ...state,
        authValues: action.payload.values,
      };
    }
    case getType(setRefreshOrdersInterval): {
      return {
        ...state,
        refreshOrdersShortInterval: action.payload.shortInterval || state.refreshOrdersShortInterval,
        refreshOrdersLongInterval: action.payload.longInterval || state.refreshOrdersLongInterval,
        satToSatEnabled: action.payload.satToSatEnabled,
        smartSourcingEnabled: action.payload.smartSourcingEnabled,
        osPickingLocationsEnabled: action.payload.osPickingLocationsEnabled,
        dsPickingLocationsEnabled: action.payload.dsPickingLocationsEnabled,
        overstockPickingEnabled: action.payload.overstockPickingEnabled,
        storeToStorePickingEnabled: action.payload.storeToStorePickingEnabled,
        recallPickingEnabled: action.payload.recallPickingEnabled,
      };
    }
    default: {
      return state;
    }
  }
};
