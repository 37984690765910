import { all, put } from 'redux-saga/effects';
import { AxiosError } from 'axios';

import mapAPIError from '../../mapAPIError';
import mapMultipleAPIError from '../../mapMultipleAPIErrors'


import { createToast } from '../actions/toasts.actions';

import { APIError } from '../../types';
import { CreateToastPayload } from '../actions/toasts.types';

export function* handleApiErrors(errors: APIError[], data: any = null) {

  const apiRoot = data ? data?.config?.API_ROOT : null;
  const env = data ? data?.config?.ENV : null;

  const extraData = data ? {
    saga: data?.saga,
    method: data?.method,
    url: data?.url,
    payload: data?.data,
    apiRoot,
    env
  } : null

  if (errors?.length > 0) {

    const errorSet = new Set()
    errors.forEach(error => errorSet.add(error.code))
    let filteredErrors :APIError[] = errors

    if(errorSet.has('COMMERCIAL_ORDER_SERVICE_DOWN') && errorSet.has('REPLENISHMENT_ORDER_SERVICE_DOWN')) {
      filteredErrors = errors.filter(err => (err.code=== 'COMMERCIAL_ORDER_SERVICE_DOWN'|| err.code=== 'REPLENISHMENT_ORDER_SERVICE_DOWN'))
      yield put(createToast({ type: 'error', message: mapMultipleAPIError(filteredErrors)}))
      filteredErrors = errors.filter(err => !(err.code=== 'COMMERCIAL_ORDER_SERVICE_DOWN'|| err.code=== 'REPLENISHMENT_ORDER_SERVICE_DOWN'))
    } 

    yield all([...filteredErrors.map(error => put(createToast(extraData ? { type: 'error', message: mapAPIError(error), error, extraData } : { type: 'error', message: mapAPIError(error), error })))]);
  }
}

export function* catchApiErrors(error: AxiosError, data: any = null) {

  const errors = error && error.response && error.response.data.errors;

  const apiRoot = data ? data?.config?.API_ROOT : null;
  const env = data ? data?.config?.ENV : null;

  const extraData = data ? {
    saga: data?.saga,
    method: data?.method,
    url: data?.url,
    payload: data?.data,
    apiRoot,
    env
  } : null

  const toast: CreateToastPayload = {
    type: 'error',
    message: 'An error has occurred...',
    error
  }

  if (extraData) toast.extraData = extraData;

  if (errors) {
    yield handleApiErrors(errors, data);
  } else {
    yield put(
      createToast(toast)
    );
  }
}
