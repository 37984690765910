import React, { ReactElement } from 'react';

const PrintLocationReportIcon = (): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#DD3900" />
    <path
      d="M27 16H13C11.34 16 10 17.34 10 19V25H14V29H26V25H30V19C30 17.34 28.66 16 27 16ZM24 27H16V22H24V27ZM27 20C26.45 20 26 19.55 26 19C26 18.45 26.45 18 27 18C27.55 18 28 18.45 28 19C28 19.55 27.55 20 27 20ZM26 11H14V15H26V11Z"
      fill="white"
    />
  </svg>
);

export default PrintLocationReportIcon;
