import { combineReducers } from 'redux';

import activePickTour from './activePickTour.reducer';
import appPage from './appPage.reducer';
import config from './config.reducer';
import currentLanguage from './currentLanguage.reducer';
import loading from './loading.reducer';
import orders from './orders.reducer';
import pickerStore from './pickerStore.reducer';
import pickTourPage from './pickTourPage.reducer';
import toasts from './toasts.reducer';
import driverLogoff from './driverLogoff.reducer';
import employeeData from './employeeData.reducer';
import fullScreenPopup from './fullScreenPopup.reducer';
import message from './message.reducer';

const rootReducer = combineReducers({
  activePickTour,
  appPage,
  config,
  currentLanguage,
  loading,
  orders,
  pickerStore,
  pickTourPage,
  toasts,
  driverLogoff,
  employeeData,
  fullScreenPopup,
  message
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
