import React, { ReactElement } from 'react';

const DollarIcon = (): ReactElement => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.00004 1.33337C4.32004 1.33337 1.33337 4.32004 1.33337 8.00004C1.33337 11.68 4.32004 14.6667 8.00004 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00004C14.6667 4.32004 11.68 1.33337 8.00004 1.33337ZM8.94004 12.06V13.3334H7.16004V12.0467C6.02004 11.8067 5.05337 11.0734 4.98004 9.78004H6.28671C6.35337 10.48 6.83337 11.0267 8.05337 11.0267C9.36004 11.0267 9.65337 10.3734 9.65337 9.96671C9.65337 9.41337 9.36004 8.89337 7.87337 8.54004C6.22004 8.14004 5.08671 7.46004 5.08671 6.09337C5.08671 4.94671 6.01337 4.20004 7.16004 3.95337V2.66671H8.94004V3.96671C10.18 4.26671 10.8 5.20671 10.84 6.22671H9.53337C9.50004 5.48671 9.10671 4.98004 8.05337 4.98004C7.05337 4.98004 6.45337 5.43337 6.45337 6.07337C6.45337 6.63337 6.88671 7.00004 8.23337 7.34671C9.58004 7.69337 11.02 8.27337 11.02 9.95337C11.0134 11.1734 10.1 11.84 8.94004 12.06Z"
      fill="black"
    />
  </svg>
);

export default DollarIcon;
