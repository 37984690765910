import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from '../../buttons/Button';

import { setPickerStore, storeDetectionError } from '../../../redux/actions/pickerStore.actions';

import { padTo8LeadingZeroes } from '../../../utils';

import styles from './StoreVerification.css';

type Props = {
  storeToVerify: string | null;
};

const StoreVerification = ({ storeToVerify }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'STORE_VERIFICATION';
  const dispatch = useDispatch();

  return (
    <section className={styles.section}>
      <p className={styles.p} data-cy="store-verification-p">
        {t(`${translationRoot}.VERIFY`, {
          storeToVerify,
        })}
      </p>
      <Button
        className={styles.button}
        data-cy="store-verification-confirm-button"
        onClick={() => storeToVerify && dispatch(setPickerStore({ storeID: padTo8LeadingZeroes(storeToVerify) }))}
      >
        {t(`${translationRoot}.CONFIRM`)}
      </Button>
      <Button
        className={styles.button}
        data-cy="store-verification-cancel-button"
        color="secondary"
        onClick={() => dispatch(storeDetectionError())}
      >
        {t(`${translationRoot}.CANCEL`)}
      </Button>
    </section>
  );
};

export default StoreVerification;
