import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { POG_DYNAMIC_SLOT } from '../../../../../constants';

import { Pick } from '../../../../../types';

import { parseDynamicSlotDetails, mergeClassNames } from '../../../../../utils';

import styles from './PickTourFooter.css';

type Props = {
  pick: Pick | null;
};

const PickTourFooter = ({ pick }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.PICK_TOUR_FOOTER';

  if (pick !== null) {
    const { description, locations, locationIndex, partNumber, pickedQuantity, totalQuantity } = pick;
    const currentLocation = locations[locationIndex];
    const footerToDisplay = () => {
      if (!currentLocation) {
        return <p>{t(`${translationRoot}.NO_LOCATION_DATA`)}</p>;
      }
      if (currentLocation.planogramName === POG_DYNAMIC_SLOT) {
        const dynamicSlotDetails = parseDynamicSlotDetails(currentLocation.itemSequence.toString());
        return (
          <div className={styles.details}>
            <p className={mergeClassNames(styles.pogFog, styles.ellipseText)}>{`${t(`${translationRoot}.ZONE`)} ${
              dynamicSlotDetails.zone
            }`}</p>
            <p>{` | ${t(`${translationRoot}.AISLE`)} ${dynamicSlotDetails.aisle}`}</p>
            <p>{` | ${t(`${translationRoot}.SLOT`)} ${dynamicSlotDetails.slot}`}</p>
          </div>
        );
      }
      return (
        <div className={styles.details}>
          {currentLocation ? (
            <>
              <p className={mergeClassNames(styles.pogFog, styles.ellipseText)}>{`${t(`${translationRoot}.POG_FOG`)}: ${
                currentLocation.planogramName
              }`}</p>
              <p>{` | ${t(`${translationRoot}.SEQ`)}: ${currentLocation.itemSequence || 0} | `}</p>
            </>
          ) : null}
          <p className={mergeClassNames(styles.partNumber, styles.ellipseText)}>{`${t(
            `${translationRoot}.PART_NUMBER`
          )}: ${partNumber}`}</p>
        </div>
      );
    };
    return (
      <footer className={styles.container}>
        <div className={styles.leftContainer}>
          <p className={mergeClassNames(styles.name, styles.ellipseText)}>{`${t(
            `${translationRoot}.NEXT`
          )}: ${description}`}</p>
          {footerToDisplay()}
        </div>

        <p className={styles.qtyLabel}>
          {t(`${translationRoot}.QTY`)}
          <span className={styles.qty}>{`${pickedQuantity}/${totalQuantity}`}</span>
        </p>
      </footer>
    );
  }
  return <div className={styles.blank} />;
};

export default PickTourFooter;
