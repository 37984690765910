import { Order, RecallOrderLocalStorage } from './types';
import { stripLeadingZeroes } from './utils';

export const setRecallData = (storeNumber: string, recallOrderData: Order[]) => {
  const localStorageData = localStorage.getItem('storeRecallData');
  const localStorageRecallData: RecallOrderLocalStorage = localStorageData && JSON.parse(localStorageData);
  const formattedStoreNumber = stripLeadingZeroes(storeNumber);
  const today = new Date();
  const actualMonth = today.getUTCMonth() + 1;
  const actualDay = today.getUTCDate() < 10 ? `0${today.getUTCDate()}` : today.getUTCDate();
  const formattedDate = `${today.getUTCFullYear()}-${
    actualMonth < 10 ? `0${actualMonth}` : actualMonth
  }-${actualDay}T00:10:00.000Z`;

  if (formattedStoreNumber) {
    localStorage.setItem(
      'storeRecallData',
      JSON.stringify({
        ...localStorageRecallData,
        [formattedStoreNumber]: {
          recallOrders: recallOrderData,
          receivedDate: formattedDate,
        },
      })
    );
  }
};

export const removeRecallOrders = (orderIds: string[], storeNumber: string) => {
  const localStorageData = localStorage.getItem('storeRecallData');
  const localStorageRecallData: RecallOrderLocalStorage = localStorageData && JSON.parse(localStorageData);
  const formattedStoreNumber = stripLeadingZeroes(storeNumber);
  if (localStorageRecallData && formattedStoreNumber && localStorageRecallData[formattedStoreNumber]) {
    const updatedStoreRecalls = {
      ...localStorageRecallData[formattedStoreNumber],
      recallOrders: localStorageRecallData[formattedStoreNumber].recallOrders.filter(
        localStorageOrder => !orderIds.includes(localStorageOrder.id)
      ),
    };
    localStorage.setItem(
      'storeRecallData',
      JSON.stringify({ ...localStorageRecallData, [formattedStoreNumber]: updatedStoreRecalls })
    );
  }
};
