import { getType } from 'typesafe-actions';

import {
  getProximityStore,
  setPickerStore,
  storeDetectionError,
  promptForStoreVerification,
} from '../actions/pickerStore.actions';

import { PickerStoreState } from './types';
import { RootAction } from '../actions/types';

const initialState: PickerStoreState = {
  storeID: null,
  isDetectingStore: false,
  shouldPromptStoreSelection: false,
  shouldPromptStoreVerification: false,
  storeToVerify: null,
};

export default (state: PickerStoreState = initialState, action: RootAction): PickerStoreState => {
  switch (action.type) {
    case getType(getProximityStore): {
      return {
        ...state,
        isDetectingStore: true,
      };
    }
    case getType(setPickerStore): {
      return {
        ...state,
        storeID: action.payload.storeID,
        isDetectingStore: false,
        shouldPromptStoreSelection: false,
        shouldPromptStoreVerification: false,
      };
    }
    case getType(promptForStoreVerification): {
      return {
        ...state,
        isDetectingStore: true,
        shouldPromptStoreSelection: false,
        shouldPromptStoreVerification: true,
        storeToVerify: action.payload.storeToVerify,
      };
    }
    case getType(storeDetectionError): {
      return {
        ...state,
        isDetectingStore: false,
        shouldPromptStoreSelection: true,
        shouldPromptStoreVerification: false,
      };
    }
    default: {
      return state;
    }
  }
};
