import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import CheckmarkSquareIcon from '../../../../assets/icons/CheckmarkSquareIcon';
import DollarIcon from '../../../../assets/icons/DollarIcon';

import { ORDER_TYPE_RECALL } from '../../../../constants';

import { CompletedRecallTour } from '../../../../types';

import { displayOrderType, determineIconByOrderType, displayDate } from '../../../../utils';

import styles from './CompletedRecallItem.css';

type Props = {
  isSelected: boolean;
  completedRecall: CompletedRecallTour;
  onSelect: () => void;
};

const CompletedRecallItem = ({ isSelected, completedRecall, onSelect }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ORDER_LIST.LIST_ITEM';

  return (
    <li className={styles.li} onClick={() => onSelect()}>
      <div className={styles.checkboxArea}>
        {isSelected ? <CheckmarkSquareIcon /> : <div className={styles.circle} data-cy="circle-icon" />}
      </div>
      <div className={styles.mainArea}>
        <div className={styles.destination} data-cy="pickTour-id">
          <div>{completedRecall.recallId}</div>
          <div className={styles.recallDescription}>{(completedRecall.recallDescription || "").replace(/\s+/g, ' ').trim()}</div>
        </div>
        <div className={styles.content}>
          <div className={styles.orderType}>
            <div className={styles.icon}>{determineIconByOrderType({ orderType: ORDER_TYPE_RECALL })}</div>
            <p data-cy="pickTour-type">{displayOrderType(ORDER_TYPE_RECALL)}</p>
          </div>
          <div className={styles.itemsPick}>
            <span>{t(`${translationRoot}.PICKED`)}</span>
            <span className={styles.quantity}>{completedRecall.totalQtyPicked}</span>
          </div>
          <div className={styles.orderType}>
            <div className={styles.icon}>
              <DollarIcon />
            </div>
            <p data-cy="dollar-icon">{completedRecall.recallDollarAmount}</p>
          </div>
          <div className={styles.itemsPick}>
            <span>{t(`${translationRoot}.SKIPPED`)}</span>
            <span className={styles.quantity}>0</span>
          </div>
          <div>
            <span className={styles.textLight}>{`${t(`${translationRoot}.PROCESSED`)} ${
              displayDate({ date: completedRecall.completedDate }).split('-')[0]
            }`}</span>
            <span className={styles.orderTime}>
              {displayDate({ date: completedRecall.completedDate }).split('-')[1]}
            </span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default CompletedRecallItem;
