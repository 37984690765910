import { GetOrdersResponse } from './types';
import { SetOrdersPayload } from '../actions/orders.types';
import { Order } from '../../types';

export const mapGetOrdersResponseToSetOrdersPayload = (response: GetOrdersResponse): SetOrdersPayload => ({
  orders: response.orders.map(
    (responseItem): Order => ({
      id: responseItem.orderID,
      type: responseItem.orderType,
      deliveryMethod: responseItem.deliveryMethod,
      destination: responseItem.destination,
      quantity: responseItem.quantity,
      finalizationErrorCode: responseItem.finalizationErrorCode,
      finalizationErrorMessage: responseItem.finalizationErrorMessage,
      isRetryEnabled: responseItem.isRetryEnabled,
      isUpcScanRequired: responseItem.isUpcScanRequired,
      date: responseItem.date,
      sequenceNumber: responseItem.orderSequenceNumber,
      recallDescription: responseItem.recallDescription,
      recallType: responseItem.recallType,
      supplierID: responseItem.supplierID,
    })
  ),
  pickTours: response.pickTours,
});
