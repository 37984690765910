import EventLogger from '@az/whispers-js';

import { takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';

import { sendLog } from '../actions/log.actions';

import { Env, LogDetails } from '../../types';

import { AZ_PICK_APP_NAME } from '../../constants';
import { createTokenDataCallback, determineDeviceType, getUID } from '../../utils';

export function* runSendLogSaga(action: ReturnType<typeof sendLog>) {
  const loginData = sessionStorage.getItem('loginData');
  const env: Env | null = yield select((state: RootState) => state.config.authValues?.ENV);
  const baseUrl: Env | null = yield select((state: RootState) => state.config.authValues?.WHISPERS_URL);
  const storeID = yield select((state: RootState) => state.pickerStore) || null;

  if (loginData && env) {
    const tokenCallback = createTokenDataCallback(loginData);

    if (tokenCallback) {
      const eventLogger = new EventLogger(AZ_PICK_APP_NAME, env, null, null, tokenCallback, null, null, baseUrl);
      const { logType, logEvent, logDetails, logMessage } = action.payload;

      const details: LogDetails = {
        logDetails: {
          ...logDetails,
          ...storeID,
          uid: getUID(),
          deviceType: determineDeviceType(),
        },
        logEvent,
      };

      eventLogger[logType || 'info'](logMessage || 'App event log.', details, { immediate: true });
    }
  }
}

export default function* watchSendLog() {
  yield takeEvery(getType(sendLog), runSendLogSaga);
}
