import { RootState } from './reducers/rootReducer';
import { PaginationData } from '../types';

// Active Pick Tour Selectors

export const getActivePickTourState = (state: RootState) => state.activePickTour;

export const getActivePickIDFromState = (state: RootState) => state.activePickTour && state.activePickTour.activePickID;

export const getActivePickTourFromState = (state: RootState) => state.activePickTour && state.activePickTour.pickTour;

export const getHasJustScannedFromState = (state: RootState) =>
  state.activePickTour && state.activePickTour.hasJustScanned;

export const selectIsTourMixedValidation = (state: RootState) =>
  state.activePickTour && state.activePickTour.isTourMixedValidation;

export const selectPaginationData = (state: RootState): PaginationData =>
  state.activePickTour && state.activePickTour.isPaginated
    ? { ...state.activePickTour.paginationMetadata, isPaginated: true }
    : { isPaginated: false };

// App Page Selectors

export const getAppPageNameFromState = (state: RootState) => state.appPage.name;

// Config Selectors

export const getConfigAuthValuesFromState = (state: RootState) => state.config.authValues;

export const selectRefreshOrdersShortInterval = (state: RootState) => state.config.refreshOrdersShortInterval;

export const selectRefreshOrdersLongInterval = (state: RootState) => state.config.refreshOrdersLongInterval;

export const selectIsOverstockEnabled = (state: RootState) => state.config.overstockPickingEnabled;

export const selectIsSmartSourcingEnabled = (state: RootState) => state.config.smartSourcingEnabled;

export const selectInactivityTimeoutForToasts = (state: RootState) => state.config.authValues?.INACTIVITY_TIMEOUT_FOR_TOASTS ?? 120000;

// Current Language Selectors

export const selectCurrentLanguage = (state: RootState) => state.currentLanguage;

// Loading Selectors

export const determineIsLoadingFromState = (state: RootState) => Object.values(state.loading).some(value => value > 0);
export const selectIsGetOrdersLoading = (state: RootState) => state.loading.getOrders > 0;

// Orders Selectors

export const getOrdersFromState = (state: RootState) => state.orders;

// Picker Store Selectors

export const selectPickerStore = (state: RootState) => state.pickerStore;
export const selectPickerStoreID = (state: RootState) => state.pickerStore.storeID;

// Pick Tour Page Selectors

export const getPickTourPageNameFromState = (state: RootState) => state.pickTourPage.name;

// Toast Selectors

export const getActiveToastFromState = (state: RootState) => (state.toasts.length > 0 ? state.toasts[state.toasts.length-1] : null);

// Driver Logoff Selectors

export const getDriverLogoffStatus = (state: RootState) => state.driverLogoff.success;

// Get Employee
export const getEmployee = (state: RootState) => state.employeeData;
