import React, { ReactElement, SetStateAction } from 'react';
import MediaQuery from 'react-responsive';

import { useTranslation } from 'react-i18next';
import Card from '../Card';
import DetailsViewCardHeader from './DetailsViewCardHeader';
import DetailsViewCardItemDetails from './DetailsViewCardItemDetails';
import TextField from '../../inputs/TextField';
import Button from '../../buttons/Button';
import DetailsViewCardOrderInfo from './DetailsViewCardOrderInfo';
import DetailsViewCardFooter from './DetailsViewCardFooter';

import { calculateAmountToPick, setInputIfDigits } from '../../../utils';

import { MQ_MAX_WIDTH, MQ_MIN_WIDTH } from '../../../constants';

import { Pick, ManualEntry, SkipReason } from '../../../types';

import styles from './DetailsViewCard.css';

type Props = {
  activePick: Pick;
  hasJustScanned: boolean;
  itemInputValue: string;
  qtyInputValue: string;
  isTourComplete: boolean;
  isTourMixedValidation: boolean;
  currentLanguage: string;
  quantityOnHand: number | null | undefined;
  keepQuantity: number | null;
  setItemInputValue: (value: SetStateAction<string>) => void;
  setQtyInputValue: (value: SetStateAction<string>) => void;
  setShouldShowManualEntry: (value: SetStateAction<ManualEntry>) => void;
  onManualEntrySubmit: () => void;
  handlePick: (qty: number) => void;
  onCompleteWithQtyOnly: () => void;
  onSkip: (skipOption: SkipReason) => void;
  skipLocation: () => void;
  handleAdjustSlotQty: () => void;
  handleAdjustRecallQty: () => void;
};

const DetailsViewCard = ({
  activePick,
  hasJustScanned,
  itemInputValue,
  qtyInputValue,
  isTourComplete,
  isTourMixedValidation,
  currentLanguage,
  keepQuantity,
  quantityOnHand,
  setItemInputValue,
  setQtyInputValue,
  setShouldShowManualEntry,
  onManualEntrySubmit,
  handlePick,
  onCompleteWithQtyOnly,
  onSkip,
  skipLocation,
  handleAdjustSlotQty,
  handleAdjustRecallQty,
}: Props): ReactElement => {
  const isOverstockPickTourComplete = activePick.orderType === 'OVERSTOCK' && isTourComplete;

  const isManualEntrySubmitDisabled = itemInputValue === '' || qtyInputValue === '' || isOverstockPickTourComplete;

  const isQtyEntrySubmitDisabled = qtyInputValue === '' || isOverstockPickTourComplete;

  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.DETAILS_VIEW';

  return (
    <Card>
      <DetailsViewCardHeader
        keepQuantityOnHand={keepQuantity}
        description={activePick.description}
        pickedQuantity={activePick.pickedQuantity}
        totalQuantity={calculateAmountToPick(activePick)}
        hasJustScanned={hasJustScanned}
        orderType={activePick.orderType}
        quantityOnHand={quantityOnHand || 0}
      />
      <DetailsViewCardItemDetails
        planogramName={
          activePick.locations[activePick.locationIndex]
            ? activePick.locations[activePick.locationIndex].planogramName
            : ''
        }
        sequence={
          activePick.locations[activePick.locationIndex] && activePick.locations[activePick.locationIndex].itemSequence
            ? activePick.locations[activePick.locationIndex].itemSequence.toString()
            : '0'
        }
        partNumber={activePick.partNumber || ''}
        altPartNumber={activePick.altPartNumber || ''}
        sku={activePick.sku}
        orderType={activePick.orderType}
      />
      <MediaQuery maxWidth={MQ_MAX_WIDTH}>
        <DetailsViewCardOrderInfo orderType={activePick.orderType} destination={activePick.destination} />
        <DetailsViewCardFooter
          activePick={activePick}
          isTourMixedValidation={isTourMixedValidation}
          currentLanguage={currentLanguage}
          onSkip={onSkip}
          setShouldShowManualEntry={setShouldShowManualEntry}
          handlePick={handlePick}
          skipLocation={skipLocation}
          handleAdjustSlotQty={handleAdjustSlotQty}
          handleAdjustRecallQty={handleAdjustRecallQty}
        />
      </MediaQuery>
      <MediaQuery minWidth={MQ_MIN_WIDTH}>
        <DetailsViewCardOrderInfo orderType={activePick.orderType} destination={activePick.destination} />
        {activePick.isUpcScanRequired || isTourMixedValidation ? (
          <div className={styles.manualEntryContainer}>
            <label htmlFor="item-field" className={styles.itemLabel}>
              {t(`${translationRoot}.PART_NUMBER_INPUT`)}
              <TextField
                disabled={isOverstockPickTourComplete}
                name="item-field"
                value={itemInputValue}
                placeholder={t(`${translationRoot}.PART_NUMBER_INPUT_MOBILE.PLACEHOLDER`)}
                data-cy="pick-tour-item-field"
                onChange={event => setItemInputValue(event.target.value)}
                onKeyUp={event => {
                  if (event.keyCode === 13 && !isManualEntrySubmitDisabled) {
                    onManualEntrySubmit();
                  }
                }}
              />
            </label>
            <label htmlFor="qty-field" className={styles.qtyLabel}>
              {t(`${translationRoot}.QTY_INPUT`)}
              <TextField
                disabled={isOverstockPickTourComplete}
                name="qty-field"
                value={qtyInputValue}
                type="number"
                placeholder={t(`${translationRoot}.AMOUNT_PLACEHOLDER`)}
                data-cy="pick-tour-qty-field"
                onChange={event => setInputIfDigits({ inputSetter: setQtyInputValue, value: event.target.value })}
                onKeyUp={event => {
                  if (event.keyCode === 13 && !isManualEntrySubmitDisabled) {
                    onManualEntrySubmit();
                  }
                }}
              />
            </label>
            <Button
              className={styles.submitButton}
              disabled={isManualEntrySubmitDisabled}
              onClick={() => onManualEntrySubmit()}
              data-cy="pick-tour-manual-submit-button"
            >
              {t(`${translationRoot}.SUBMIT_BUTTON`)}
            </Button>
          </div>
        ) : (
          <div className={styles.qtyOnlyContainer}>
            <label htmlFor="qty-field" className={styles.qtyOnlyLabel}>
              {t(`${translationRoot}.QTY_INPUT`)}
              <TextField
                disabled={isOverstockPickTourComplete}
                name="qty-field"
                value={qtyInputValue}
                type="number"
                placeholder={t(`${translationRoot}.AMOUNT_PLACEHOLDER`)}
                data-cy="pick-tour-qty-field"
                onChange={event => setInputIfDigits({ inputSetter: setQtyInputValue, value: event.target.value })}
                onKeyUp={event => {
                  if (event.keyCode === 13 && !isQtyEntrySubmitDisabled) {
                    onCompleteWithQtyOnly();
                  }
                }}
              />
            </label>
            <Button
              className={styles.qtySubmitButton}
              disabled={isQtyEntrySubmitDisabled}
              onClick={() => onCompleteWithQtyOnly()}
              data-cy="pick-tour-manual-submit-button"
            >
              {t(`${translationRoot}.SUBMIT_BUTTON`)}
            </Button>
          </div>
        )}
        <DetailsViewCardFooter
          activePick={activePick}
          isTourMixedValidation={isTourMixedValidation}
          currentLanguage={currentLanguage}
          onSkip={onSkip}
          setShouldShowManualEntry={setShouldShowManualEntry}
          handlePick={handlePick}
          skipLocation={skipLocation}
          handleAdjustSlotQty={handleAdjustSlotQty}
          handleAdjustRecallQty={handleAdjustRecallQty}
        />
      </MediaQuery>
    </Card>
  );
};

export default DetailsViewCard;
