import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import {
  getPickTourItems,
  setActivePickTour,
  addPickTourPage,
  setActivePickTourPage,
} from '../actions/activePickTour.actions';
import { setAppPage } from '../actions/appPage.actions';
import { setLoading } from '../actions/loading.actions';
import { setPickTourPage } from '../actions/pickTourPage.actions';
import {
  mapGetPickTourItemsResponseToSetActivePickTourPayload,
  mapGetPickTourItemsResponseToSetActivePickTourPagePayload,
  mapGetPickTourItemsResponseToAddPickTourPagePayload,
} from './getPickTourItems.mappers';

import {
  APP_PAGE_PICK_TOUR,
  SAGA_GET_PICK_TOUR_ITEMS,
  BFF_ROOT,
  BFF_V1,
  BFF_PICK_TOURS,
  BFF_ITEMS,
  PICK_TOUR_PAGINATION_PAGE_SIZE,
  PICK_TOUR_PAGE_UPDATE_FAILED,
  ORDER_TYPE_OVERSTOCK
} from '../../constants';

import { GetPickTourItemsResponse } from './types';
import {  ConfigAuthValues, Language, OrderType } from '../../types';

import { callAPI, calculateAmountToPick } from '../../utils';
import { mapAPIPickTourListItemToPick } from './sharedMappers';

import { handleApiErrors, catchApiErrors } from './utils';

import { ActionTypes as FullScreenPopupActionTypes } from '../reducers/fullScreenPopup.reducer';

const callGetPickTourItems = (
  pickTourID: string,
  orderType: OrderType,
  currentLanguage: Language,
  page: number,
  configAuthValues: ConfigAuthValues
) => {
  return callAPI({
    method: 'GET',
    // Back end requires just characters before dash. ie 'en' or 'es'
    languageHeader: currentLanguage.slice(0, 2),
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${pickTourID}${BFF_ITEMS}?orderType=${orderType}&page=${page}&size=${PICK_TOUR_PAGINATION_PAGE_SIZE}`,
  });
};

export function* runGetPickTourItemsSaga(action: ReturnType<typeof getPickTourItems>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);
  const currentLanguage = yield select((state: RootState) => state.currentLanguage);

  yield put(setLoading({ sagaName: SAGA_GET_PICK_TOUR_ITEMS, isLoading: true }));

  try {
    const { data }: { data: GetPickTourItemsResponse } = yield call(
      callGetPickTourItems,
      action.payload.isPickTourCreation ? action.payload.pickTourData.pickTourID : action.payload.pickTourID,
      action.payload.isPickTourCreation ? action.payload.pickTourType : action.payload.orderType,
      currentLanguage,
      action.payload.page,
      configAuthValues
    );
    const showOverstockPopup = (data.pickTourMetadata.remainingOverstockOrders || 0) > 0 && action.payload.isPickTourCreation && action.payload.pickTourType === ORDER_TYPE_OVERSTOCK
    const fullScreenPopupPayload = {
      show: true,
      title: "POPUP.IMPORTANT",
      text: "POPUP.REMAINING_OVERSTOCK_ITEMS",
      textVariables: { _remainingOverstockOrders_: data.pickTourMetadata.remainingOverstockOrders },
      redirectPage: APP_PAGE_PICK_TOUR
    }

    // TODO: Validate API response
    const isPageIncomplete = data.content.some(
      pickTourListItem =>
        pickTourListItem.pickedItemQuantity < calculateAmountToPick(mapAPIPickTourListItemToPick(pickTourListItem)) &&
        !pickTourListItem.skippedFlag &&
        (pickTourListItem.location.length > 0 || pickTourListItem.orderType === 'RECALL')
    );
    if (action.payload.isPickTourCreation) {
      if (action.payload.pickTourType === 'OVERSTOCK' || action.payload.pickTourType === 'RECALL') {
        if (action.payload.page === 0) {
          yield put(
            setActivePickTour(mapGetPickTourItemsResponseToSetActivePickTourPayload(data, action.payload.pickTourData))
          );
          if (data.pickTourMetadata.nextItemPageNumber === null || isPageIncomplete) {
            if (!showOverstockPopup) yield put(setAppPage({ name: APP_PAGE_PICK_TOUR }));
            if (showOverstockPopup) yield put({ type: FullScreenPopupActionTypes.SHOW_FULLSCREEN_POPUP, payload: fullScreenPopupPayload })
          } else {
            yield put(getPickTourItems({ ...action.payload, page: data.pickTourMetadata.nextItemPageNumber }));
          }
        } else {
          yield put(setActivePickTourPage(mapGetPickTourItemsResponseToSetActivePickTourPagePayload(data)));
          if (!showOverstockPopup) yield put(setAppPage({ name: APP_PAGE_PICK_TOUR }));
          if (showOverstockPopup) yield put({ type: FullScreenPopupActionTypes.SHOW_FULLSCREEN_POPUP, payload: fullScreenPopupPayload })

        }
      } else if (action.payload.page === 0) {
        yield put(
          setActivePickTour(mapGetPickTourItemsResponseToSetActivePickTourPayload(data, action.payload.pickTourData))
        );
        if (data.page.totalPages === 1) {
          if (!showOverstockPopup) yield put(setAppPage({ name: APP_PAGE_PICK_TOUR }));
          if (showOverstockPopup) yield put({ type: FullScreenPopupActionTypes.SHOW_FULLSCREEN_POPUP, payload: fullScreenPopupPayload })
        } else {
          yield put(getPickTourItems({ ...action.payload, page: action.payload.page + 1 }));
        }
      } else {
        yield put(addPickTourPage(mapGetPickTourItemsResponseToAddPickTourPagePayload(data)));
        if (action.payload.page < data.page.totalPages - 1) {
          yield put(getPickTourItems({ ...action.payload, page: action.payload.page + 1 }));
        } else {
          if (!showOverstockPopup) yield put(setAppPage({ name: APP_PAGE_PICK_TOUR }));
          if (showOverstockPopup) yield put({ type: FullScreenPopupActionTypes.SHOW_FULLSCREEN_POPUP, payload: fullScreenPopupPayload })

        }
      }
    } else if (data.pickTourMetadata.nextItemPageNumber === null || isPageIncomplete) {
      yield put(setActivePickTourPage(mapGetPickTourItemsResponseToSetActivePickTourPagePayload(data)));
    } else {
      yield put(getPickTourItems({ ...action.payload, page: data.pickTourMetadata.nextItemPageNumber }));
    }
    yield handleApiErrors(data.errors.errors);
  } catch (error) {
    if (!action.payload.isPickTourCreation) {
      yield put(setPickTourPage({ name: PICK_TOUR_PAGE_UPDATE_FAILED }));
    }
    try {
      const pickTourID = action.payload.isPickTourCreation ? action.payload.pickTourData.pickTourID : action.payload.pickTourID;
      const orderType = action.payload.isPickTourCreation ? action.payload.pickTourType : action.payload.orderType;
      const {page} = action.payload;
      const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${pickTourID}${BFF_ITEMS}?orderType=${orderType}&page=${page}&size=${PICK_TOUR_PAGINATION_PAGE_SIZE}`
      const method = 'GET';
      const data = {};
      const config = configAuthValues;
      yield catchApiErrors(error, {url, method, data, config, saga: SAGA_GET_PICK_TOUR_ITEMS});
    } catch (_error) {
      yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_GET_PICK_TOUR_ITEMS, isLoading: false }));
}

export default function* watchGetPickTourItems() {
  yield takeEvery(getType(getPickTourItems), runGetPickTourItemsSaga);
}
