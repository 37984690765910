import React, { ReactElement } from 'react';
import MediaQuery from 'react-responsive';

import { useTranslation } from 'react-i18next';
import PickItem from '../../ActivePickTour/PickItem';
import SlideUpFooter from '../../../../layout/SlideUpFooter';
import Button from '../../../../buttons/Button';

import { Pick, PickTour, PaginationData } from '../../../../../types';
import PaginationBar from '../../../../layout/PaginationBar';

import { MQ_MAX_WIDTH, MQ_MIN_WIDTH } from '../../../../../constants';

import { generatePickID, calculateResolvedPickPickedQuantity, calculateTotalQuantity } from '../../../../../utils';

import styles from './ConfirmStaged.css';
import { UpdateType } from '../../../../../redux/actions/activePickTour.types';

type Props = {
  pickTour: PickTour;
  paginationData: PaginationData;
  picks: Pick[];
  onPageChange: (page: number) => void;
  closePickTour: (updateType: UpdateType) => void;
};

const ConfirmStaged = ({ pickTour, paginationData, picks, onPageChange, closePickTour }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'CONFIRM_STAGED';
  const resolvedPickPickedQuantity =
    calculateResolvedPickPickedQuantity(pickTour) +
    (paginationData.isPaginated ? paginationData.totalItemsPicked - paginationData.initialPageItemsPicked : 0);

  const totalQuantity = paginationData.isPaginated ? paginationData.totalItems : calculateTotalQuantity(pickTour);

  const qtyCopy = t(`${translationRoot}.PARTS_MUST_BE_STAGED`, {
    picked: resolvedPickPickedQuantity,
    total: totalQuantity,
  });

  return (
    <>
      <MediaQuery maxWidth={MQ_MAX_WIDTH}>
        <section className={styles.section}>
          <header className={styles.header}>
            <h1>{t(`${translationRoot}.EXIT_TOUR`)}</h1>
            {resolvedPickPickedQuantity > 0 && <p>{qtyCopy}</p>}
          </header>
          <ul className={paginationData.isPaginated ? styles.paginatedItemListContainer : styles.itemListContainer}>
            {picks.map(pick => (
              <PickItem key={generatePickID(pick)} pick={pick} />
            ))}
          </ul>
          {/* TODO: Need a way to get the filtered picks in this component for paginated pick tours */}
          {paginationData.isPaginated && (
            <PaginationBar
              isDisabled={false}
              currentPage={paginationData.pageNumber + 1}
              totalPages={paginationData.totalPages}
              onPageChange={onPageChange}
            />
          )}
          <SlideUpFooter isOpen>
            <Button className={styles.footerButton} onClick={() => closePickTour('exit')}>
              {t(
                `${translationRoot}.${
                  resolvedPickPickedQuantity > 0 ? 'CONFIRM_ITEMS_STAGED' : 'CONFIRM_ITEMS_SKIPPED'
                }`
              )}
            </Button>
          </SlideUpFooter>
        </section>
      </MediaQuery>
      <MediaQuery minWidth={MQ_MIN_WIDTH}>
        <section className={styles.exitSection}>
          <h1 className={styles.h1}>{t(`${translationRoot}.EXIT_TOUR`)}</h1>
          {resolvedPickPickedQuantity > 0 && <p className={styles.p}>{qtyCopy}</p>}
          <Button
            className={styles.button}
            onClick={() => closePickTour('exit')}
            data-cy="pick-tour-confirm-exit-button"
          >
            {t(
              `${translationRoot}.${resolvedPickPickedQuantity > 0 ? 'CONFIRM_ITEMS_STAGED' : 'CONFIRM_ITEMS_SKIPPED'}`
            )}
          </Button>
        </section>
      </MediaQuery>
    </>
  );
};

export default ConfirmStaged;
