import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { createPickTour, getPickTourItems } from '../actions/activePickTour.actions';
import { setLoading } from '../actions/loading.actions';
import { clearSelected, setHasManualOverstockOrder, setOrders } from '../actions/orders.actions';

import { mapCreatePickTourPayloadToCreatePickTourRequest } from './createPickTour.mappers';

import {
  BFF_PICK_TOURS,
  BFF_ROOT,
  BFF_V1,
  GENERIC_NON_SERVICE_ERROR,
  NO_OVERSTOCK_ORDERS_GENERATED,
  PREVIOUS_OVERSTOCK_TOUR_PENDING,
  SAGA_CREATE_PICK_TOUR,
} from '../../constants';

import { CreatePickTourRequest, CreatePickTourResponse } from './types';
import { AppLogType, ConfigAuthValues, Order } from '../../types';

import { callAPI } from '../../utils';

import { catchApiErrors, handleApiErrors } from './utils';

import { removeRecallOrders } from '../../recallStorageUtils';
import { sendLog } from '../actions/log.actions';

const callCreatePickTour = (data: CreatePickTourRequest, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'POST',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}`,
    data,
  });
};

export function* runCreatePickTourSaga(action: ReturnType<typeof createPickTour>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);
  const ordersState = yield select((state: RootState) => state.orders);

  yield put(setLoading({ sagaName: SAGA_CREATE_PICK_TOUR, isLoading: true }));
  yield put(sendLog({ logEvent: AppLogType.PICK_TOUR_STARTED }));

  try {
    const { data }: { data: CreatePickTourResponse } = yield call(
      callCreatePickTour,
      mapCreatePickTourPayloadToCreatePickTourRequest(action.payload),
      configAuthValues
    );
    // TODO: Validate API response
    if (data.pickTour) {
      yield put(
        getPickTourItems({
          isPickTourCreation: true,
          page: 0,
          pickTourData: data.pickTour,
          pickTourType: action.payload.orders[0].type,
        })
      );
      if (action.payload.orders[0].type === 'RECALL') {
        // For recalls you can only submit one at at time so it's safe to use the first one in the array.
        const orderToRemove = action.payload.orders[0];
        yield put(
          setOrders({
            orders: ordersState.orders.filter((reduxOrder: Order) => reduxOrder.id !== orderToRemove.id),
            pickTours: ordersState.pickTours,
          })
        );
        if (orderToRemove.supplierID) {
          removeRecallOrders([orderToRemove.id], orderToRemove.supplierID);
        }
      }
    }
    yield handleApiErrors(data.errors);
    // TODO: Determine if this should be triggered on any other errors
    if (
      data.errors.some(
        error => error.code === GENERIC_NON_SERVICE_ERROR && error.detail === NO_OVERSTOCK_ORDERS_GENERATED
      )
    ) {
      yield put(setHasManualOverstockOrder({ hasManualOverstockOrder: false }));
    }
  } catch (error) {
    try {
      if (error?.response?.status === 503) yield put(clearSelected(true));
    } finally {
      try {
        const data = mapCreatePickTourPayloadToCreatePickTourRequest(action.payload);
        const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}`
        const method = 'POST';
        const config = configAuthValues;
        yield catchApiErrors(error, {url, method, data, config, saga: SAGA_CREATE_PICK_TOUR});
      } catch (_error) {
        yield catchApiErrors(error);
      }
      if (
        error?.response?.data?.errors?.some(
          (e: { code: string; detail: string; id: string }) =>
            e.code === GENERIC_NON_SERVICE_ERROR && e.detail === PREVIOUS_OVERSTOCK_TOUR_PENDING
        )
      ) {
        yield put(setHasManualOverstockOrder({ hasManualOverstockOrder: false }));
      }
    }
  }

  yield put(setLoading({ sagaName: SAGA_CREATE_PICK_TOUR, isLoading: false }));
}

export default function* watchCreatePickTour() {
  yield takeEvery(getType(createPickTour), runCreatePickTourSaga);
}
