import React from 'react';
import axios, { CancelToken, Method } from 'axios';
import i18n from './i18n';

import axiosInstance from './axiosInstance';

import ShuttleIcon from './assets/icons/ShuttleIcon';
import CommercialIcon from './assets/icons/CommercialIcon';
import OverstockIcon from './assets/icons/OverstockIcon';
import RecallIcon from './assets/icons/RecallIcon';

import {
  ORDER_TYPE_SAT_TO_SAT,
  ORDER_TYPE_COMMERCIAL,
  ORDER_TYPE_OVERSTOCK,
  ORDER_TYPE_RECALL,
  POG_DYNAMIC_SLOT,
  POG_OVERSTOCK,
  DESKTOP,
  CT40,
  TC75,
  USER_DATA,
  BYTE,
  ANDROID,
  ORDER_TYPE_COMMERCIAL_EP,
} from './constants';

import { Falsy, OrderType, Pick, PickTour, DynamicSlotDetails, UserData, LogType, PickLocation } from './types';

const translationRoot = 'UTILS';

export const mergeClassNames = (...classNames: (string | Falsy)[]) =>
  classNames.filter(className => className).join(' ');

export const stripLeadingZeroes: (value: string) => string = value => value && value.replace(/^0+/, '');

const padLeadingZeroes = (length: number) => (value: string) => {
  if (value.length < length) {
    return `${'0'.repeat(length - value.length)}${value}`;
  }
  return value;
};

export const isCommercialOrder = (orderType?: OrderType) =>
  orderType && (orderType === ORDER_TYPE_COMMERCIAL || orderType === ORDER_TYPE_COMMERCIAL_EP);

export const padTo8LeadingZeroes = padLeadingZeroes(8);
// Matches string that starts with EP- followed by 1 or more numbers.
export const epRegEx = /^EP-\d+/;

export const displayOrderType = (
  orderType: OrderType,
  deliveryMethod?: string | null,
  orderDestination?: string | null
) => {
  switch (orderType) {
    case ORDER_TYPE_SAT_TO_SAT: {
      return i18n.t(`${translationRoot}.SAT_TO_SAT`);
    }
    /*
      TODO: clean this hack up - once the new order type has been in prod for a while, we can separate the cases out here
      Then we will have no need for the extra orderDestination parameter in the method.
    */
    case ORDER_TYPE_COMMERCIAL:
    case ORDER_TYPE_COMMERCIAL_EP: {
      if (deliveryMethod || orderDestination) {
        if (orderDestination && orderDestination.match(epRegEx) && !deliveryMethod) {
          return `${i18n.t(`${translationRoot}.EXPANDED_PART`)}`;
        }
        if (!deliveryMethod && orderDestination && !orderDestination.match(epRegEx)) {
          return i18n.t(`${translationRoot}.COMMERCIAL`);
        }
        return `${
          orderDestination && orderDestination.match(epRegEx) ? `EP` : `${i18n.t(`${translationRoot}.COMMERCIAL`)}`
        } - ${i18n.t(`${translationRoot}.${deliveryMethod === 'Commercial - Delivery' ? 'DELIVERY' : 'WALK_IN'}`)}`;
      }
      return i18n.t(`${translationRoot}.COMMERCIAL`);
    }
    case ORDER_TYPE_OVERSTOCK: {
      return i18n.t(`${translationRoot}.OVERSTOCK`);
    }
    case ORDER_TYPE_RECALL: {
      return i18n.t(`${translationRoot}.RECALL`);
    }
    default: {
      return '';
    }
  }
};

export const determineIconByOrderType = ({ orderType, small }: { orderType: OrderType; small?: boolean }) => {
  switch (orderType) {
    case ORDER_TYPE_SAT_TO_SAT: {
      return <ShuttleIcon small={small} />;
    }
    case ORDER_TYPE_COMMERCIAL:
    case ORDER_TYPE_COMMERCIAL_EP: {
      return <CommercialIcon small={small} />;
    }
    case ORDER_TYPE_OVERSTOCK: {
      return <OverstockIcon small={small} />;
    }
    case ORDER_TYPE_RECALL: {
      return <RecallIcon small={small} />;
    }
    default: {
      return '';
    }
  }
};

export const callAPI = (options: {
  method: Method;
  url: string;
  headers?: { [key: string]: string } | null;
  languageHeader?: string;
  data?: any;
  withCredentials?: boolean;
  cancelToken?: CancelToken;
}) => {
  const { method, url, headers, data, withCredentials, languageHeader, cancelToken } = options;

  const logInValuesJSON = sessionStorage.getItem('loginData');
  const logInValues = logInValuesJSON && JSON.parse(logInValuesJSON);
  return axiosInstance.request({
    url,
    method,
    data,
    withCredentials,
    headers: {
      ...headers,
      Authorization: process.env.NODE_ENV === 'test' ? 'Bearer 1234' : `Bearer ${logInValues.access_token}`,
      'Content-Type': 'application/json',
      'Accept-Language': languageHeader || 'en',
    },
    cancelToken,
  });
};

export const generatePickID = (pick: Pick) => `${pick.orderType}:${pick.orderID}:${pick.lineNumber}`;

export const displayDestination = ({
  orderType,
  destination,
}: {
  orderType: OrderType;
  destination: string | null;
}) => {
  if (destination) {
    return [ORDER_TYPE_SAT_TO_SAT, ORDER_TYPE_OVERSTOCK].includes(orderType)
      ? `${i18n.t(`${translationRoot}.STORE`)} ${stripLeadingZeroes(destination)}`
      : destination;
  }
  return `${i18n.t(`${translationRoot}.NO_DESTINATION`)}`;
};

export const padTo2LeadingZeroes = padLeadingZeroes(2);

export const padTo3LeadingZeroes = padLeadingZeroes(3);

export const padTo6LeadingZeroes = padLeadingZeroes(6);

export const displayDate = ({ date }: { date: string }) => {
  const dateobj = new Date(date);
  const month = padTo2LeadingZeroes((dateobj.getMonth() + 1).toString());
  const day = padTo2LeadingZeroes(dateobj.getDate().toString());
  const dateString = `${month}/${day}/${dateobj.getFullYear()}`;
  const hours = dateobj.getHours();
  const minutes = padTo2LeadingZeroes(dateobj.getMinutes().toString());
  const adjustedHours = hours > 12 ? hours - 12 : hours;
  const timeString = `${adjustedHours === 0 ? 12 : adjustedHours}:${minutes} ${hours > 11 ? 'pm' : 'am'}`;
  return `${dateString} - ${timeString}`;
};

export const isToday = (dateInQuestion: string) => {
  const today = new Date();
  const formattedDate = new Date(dateInQuestion);
  return (
    formattedDate.getUTCDate() === today.getUTCDate() &&
    formattedDate.getUTCMonth() === today.getUTCMonth() &&
    formattedDate.getUTCFullYear() === today.getUTCFullYear()
  );
};

export const calculateAmountToPick = (pick: Pick) => {
  if (pick.orderType === ORDER_TYPE_RECALL) {
    const originalTotal = pick.totalQuantity;
    const keepQuantity = pick.keepQuantityOnHand || pick.keepQuantityOnHand === 0 ? pick.keepQuantityOnHand : null;
    const updatedQuantity =
      pick.locations.length > 0 ? pick.locations[pick.locations.length - 1].updatedQuantity : null;
    const quantityOnHand = updatedQuantity || updatedQuantity === 0 ? updatedQuantity : pick.quantityOnHand;

    return (quantityOnHand || quantityOnHand === 0) && (keepQuantity || keepQuantity === 0)
      ? Math.max(quantityOnHand - keepQuantity, 0)
      : originalTotal;
  }
  return pick.totalQuantity;
};

export const calculateQuantitiesForExitReview = (pickTour: PickTour) => {
  return pickTour.picks.reduce(
    (quantity, pick) => quantity + (pick.pickedQuantity < calculateAmountToPick(pick) ? 0 : pick.pickedQuantity),
    0
  );
};

export const calculatePickedQuantity = (pickTour: PickTour) => {
  return pickTour.picks.reduce((quantity, pick) => quantity + pick.pickedQuantity, 0);
};

export const calculateResolvedPickPickedQuantity = (pickTour: PickTour) =>
  pickTour.picks.reduce(
    (quantity, pick) =>
      quantity + (pick.pickedQuantity === calculateAmountToPick(pick) || pick.isSkipped ? pick.pickedQuantity : 0),
    0
  );

export const calculateUnresolvedPickPickedQuantity = (pickTour: PickTour) =>
  pickTour.picks.reduce(
    (quantity, pick) =>
      quantity + (pick.pickedQuantity < calculateAmountToPick(pick) && !pick.isSkipped ? pick.pickedQuantity : 0),
    0
  );

export const calculateTotalQuantity = (pickTour: PickTour) =>
  pickTour.picks.reduce((quantity, pick) => quantity + calculateAmountToPick(pick), 0);

export const updatedQuantityTotal = (activePick: Pick | null) => {
  if (!activePick) {
    return null;
  }

  const locationsUpdated = activePick?.locations.filter(
    (location: PickLocation) => location.updatedQuantity && location
  );

  if (locationsUpdated?.length) {
    const updatedQuantitySum = locationsUpdated.reduce(
      (previousValue, currentValue: PickLocation) =>
        currentValue.updatedQuantity ? previousValue + currentValue.updatedQuantity : previousValue,
      0
    );

    return updatedQuantitySum;
  }

  return null;
};

export const calculateSkippedQuantity = (pickTour: PickTour) =>
  pickTour.picks.reduce(
    (quantity, pick) => (pick.isSkipped ? quantity + (calculateAmountToPick(pick) - pick.pickedQuantity) : quantity),
    0
  );

export const setInputIfDigits = (params: { inputSetter: (value: string) => void; value: string }) => {
  const { inputSetter, value } = params;
  if (/^\d*$/.test(value)) {
    inputSetter(value);
  }
};

// needs access to configs
export const determineDeviceType = () => {
  const deviceInfo = navigator.userAgent;
  if (deviceInfo.includes(TC75)) {
    return TC75;
  }
  if (deviceInfo.includes(ANDROID)) {
    return CT40;
  }
  return DESKTOP;
};

/* The sequence field for dynamically slotted items needs to be parsed using the following instructions:
     - The field comes back as an 8 or 9 digit number.
     - The first digit (or first two digits if a 9 digit number) represents the Zone.
     - The next two digits represent the Aisle.
     - The last five digits represent the Slot.
  */

export const parseDynamicSlotDetails = (sequence: string): DynamicSlotDetails => ({
  zone: stripLeadingZeroes(sequence.slice(0, -7)),
  aisle: stripLeadingZeroes(sequence.slice(-7, -5)),
  slot: stripLeadingZeroes(sequence.slice(-5)),
});

export const sortPicksByLocation = ({
  picks,
  shouldUseFirstLocation,
}: {
  picks: Pick[];
  shouldUseFirstLocation?: boolean;
}) =>
  [...picks].sort((pickA, pickB) => {
    const locationA = pickA.locations[shouldUseFirstLocation ? 0 : pickA.locationIndex];
    const locationB = pickB.locations[shouldUseFirstLocation ? 0 : pickB.locationIndex];
    if (locationA) {
      if (locationB) {
        if (locationA.planogramName === POG_DYNAMIC_SLOT) {
          if (locationB.planogramName === POG_DYNAMIC_SLOT) {
            return locationA.itemSequence - locationB.itemSequence;
          }
          return -1;
        }
        if (locationB.planogramName === POG_DYNAMIC_SLOT) {
          return 1;
        }
        if (locationA.planogramName === POG_OVERSTOCK) {
          if (locationB.planogramName === POG_OVERSTOCK) {
            return locationA.itemSequence - locationB.itemSequence;
          }
          return -1;
        }
        if (locationB.planogramName === POG_OVERSTOCK) {
          return 1;
        }
        if (locationA.vdlSequence !== null) {
          if (locationB.vdlSequence !== null) {
            if (locationA.vdlSequence === locationB.vdlSequence) {
              return locationA.itemSequence - locationB.itemSequence;
            }
            return locationA.vdlSequence - locationB.vdlSequence;
          }
          return -1;
        }
        if (locationB.vdlSequence !== null) {
          return 1;
        }
        return 0;
      }
      return -1;
    }
    if (locationB) {
      return 1;
    }
    return 0;
  });

// Utility functions for sagas

export const getTokenData = (loginData: string): { accessToken: string; refreshToken: string } => {
  const {
    access_token: accessToken,
    refresh_token: refreshToken,
  }: { access_token: string; refresh_token: string } = JSON.parse(loginData);
  return { accessToken, refreshToken };
};

export const getUID = (): string | null => {
  const userData = sessionStorage.getItem(USER_DATA);
  if (userData) {
    const parsedUserData: UserData = JSON.parse(userData);
    const { uid } = parsedUserData.claims;
    return uid || null;
  }
  return null;
};

export const createTokenDataCallback = (loginData: string) => {
  const { accessToken, refreshToken } = getTokenData(loginData);

  return accessToken && refreshToken
    ? async () => ({
        accessToken,
        refreshToken,
      })
    : null;
};

export const getLogType = (typeCode: string): LogType => {
  switch (typeCode) {
    case 'caution': {
      return 'warn';
    }
    case 'error': {
      return 'error';
    }
    case 'information': {
      return 'info';
    }
    case 'success': {
      return 'info';
    }
    default: {
      return 'info';
    }
  }
};

// System data utils

export const getAppVersion = async () => {
  const response = await axios.get('./version.json');
  const { version } = await response.data;

  return version || 0;
};

// Might not report correct version, sometimes browser will send back fake data to avoid sniffing.
export const getChromeVersion = () => {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? `${parseInt(raw[2], 10)}` : 'n/a';
};

export const getSystemDetails = () => {
  const deviceMemory = (navigator as any)?.deviceMemory || null;
  const { jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize } = (performance as any).memory || {};

  const convertMemorySizeToMB = (value: number) => `${Math.floor(value ? value / BYTE / BYTE : 0)}MB` || 'n/a';

  return {
    appVersion: getAppVersion() || 'n/a',
    chromeVersion: getChromeVersion(),
    deviceMemory: deviceMemory ? `${deviceMemory}GB` : 'n/a',
    memoryLimit: convertMemorySizeToMB(jsHeapSizeLimit),
    memorySize: convertMemorySizeToMB(totalJSHeapSize),
    memoryUsed: convertMemorySizeToMB(usedJSHeapSize),
  };
};
