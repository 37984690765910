/* eslint-disable react/no-danger */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './FullScreenPopup.css';
import { setAppPage } from '../../../redux/actions/appPage.actions';
import Button from '../../buttons/Button';

import { ActionTypes as FullScreenPopupActionTypes } from '../../../redux/reducers/fullScreenPopup.reducer';
import { RootState } from '../../../redux/reducers/rootReducer';

export default function() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fullScreenPopup = useSelector((state: RootState) => state.fullScreenPopup);
  const { title, textVariables, show, redirectPage, text } = fullScreenPopup;

  function processedText(){
    let output = t(text);
    if (textVariables){
      Object.keys(textVariables).forEach(variable => {
        output = output.replace(variable, textVariables[variable]);
      });  
    }
    return output;
  }

  return (
    <>
      {show === true && (
        <div className={`${styles.container}`}>
          <div className={styles.body}>
            <div className={styles.content}>
              <h1 className={styles.title}>{t(title)}</h1>
              <span className={styles.text} dangerouslySetInnerHTML={{ __html: processedText() }} />
              <Button
                onClick={() => {
                  dispatch({ type: FullScreenPopupActionTypes.HIDE_FULLSCREEN_POPUP });
                  if (redirectPage) dispatch(setAppPage({ name: redirectPage }));
                }}
              >
                OK
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}