import React, { ReactElement } from 'react';

import styles from './icons.css';

const AdjustSlotQtyIcon = (): ReactElement => (
    <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-cy="adjust-icon">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M2 17h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1 3h1.8L2 13.1v.9h3v-1H3.2L5 10.9V10H2v1zm5-6v2h14V5H7zm0 14h14v-2H7v2zm0-6h14v-2H7v2z"/>
    </svg>
);

export default AdjustSlotQtyIcon;
