import React, { ReactElement, ReactNode } from 'react';

import { mergeClassNames } from '../../../utils';

import styles from './Main.css';

export type Props = {
  children: ReactNode;
  className?: string;
};

const Main = ({ children, className }: Props): ReactElement => (
  <main className={mergeClassNames(styles.container, className)}>{children}</main>
);

export default Main;
