type State = {
  data: null | string,
}

export enum ActionTypes {
  SEND_MESSAGE = "SEND_MESSAGE",
  CLEAR_MESSAGE = "CLEAR_MESSAGE"
}

export enum Messages {
  PRINT_DISCREPANCY_REPORT = "PRINT_DISCREPANCY_REPORT",
  PRINT_RECALL_REPORT = "PRINT_RECALL_REPORT"
}

const initialState: State = { data: null };

export default (state = initialState, action: { type: ActionTypes; payload: string; }) => {
  switch (action.type) {
    case (ActionTypes.SEND_MESSAGE): {
      return {data: action.payload};
    }
    case (ActionTypes.CLEAR_MESSAGE): {
      return initialState;
    }
    default: {
      return state;
    }
  }
};