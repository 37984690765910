import React, { useState, useRef, useEffect, ReactElement } from 'react';

import { mergeClassNames } from '../../../utils';

import IconButton from '../../buttons/IconButton';

import { ToastType } from '../../../types';

import XIcon from '../../../assets/icons/XIcon';
import styles from './Toast.css';

type Props = {
  id: string;
  type: ToastType;
  message: string;
  duration?: number;
  onToastClose: () => void;
};

const Toast = ({ id, type, message, duration=5000, onToastClose }: Props): ReactElement | null => {
  const timer = useRef<number>();

  const [isOpen, setIsOpen] = useState(false);

  const EXTRA_DURATION_CHARACTER_THRESHOLD = 120;

  const handleRemoveTimer = () => {
    if (timer.current) {
      window.clearTimeout(timer.current);
      timer.current = undefined;
    }
  };

  useEffect(() => {
    if (id) {
      const handleSetTimer = () => {
        timer.current = window.setTimeout(() => {
          timer.current = undefined;
          setIsOpen(false);
        }, duration + Math.floor(message.length / EXTRA_DURATION_CHARACTER_THRESHOLD) * 1000);
      };
      handleSetTimer();
      setIsOpen(true);
    }
    return () => {
      handleRemoveTimer();
    };
  }, [id, message.length, duration]);

  return (
    <div
      className={mergeClassNames(styles.container, styles[type], isOpen ? styles.open : styles.closed)}
      onTransitionEnd={() => {
        if (!isOpen) {
          onToastClose();
        }
      }}
      data-cy="toast"
    >
      <p className={styles.memo} data-cy="toast-message">
        {message}
      </p>
      <IconButton
        icon={<XIcon />}
        onClick={() => {
          if (isOpen) {
            handleRemoveTimer();
            setIsOpen(false);
          }
        }}
        data-cy="toast-close-button"
      />
    </div>
  );
};

export default Toast;
