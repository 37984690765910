import { CreatePickTourRequest, CreatePickTourRequestItem } from './types';
import { CreatePickTourPayload } from '../actions/activePickTour.types';

export const mapCreatePickTourPayloadToCreatePickTourRequest = (
  payload: CreatePickTourPayload
): CreatePickTourRequest =>
  payload.orders.map(
    (order): CreatePickTourRequestItem => ({
      orderID: order.id,
      orderType: order.type,
      isUpcScanRequired: order.isUpcScanRequired,
      supplierID: order.supplierID,
    })
  );
