import { PICK_TOUR_STATUS_CLOSED, PICK_TOUR_SUB_STATUS_PARTFILL, PICK_TOUR_SUB_STATUS_SKIPPED } from '../../constants';
import { SetForwardingStorePayload } from '../actions/activePickTour.types';
import { APIPickTour, SkipPickTourRequest, SkipPickTourResponse } from './types';

export const mapPickTourDataToSkipPickTourRequest = (pickTourData: APIPickTour): SkipPickTourRequest => ({
  ...pickTourData,
  pickTourList: pickTourData.pickTourList.filter(pick => pick.skippedFlag),
  pickTourStatus: PICK_TOUR_STATUS_CLOSED,
  pickTourSubStatus: pickTourData.pickTourList.some(pick => !pick.skippedFlag)
    ? PICK_TOUR_SUB_STATUS_PARTFILL
    : PICK_TOUR_SUB_STATUS_SKIPPED,
});

export const mapSkipPickTourResponseToSetForwardingStorePayload = (
  pickTourResponse: SkipPickTourResponse
): SetForwardingStorePayload => ({
  storeID: pickTourResponse.forwardingDetails.receivingStoreID,
});
