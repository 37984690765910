/* eslint-disable react/no-array-index-key */

import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import PageForwardIcon from '../../../assets/icons/PageForwardIcon';
import PageBackwardIcon from '../../../assets/icons/PageBackwardIcon';

import { mergeClassNames } from '../../../utils';

import styles from './PaginationBar.css';

type Props = {
  isDisabled: boolean;
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
};

const PaginationBar = ({ isDisabled, currentPage, totalPages, onPageChange }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR';

  const handleClick = (page: number) => {
    if (!isDisabled && page > 0 && page <= totalPages) {
      onPageChange(page);
    }
  };
  const PageButton = ({ page, hasEllipsis }: { page: number; hasEllipsis?: boolean }): ReactElement => (
    <div
      className={mergeClassNames(
        styles.button,
        currentPage === page && styles.currentPage,
        isDisabled && styles.disabled
      )}
      onClick={() => handleClick(page)}
    >
      {hasEllipsis ? '...' : page}
    </div>
  );
  const previousPage = currentPage - 1;
  const secondPrevious = currentPage - 2;
  const nextPage = currentPage + 1;
  const secondNext = currentPage + 2;
  return (
    <div className={styles.container}>
      <div
        onClick={() => handleClick(previousPage)}
        className={mergeClassNames(
          styles.button,
          styles.navArrows,
          (isDisabled || previousPage < 1) && styles.disabled
        )}
      >
        <PageBackwardIcon disabled={isDisabled || previousPage < 1} />
        <span className={mergeClassNames(styles.navArrowsText, (isDisabled || previousPage < 1) && styles.disabled)}>
          {t(`${translationRoot}.PAGINATION_BAR.PREV`)}
        </span>
      </div>
      {totalPages < 8 && [...Array(totalPages)].map((_, i) => <PageButton page={i + 1} key={i} />)}
      {totalPages >= 8 && currentPage < 5 && (
        <>
          {[...Array(5)].map((_, i) => (
            <PageButton page={i + 1} key={i} />
          ))}
          <PageButton page={6} hasEllipsis />
          <PageButton page={totalPages} />
        </>
      )}
      {totalPages >= 8 && currentPage > totalPages - 4 && (
        <>
          <PageButton page={1} />
          <PageButton page={totalPages - 5} hasEllipsis />
          {[...Array(5)].map((_, i) => (
            <PageButton page={i + totalPages - 4} key={i} />
          ))}
        </>
      )}
      {totalPages >= 8 && currentPage >= 5 && currentPage <= totalPages - 4 && (
        <>
          <PageButton page={1} />
          <PageButton page={secondPrevious} hasEllipsis />
          <PageButton page={previousPage} />
          <PageButton page={currentPage} />
          <PageButton page={nextPage} />
          <PageButton page={secondNext} hasEllipsis />
          <PageButton page={totalPages} />
        </>
      )}
      <div
        onClick={() => handleClick(nextPage)}
        className={mergeClassNames(
          styles.button,
          styles.navArrows,
          (isDisabled || nextPage > totalPages) && styles.disabled
        )}
      >
        <span
          className={mergeClassNames(styles.navArrowsText, (isDisabled || nextPage > totalPages) && styles.disabled)}
        >
          {t(`${translationRoot}.PAGINATION_BAR.NEXT`)}
        </span>
        <PageForwardIcon disabled={isDisabled || nextPage > totalPages} />
      </div>
    </div>
  );
};

export default PaginationBar;
