import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Toast from '../Toast';

import { removeToast } from '../../../redux/actions/toasts.actions';
import { getActiveToastFromState } from '../../../redux/selectors';

const ToastContainer = (): ReactElement | null => {
  const dispatch = useDispatch();

  const activeToast = useSelector(getActiveToastFromState);

  return (
    activeToast && (
      <Toast
        id={activeToast.id}
        type={activeToast.type}
        message={activeToast.message}
        duration={activeToast.duration}
        onToastClose={() => dispatch(removeToast({ id: activeToast.id }))}
      />
    )
  );
};

export default ToastContainer;
