import React, { ReactElement } from 'react';

import styles from './icons.css';

type Props = {
  small?: boolean;
};

const OverstockIcon = ({ small }: Props): ReactElement => (
  <svg className={small ? styles.svgBlueSmall : styles.svgBlue} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29">
    <title>Group</title>
    <defs>
      <polygon
        id="path-1"
        points="11.0833334 7.58333334 7.58333334 7.58333334 7.58333334 11.0833334 6.41666666 11.0833334 6.41666666 7.58333334 2.91666665 7.58333334 2.91666665 6.41666666 6.41666666 6.41666666 6.41666666 2.91666665 7.58333334 2.91666665 7.58333334 6.41666666 11.0833334 6.41666666"
      />
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="icon/overstock-copy-2" transform="translate(-15.000000, -6.000000)">
        <g id="Group" transform="translate(15.000000, 6.500000)">
          <path
            d="M22,0 L17,0 C14.2385763,0 12,2.23857625 12,5 L12,10 C12,12.7614237 14.2385763,15 17,15 L22,15 C24.7614237,15 27,12.7614237 27,10 L27,5 C27,2.23857625 24.7614237,0 22,0 Z M17,2 L22,2 C23.6568542,2 25,3.34314575 25,5 L25,10 C25,11.6568542 23.6568542,13 22,13 L17,13 C15.3431458,13 14,11.6568542 14,10 L14,5 C14,3.34314575 15.3431458,2 17,2 Z"
            id="Rectangle-Copy-2"
            fill="#B7C3CE"
            fillRule="nonzero"
          />
          <g id="Order-Type-Copy" transform="translate(12.500000, 0.500000)">
            <polygon id="Base" points="-2.91666649e-08 -2.91666732e-08 14 -2.91666732e-08 14 14 -2.91666649e-08 14" />
            <mask id="mask-2" fill="white">
              <use href="#path-1" />
            </mask>
            <g id="Icon" />
            <rect id="↳-🎨-Color" fill="#B7C3CE" mask="url(#mask-2)" x="0" y="0" width="14" height="14" />
          </g>
          <path
            d="M10,27.5 C4.4771525,27.5 0,23.0228475 0,17.5 C0,11.9771525 4.4771525,7.5 10,7.5 L10,9.5 L10,9.5 C5.581722,9.5 2,13.081722 2,17.5 C2,21.918278 5.581722,25.5 10,25.5 C14.418278,25.5 18,21.918278 18,17.5 L20,17.5 L20,17.5 C20,23.0228475 15.5228475,27.5 10,27.5 Z"
            id="Combined-Shape"
            fill="#B7C3CE"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default OverstockIcon;
