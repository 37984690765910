import React, { ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

type PageProps = {
  children: ReactNode;
  name: string;
};

export type SwitchProps = {
  children: ReactElement<PageProps>[];
  currentPageName: string;
};

const translationRoot = 'ERRORS';

export const Page = ({ children }: PageProps): ReactElement => <>{children}</>;

const Switch = ({ children, currentPageName }: SwitchProps): ReactElement => {
  const { t } = useTranslation();
  return (
    React.Children.toArray(children).find(child => child.props.name === currentPageName) || (
      <h1>{t(`${translationRoot}.PAGE_NOT_EXIST`)}</h1>
    )
  );
};

export default Switch;
