import React, { ReactNode, ReactElement } from 'react';

import styles from './Header.css';

export type Props = {
  children: ReactNode;
};

const Header = ({ children }: Props): ReactElement => <header className={styles.container}>{children}</header>;

export default Header;
