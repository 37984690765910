import EventLogger from '@az/whispers-js';
import { takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { createToast } from '../actions/toasts.actions';

import { RootState } from '../reducers/rootReducer';

import { AZ_PICK_APP_NAME } from '../../constants';

import { Env } from '../../types';
import { createTokenDataCallback, getLogType, getUID } from '../../utils';

export function* runLogToast(action: ReturnType<typeof createToast>) {
  const loginData = sessionStorage.getItem('loginData');
  const env: Env | null = yield select((state: RootState) => state.config.authValues?.ENV);
  const baseUrl: Env | null = yield select((state: RootState) => state.config.authValues?.WHISPERS_URL);

  if (loginData && env) {
    const tokenCallback = createTokenDataCallback(loginData);

    if (tokenCallback) {
      const storeID: string | null = yield select((state: RootState) => state.pickerStore.storeID);

      const eventLogger = new EventLogger(AZ_PICK_APP_NAME, env, null, null, tokenCallback, null, null, baseUrl);

      eventLogger[getLogType(action.payload.type)](
        action.payload.message,
        { storeID, uid: getUID(), error: action.payload.error || null, extraData: action.payload.extraData },
        { immediate: true }
      );
    }
  }
}

export default function* watchCreateToast() {
  yield takeEvery(getType(createToast), runLogToast);
}
