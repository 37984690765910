import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';

import { setLoading } from '../actions/loading.actions';
import { retryOrder } from '../actions/orders.actions';

import { mapRetryOrderPayloadToRequest } from './retryOrder.mapper';

import { callAPI } from '../../utils';
import { handleApiErrors, catchApiErrors } from './utils';

import { BFF_ROOT, BFF_V1, BFF_PICK_TOURS, SAGA_RETRY_ORDER, RETRY_FINALIZATION } from '../../constants';

import { ConfigAuthValues } from '../../types';
import { RetryOrderRequest } from './types';
import { createToast } from '../actions/toasts.actions';

const callRetryOrder = (data: RetryOrderRequest, configValues: ConfigAuthValues) => {
  return callAPI({
    method: 'POST',
    url: `${configValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}${RETRY_FINALIZATION}`,
    data,
  });
};

export function* runRetryOrder(action: ReturnType<typeof retryOrder>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  yield put(setLoading({ sagaName: SAGA_RETRY_ORDER, isLoading: true }));
  try {
    const result = yield call(callRetryOrder, mapRetryOrderPayloadToRequest(action.payload.order), configAuthValues);
    yield put(createToast({ type: 'success', message: 'Retry initiated successfully' }));
    yield handleApiErrors(result?.data?.errors);
  } catch (error) {
    try {
      const data = mapRetryOrderPayloadToRequest(action.payload.order)
      const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}${RETRY_FINALIZATION}`
      const method = 'POST';
      const config = configAuthValues;
      yield catchApiErrors(error, {url, method, data, config, saga: SAGA_RETRY_ORDER});
    } catch (_error) {
      yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_RETRY_ORDER, isLoading: false }));
}

export default function* watchRetryOrder() {
  yield takeEvery(getType(retryOrder), runRetryOrder);
}
