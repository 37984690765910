import React, { ReactElement } from 'react';

const ReprintRecallSheetIcon = (): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#DD3900" />
    <path
      d="M30.8923 9.05882L29.7846 8L28.6769 9.05882L27.5692 8L26.4615 9.05882L25.3538 8L24.2462 9.05882L23.1385 8L22.0308 9.05882L20.9231 8V17.8824H18.7077V20C18.7077 21.1718 19.6972 22.1177 20.9231 22.1177H29.7846C31.0105 22.1177 32 21.1718 32 20V8L30.8923 9.05882ZM30.5231 20C30.5231 20.3882 30.1908 20.7059 29.7846 20.7059C29.3785 20.7059 29.0462 20.3882 29.0462 20V17.8824H22.4V10.1176H30.5231V20Z"
      fill="white"
    />
    <path d="M27.5693 11.5294H23.1385V12.9412H27.5693V11.5294Z" fill="white" />
    <path d="M29.7846 11.5294H28.3077V12.9412H29.7846V11.5294Z" fill="white" />
    <path d="M27.5693 13.6471H23.1385V15.0588H27.5693V13.6471Z" fill="white" />
    <path d="M29.7846 13.6471H28.3077V15.0588H29.7846V13.6471Z" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6638 19.2941H10.9538V22.1176H17.0477C16.4161 21.2784 15.9412 20.3242 15.6638 19.2941ZM17.6411 22.8235H10.2154C8.98954 22.8235 8 23.7694 8 24.9412V29.1765H10.9538V32H19.8154V29.1765H22.7692V25.5299C22.2487 25.4465 21.7435 25.3196 21.258 25.1534C21.1632 25.4388 20.8827 25.6471 20.5538 25.6471C20.1477 25.6471 19.8154 25.3294 19.8154 24.9412C19.8154 24.8066 19.8553 24.6805 19.9244 24.5731C19.0647 24.1132 18.2935 23.5203 17.6411 22.8235ZM12.4308 30.5882H18.3385V27.0588H12.4308V30.5882Z"
      fill="white"
    />
  </svg>
);

export default ReprintRecallSheetIcon;
