import React, { ReactElement, SetStateAction } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import SkipItemIcon from '../../../../../assets/icons/SkipItemIcon';

import { setActivePickID } from '../../../../../redux/actions/activePickTour.actions';
import { getHasJustScannedFromState } from '../../../../../redux/selectors';
import { Pick, PickLocation } from '../../../../../types';

import { OTHER, POG_DYNAMIC_SLOT, POG_OVERSTOCK } from '../../../../../constants';

import {
  mergeClassNames,
  displayOrderType,
  determineIconByOrderType,
  generatePickID,
  parseDynamicSlotDetails,
  calculateAmountToPick,
} from '../../../../../utils';

import styles from './PickItem.css';

type Props = {
  pick: Pick;
  isActive?: boolean;
  isTourComplete?: boolean;
  setActiveTab?: (value: SetStateAction<'Details View' | 'List View'>) => void;
};

const PickItem = ({ pick, isActive, isTourComplete, setActiveTab }: Props): ReactElement => {
  const hasJustScanned = useSelector(getHasJustScannedFromState);

  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.LIST_VIEW';

  const dispatch = useDispatch();

  const isSkippedAndTourFinished = isTourComplete && pick.isSkipped;

  const visibleLocations = pick.locations.filter(location => location.isVisible);

  const displaySkipInfo = () => {
    switch (pick.orderType) {
      case 'OVERSTOCK': {
        if (pick.pickedQuantity > 0) {
          return t(`${translationRoot}.ITEM_ADJUSTED`);
        }
        return t(`${translationRoot}.ITEM_NOT_FOUND`);
      }
      case 'SAT2SAT': {
        return `${t(`${translationRoot}.ALL_LOCATIONS_SKIPPED`)} | ${t(
          `${translationRoot}.${pick.skipReason === OTHER ? 'OTHER' : 'QTY_ON_HAND'}`
        )}`;
      }
      default: {
        return t(`${translationRoot}.ALL_LOCATIONS_SKIPPED`);
      }
    }
  };

  const displayLocation = (location: PickLocation) => {
    switch (location.planogramName) {
      case POG_DYNAMIC_SLOT: {
        const dynamicSlotDetails = parseDynamicSlotDetails(location.itemSequence.toString());
        return `${t(`${translationRoot}.ZONE`)} ${dynamicSlotDetails.zone} | ${t(`${translationRoot}.AISLE`)} ${
          dynamicSlotDetails.aisle
        } | ${t(`${translationRoot}.SLOT`)} ${dynamicSlotDetails.slot}`;
      }
      case POG_OVERSTOCK: {
        return `${t(`${translationRoot}.POG_FOG`)}: ${location.planogramName} | ${t(
          `${translationRoot}.SLOT`
        )}: ${location.itemSequence || 0}`;
      }
      default: {
        return `${t(`${translationRoot}.POG_FOG`)}: ${location.planogramName} | ${t(
          `${translationRoot}.SEQ`
        )}: ${location.itemSequence || 0}`;
      }
    }
  };

  return (
    <li
      className={mergeClassNames(
        styles.root,
        isActive && visibleLocations.length < 2 && (hasJustScanned ? styles.scanned : styles.active),
        isSkippedAndTourFinished && visibleLocations.length < 2 && styles.isSkipped
      )}
      onClick={() => {
        if (isSkippedAndTourFinished && visibleLocations.length < 2) {
          dispatch(setActivePickID({ id: generatePickID(pick) }));
          if (setActiveTab) {
            setActiveTab('Details View');
          }
        }
      }}
    >
      <div className={styles.details}>
        <div className={styles.detailsLine}>
          <span className={styles.description}>{pick.description}</span>
          <div className={styles.qtyContainer}>
            <span className={styles.qtyLabel}>{t(`${translationRoot}.ITEM_QTY`)}</span>
            <span className={styles.qty}>
              {pick.pickedQuantity}/{calculateAmountToPick(pick)}
            </span>
          </div>
        </div>
        <div className={styles.detailsLine}>
          <div className={styles.orderTypeContainer}>
            {determineIconByOrderType({ orderType: pick.orderType, small: true })}
            <span className={styles.orderType}>{displayOrderType(pick.orderType, null, pick.destination)}</span>
          </div>
          <span className={styles.partNumber}>
            {t(`${translationRoot}.PART_NUMBER`)}: {pick.partNumber}
          </span>
        </div>
      </div>
      <ul>
        {pick.isSkipped && (
          <li className={styles.skipContainer}>
            <SkipItemIcon small />
            <span className={styles.skip}>{displaySkipInfo()}</span>
          </li>
        )}
        {visibleLocations.length > 0 ? (
          visibleLocations.map((location, index) => (
            <li
              key={`${location.planogramName}:${location.itemSequence}:${location.vdlSequence}:${location.planoID ||
                0}`}
              className={mergeClassNames(
                styles.locationContainer,
                isActive &&
                  visibleLocations.length > 1 &&
                  pick.locationIndex === index &&
                  (hasJustScanned ? styles.scanned : styles.active),
                isSkippedAndTourFinished && visibleLocations.length > 1 && styles.isSkipped
              )}
              onClick={() => {
                if (isSkippedAndTourFinished && visibleLocations.length > 1) {
                  dispatch(setActivePickID({ id: generatePickID(pick), locationIndex: index }));
                  if (setActiveTab) {
                    setActiveTab('Details View');
                  }
                }
              }}
            >
              <span className={styles.location}>{displayLocation(location)}</span>
            </li>
          ))
        ) : (
          <li className={styles.locationContainer}>
            <span className={styles.location}>{t(`${translationRoot}.NO_LOCATION_DATA`)}</span>
          </li>
        )}
      </ul>
    </li>
  );
};

export default PickItem;
