import React, { ReactElement, ReactNode } from 'react';

import { mergeClassNames } from '../../../utils';

import styles from './Footer.css';

type Props = {
  children: ReactNode;
  className: string;
};

const Footer = ({ children, className }: Props): ReactElement => (
  <footer className={mergeClassNames(styles.container, className)}>{children}</footer>
);

export default Footer;
