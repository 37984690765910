import { all } from 'redux-saga/effects';

import adjustSlotQty from './adjustSlotQty.saga';
import createPickTour from './createPickTour.saga';
import forwardOrders from './forwardOrders.saga';
import getPickTourItems from './getPickTourItems.saga';
import getExternalConfig from './getExternalConfig.saga';
import getOrders from './getOrders.saga';
import getRecallOrders from './getRecallOrders.saga';
import getCompletedRecallTours from './getCompletedRecallTours.saga';
import getProximityStore from './getProximityStore.saga';
import getEmployeeMetadata from './getEmployeeMetadata.saga';
import logToast from './logToast.saga';
import skipPickTour from './skipPickTour.saga';
import updatePickTourItem from './updatePickTourItem.saga';
import updatePickTour from './updatePickTour.saga';
import retryOrder from './retryOrder.saga';
import printPickList from './printPickList.saga';
import printLocationReport from './printLocationReport.saga';
import printDiscrepancyReport from './printDiscrepancyReport.saga';
import printRecallReport from './printRecallReport.saga';
import driverLogoff from './driverLogoff.saga';
import sendLogSaga from './log.saga';

export default function* RootSaga() {
  yield all([
    adjustSlotQty(),
    createPickTour(),
    forwardOrders(),
    getPickTourItems(),
    getExternalConfig(),
    getOrders(),
    getRecallOrders(),
    getCompletedRecallTours(),
    getProximityStore(),
    getEmployeeMetadata(),
    logToast(),
    skipPickTour(),
    updatePickTourItem(),
    updatePickTour(),
    retryOrder(),
    printPickList(),
    printLocationReport(),
    printDiscrepancyReport(),
    printRecallReport(),
    driverLogoff(),
    sendLogSaga(),
  ]);
}
