import React, { ReactElement, useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getOrders } from '../../../redux/actions/orders.actions';
import refreshImage from '../../../assets/img/refresh-icon.svg'
import styles from './RefreshButton.css';

export type StateProps = {
  lastAttemptSuccess: boolean;
  lastAttemptTimeStamp: number;
  pickerStoreID: string | null
};

type Props = StateProps;
const RefreshButton = ({ lastAttemptSuccess, lastAttemptTimeStamp, pickerStoreID }: Props): ReactElement => {

  const [disabled, setDisabled] = useState(true)
  const dispatch = useDispatch();


  useEffect(() => {
    setDisabled(true)
    const timeElapsed = Date.now() - lastAttemptTimeStamp
    const timeOut = lastAttemptSuccess ? 5000 - timeElapsed : 2000 - timeElapsed
    const timer = setTimeout(() => {
      setDisabled(false)
    }, timeOut);
    return () => clearTimeout(timer);
  }, [lastAttemptTimeStamp, lastAttemptSuccess]);

  const { t } = useTranslation();
  const translationRoot = 'HEADER';


  const refreshOrders = useCallback(() => {
    if (pickerStoreID) {
      dispatch(getOrders({ fulfillmentStoreID: pickerStoreID, refreshOrders: true }));
    }
  }, [pickerStoreID, dispatch]);


  return (
    <button type='button' className={styles.container} disabled={disabled} onClick={refreshOrders}>

      <img src={refreshImage} alt='refresh' className={styles.refreshIcon} />
      {t(`${translationRoot}.REFRESH`)}
    </button>
  )
};

export default RefreshButton;
