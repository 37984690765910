import { getType } from 'typesafe-actions';

import {
  setActivePickTour,
  addPickTourPage,
  setActivePickTourPage,
  incrementActivePickPickedQuantity,
  setActivePickID,
  setHasJustScanned,
  setActivePick,
  skipLocation,
  setForwardingStore,
  updateQuantityOnHand,
} from '../actions/activePickTour.actions';

import { calculateAmountToPick, generatePickID, sortPicksByLocation } from '../../utils';

import { ActivePickTourState } from './types';
import { RootAction } from '../actions/types';

export default (state: ActivePickTourState = null, action: RootAction): ActivePickTourState => {
  switch (action.type) {
    case getType(setActivePickTour): {
      const sortedPicks = sortPicksByLocation({
        picks: action.payload.pickTour.picks,
        shouldUseFirstLocation: action.payload.pickTour.picks.some(pick => pick.orderType === 'RECALL'),
      });
      return {
        ...action.payload,
        pickTour: {
          ...action.payload.pickTour,
          picks: sortedPicks,
        },
        activePickID: generatePickID(
          sortedPicks.find(pick => pick.pickedQuantity < calculateAmountToPick(pick) && !pick.isSkipped) ||
            sortedPicks[0]
        ),
        forwardingStore: state ? state.forwardingStore : null,
      };
    }
    case getType(addPickTourPage): {
      if (state) {
        const sortedPicks = sortPicksByLocation({ picks: [...state.pickTour.picks, ...action.payload.picks] });
        return (
          state && {
            ...state,
            pickTour: {
              ...state.pickTour,
              picks: sortedPicks,
            },
            data: {
              ...state.data,
              pickTourList: [...state.data.pickTourList, ...action.payload.pickTourListData],
            },
            activePickID: generatePickID(
              sortedPicks.find(pick => pick.pickedQuantity < calculateAmountToPick(pick) && !pick.isSkipped) ||
                sortedPicks[0]
            ),
          }
        );
      }
      return state;
    }
    case getType(setActivePickTourPage): {
      const sortedPicks = sortPicksByLocation({ picks: action.payload.picks });
      return (
        state && {
          ...state,
          pickTour: {
            ...state.pickTour,
            picks: action.payload.picks,
          },
          data: {
            ...state.data,
            pickTourList: action.payload.pickTourListData,
          },
          activePickID: generatePickID(
            sortedPicks.find(pick => pick.pickedQuantity < calculateAmountToPick(pick) && !pick.isSkipped) ||
              sortedPicks[0]
          ),
          hasJustScanned: false,
          isPaginated: true,
          paginationMetadata: action.payload.paginationMetadata,
        }
      );
    }
    case getType(incrementActivePickPickedQuantity): {
      return (
        state && {
          ...state,
          pickTour: {
            ...state.pickTour,
            picks: state.pickTour.picks.map(pick =>
              generatePickID(pick) === state.activePickID
                ? {
                    ...pick,
                    pickedQuantity: pick.pickedQuantity + action.payload.quantity,
                    locations: pick.locations.map((location, index) =>
                      index === pick.locationIndex
                        ? { ...location, pickedQuantity: location.pickedQuantity + action.payload.quantity }
                        : location
                    ),
                    isSkipped: false,
                    skipReason: null,
                  }
                : pick
            ),
          },
        }
      );
    }
    case getType(setActivePickID): {
      return (
        state && {
          ...state,
          activePickID: action.payload.id,
          pickTour:
            action.payload.locationIndex !== undefined
              ? {
                  ...state.pickTour,
                  picks: state.pickTour.picks.map(pick =>
                    generatePickID(pick) === action.payload.id
                      ? {
                          ...pick,
                          locationIndex: action.payload.locationIndex as number,
                        }
                      : pick
                  ),
                }
              : state.pickTour,
        }
      );
    }
    case getType(setHasJustScanned): {
      return (
        state && {
          ...state,
          hasJustScanned: action.payload.hasJustScanned,
        }
      );
    }
    case getType(setActivePick): {
      if (state) {
        const updatedPicks = state.pickTour.picks.map(pick =>
          generatePickID(pick) === action.payload.previousPickDetails.pickID
            ? {
                ...pick,
                ...action.payload.previousPickDetails,
              }
            : pick
        );
        const activePickID = generatePickID(action.payload.activePick);
        const nonPaginationState = {
          ...state,
          pickTour: {
            ...state.pickTour,
            picks: updatedPicks,
          },
          data: {
            ...state.data,
            pickTourList: state.data.pickTourList.map(pickTourListItem =>
              `${pickTourListItem.orderType}:${pickTourListItem.orderID}:${pickTourListItem.pickTourItemDetailLineNumber}` ===
              `${action.payload.data.orderType}:${action.payload.data.orderID}:${action.payload.data.pickTourItemDetailLineNumber}`
                ? { ...action.payload.data, location: pickTourListItem.location }
                : pickTourListItem
            ),
          },
          activePickID,
          hasJustScanned: false,
        };
        if (state.isPaginated) {
          return {
            ...nonPaginationState,
            isPaginated: state.isPaginated,
            paginationMetadata: state.paginationMetadata,
          };
        }
        return { ...nonPaginationState, isPaginated: state.isPaginated };
      }
      return state;
    }
    case getType(updateQuantityOnHand): {
      return (
        state && {
          ...state,
          ...(state.isPaginated && { paginationMetadata:{...state.paginationMetadata,totalItems:action.payload.totalItems}}),
          pickTour: {
            ...state.pickTour,
            picks: state.pickTour.picks.map(pick =>
              generatePickID(pick) === state.activePickID
                ? {
                    ...pick,
                    pickedQuantity: action.payload.resetPickedQuantity ? 0 : pick.pickedQuantity,
                    locations: pick.locations.map((location, index) =>
                      index === pick.locations.length - 1
                        ? {
                            ...location,
                            pickedQuantity: action.payload.resetPickedQuantity ? 0 : location.pickedQuantity,
                            updatedQuantity: action.payload.updatedQuantity,
                          }
                        : {
                            ...location,
                            pickedQuantity: action.payload.resetPickedQuantity ? 0 : location.pickedQuantity,
                          }
                    ),
                    isSkipped: false,
                    skipReason: null,
                  }
                : pick
            ),
          },
        }
      );
    }
    case getType(skipLocation): {
      return (
        state && {
          ...state,
          pickTour: {
            ...state.pickTour,
            picks: action.payload.updatedPicks,
          },
          activePickID: generatePickID(action.payload.updatedNextPick),
          hasJustScanned: false,
        }
      );
    }
    case getType(setForwardingStore): {
      return (
        state && {
          ...state,
          forwardingStore: action.payload.storeID,
        }
      );
    }
    default: {
      return state;
    }
  }
};
