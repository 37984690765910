import { PICK_TOUR_STATUS_CLOSED, PICK_TOUR_SUB_STATUS_PARTFILL } from '../../constants';

import { APIPickTour, ForwardOrdersRequest } from './types';

export const mapPickTourDataToForwardOrdersRequest = (pickTourData: APIPickTour): ForwardOrdersRequest => ({
  ...pickTourData,
  pickTourStatus: PICK_TOUR_STATUS_CLOSED,
  pickTourSubStatus: PICK_TOUR_SUB_STATUS_PARTFILL,
  pickTourList: pickTourData.pickTourList
    .filter(pick => pick.skippedFlag)
    .map(pick => ({
      ...pick,
      // This is set to "Deliver" in SHOP but right now there is just SAT2SAT and COMMERCIAL
      orderType: pick.orderType,
    })),
});
