import React, { ReactElement } from 'react';

import styles from './icons.css';

type Props = {
  small?: boolean;
};

const ShuttleIcon = ({ small }: Props): ReactElement => (
  <svg
    className={small ? styles.svgBlueSmall : styles.svgBlue}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    data-cy="shuttle-icon"
  >
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M17 5H3c-1.1 0-2 .89-2 2v9h2c0 1.65 1.34 3 3 3s3-1.35 3-3h5.5c0 1.65 1.34 3 3 3s3-1.35 3-3H23v-5l-6-6zM3 11V7h4v4H3zm3 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm7-6.5H9V7h4v4zm4.5 6.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM15 11V7h1l4 4h-5z" />
  </svg>
);

export default ShuttleIcon;
