import React, { ReactElement } from 'react';

import styles from './icons.css';

const CheckSquare = (): ReactElement => (
  <svg className={styles.svg} width="19px" height="17px" viewBox="0 0 19 17" version="1.1">
    <defs>
      <path
        d="M8.24226667,12.9943202 L3.80886667,8.54938641 L5.39916667,6.95437058 L8.16576667,9.72760512 L16.3719667,0.678055476 L18.0360667,2.19548569 L8.24226667,12.9943202 Z M14.5044667,16.7734612 L1.90446667,16.7734612 C0.911766667,16.7734612 0.104466667,15.9642253 0.104466667,14.9691447 L0.104466667,4.1432454 C0.104466667,3.14906699 0.911766667,2.33892886 1.90446667,2.33892886 L10.9044667,2.33892886 L10.9044667,4.1432454 L1.90446667,4.1432454 L1.90446667,14.9691447 L14.5044667,14.9691447 L14.5044667,10.4583533 L16.3044667,10.4583533 L16.3044667,14.9691447 C16.3044667,15.9642253 15.4971667,16.7734612 14.5044667,16.7734612 Z"
        id="path-1"
      />
    </defs>
    <g id="Updates---September-2020" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" transform="translate(-6.000000, -8.000000)">
        <g id="icon" transform="translate(6.666667, 7.350919)">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1" />
          </mask>
          <g id="path-1" />
          <g id="Group" mask="url(#mask-2)">
            <g transform="translate(-1.333333, -1.336531)">
              <rect
                id="Swatches/Fill/Grey/Ultra-Dark"
                fill="#FFFFFF"
                x="0"
                y="0"
                width="21.3333333"
                height="20.0479616"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckSquare;
