import { Order } from '../../types';
import { RetryOrderRequest } from './types';

export const mapRetryOrderPayloadToRequest = (payload: Order): RetryOrderRequest => ({
  orderID: payload.id,
  orderType: payload.type,
  finalizationErrorCode: payload.finalizationErrorCode,
  finalizationErrorMessage: payload.finalizationErrorMessage,
  isRetryEnabled: payload.isRetryEnabled,
  quantity: payload.quantity,
  deliveryMethod: payload.deliveryMethod,
  destination: payload.destination,
  isUpcScanRequired: payload.isUpcScanRequired,
  date: payload.date,
  orderSequenceNumber: payload.sequenceNumber,
  recallDescription: payload.recallDescription,
  recallType: payload.recallType,
  supplierID: payload.supplierID,
});
