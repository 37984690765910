import { createAction } from 'typesafe-actions';

import {
  AdjustSlotQtyPayload,
  CreatePickTourPayload,
  GetPickTourItemsPayload,
  SetActivePickTourPayload,
  AddPickTourPagePayload,
  SetActivePickTourPagePayload,
  IncrementActivePickPickedQuantityPayload,
  SetActivePickIDPayload,
  SetHasJustScannedPayload,
  UpdatePickTourItemPayload,
  SetActivePickPayload,
  SkipLocationPayload,
  UpdatePickTourPayload,
  SetForwardingStorePayload,
  ForwardOrdersPayload,
  PrintPickListPayload,
  PrintLocationReportPayload,
  PrintRecallReportPayload,
  PrintDiscrepancyReportPayload,
  UpdateQuantityOnHandPayload,
} from './activePickTour.types';

export const adjustSlotQty = createAction('ADJUST_SLOT_QTY')<AdjustSlotQtyPayload>();

export const createPickTour = createAction('CREATE_PICK_TOUR')<CreatePickTourPayload>();

export const getPickTourItems = createAction('GET_PICK_TOUR_ITEMS')<GetPickTourItemsPayload>();

export const setActivePickTour = createAction('SET_ACTIVE_PICK_TOUR')<SetActivePickTourPayload>();

export const addPickTourPage = createAction('ADD_PICK_TOUR_PAGE')<AddPickTourPagePayload>();

export const setActivePickTourPage = createAction('SET_ACTIVE_PICK_TOUR_PAGE')<SetActivePickTourPagePayload>();

export const incrementActivePickPickedQuantity = createAction('SET_ACTIVE_PICK_PICKED_QUANTITY')<
  IncrementActivePickPickedQuantityPayload
>();

export const setActivePickID = createAction('SET_ACTIVE_PICK_ID')<SetActivePickIDPayload>();

export const setHasJustScanned = createAction('SET_HAS_JUST_SCANNED')<SetHasJustScannedPayload>();

export const updatePickTourItem = createAction('UPDATE_PICK_TOUR_ITEM')<UpdatePickTourItemPayload>();

export const setActivePick = createAction('SET_ACTIVE_PICK')<SetActivePickPayload>();

export const skipLocation = createAction('SKIP_LOCATION')<SkipLocationPayload>();

export const updatePickTour = createAction('UPDATE_PICK_TOUR')<UpdatePickTourPayload>();

export const printPickList = createAction('PRINT_PICK_LIST')<PrintPickListPayload>();

export const skipPickTour = createAction('SKIP_PICK_TOUR')();

export const forwardOrders = createAction('FORWARD_ORDERS')<ForwardOrdersPayload>();

export const setForwardingStore = createAction('SET_FORWARDING_STORE')<SetForwardingStorePayload>();

export const printLocationReport = createAction('PRINT_LOCATION_REPORT')<PrintLocationReportPayload>();

export const printRecallReport = createAction('PRINT_RECALL_REPORT')<PrintRecallReportPayload>();

export const printDiscrepancyReport = createAction('PRINT_DISCREPANCY_REPORT')<PrintDiscrepancyReportPayload>();

export const updateQuantityOnHand = createAction('UPDATE_QUANTITY_ON_HAND')<UpdateQuantityOnHandPayload>();
