import React, { ReactElement } from 'react';

import { mergeClassNames } from '../../../utils';

import styles from './IconButton.css';

type Props = {
  className?: string;
  icon: ReactElement;
  onClick: () => void;
};

const IconButton = ({ className, icon, onClick, ...other }: Props): ReactElement => (
  <button type="button" className={mergeClassNames(styles.button, className)} onClick={onClick} {...other}>
    {icon}
  </button>
);

export default IconButton;
