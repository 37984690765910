import { getType } from 'typesafe-actions';

import { setLoading } from '../actions/loading.actions';

import { SAGA_NAMES } from '../../constants';

import { RootAction } from '../actions/types';
import { LoadingState } from './types';

const initialState: LoadingState = SAGA_NAMES.reduce<LoadingState>((currentValues, sagaName) => {
  return { ...currentValues, [sagaName]: 0 };
}, {} as LoadingState);

export default (state = initialState, action: RootAction) => {
  switch (action.type) {
    case getType(setLoading): {
      return {
        ...state,
        [action.payload.sagaName]: state[action.payload.sagaName] + (action.payload.isLoading ? 1 : -1),
      };
    }
    default: {
      return state;
    }
  }
};
