import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { determineIsLoadingFromState } from '../../../redux/selectors';
import { mergeClassNames } from '../../../utils';

import styles from './Spinner.css';

export type Props = {
  isInitialSetup?: boolean;
};

const Spinner = ({ isInitialSetup }: Props): ReactElement | null => {
  const [isActive, setIsActive] = useState(isInitialSetup);

  const overlayRef = useRef<HTMLDivElement>(null);
  const transitionTimer = useRef<number | null>(null);

  const isLoading = useSelector(determineIsLoadingFromState);

  const setFocus = () => {
    if (overlayRef.current) {
      overlayRef.current.focus();
    }
  };

  useEffect(() => {
    setFocus();
  }, [overlayRef]);

  useEffect(() => {
    // This constant should match the css transition time on .container
    const TRANSITION_TIME = 400;
    if (isLoading && !isActive) {
      setIsActive(true);
    } else if (!isLoading && isActive) {
      transitionTimer.current = window.setTimeout(() => {
        setIsActive(false);
      }, TRANSITION_TIME);
    }
    return () => {
      if (transitionTimer.current !== null) {
        window.clearTimeout(transitionTimer.current);
      }
    };
  }, [isLoading, isActive]);

  return isLoading || isActive ? (
    <div
      className={mergeClassNames(
        styles.container,
        (isLoading || isInitialSetup) && isActive ? styles.visible : styles.hidden
      )}
      ref={overlayRef}
      tabIndex={-1}
      onBlur={() => setFocus()}
    >
      <svg className={styles.spinner} viewBox="0 0 38 38">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18" transform="rotate(212.244 18 18)" />
          </g>
        </g>
      </svg>
    </div>
  ) : null;
};

export default Spinner;
