import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './assets/translations/translationEN.json';
import translationES from './assets/translations/translationES.json';

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  'en-US': {
    translation: translationEN,
  },
  'es-MX': {
    translation: translationES,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en-US',

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
