import React, { ReactElement, ReactNode } from 'react';

import styles from './Card.css';

export type Props = {
  children: ReactNode;
};

const Card = ({ children }: Props): ReactElement => <section className={styles.section}>{children}</section>;

export default Card;
