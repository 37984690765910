import { mapAPIPickTourListItemToPick, mapAPIPickTourListToPicks } from './sharedMappers';
import { calculateAmountToPick, generatePickID } from '../../utils';

import { APIPickTour, APIPickTourPage, APIPickTourMetadata, GetPickTourItemsResponse } from './types';
import { PaginationMetadata } from '../../types';
import {
  SetActivePickTourPayload,
  SetActivePickTourPagePayload,
  AddPickTourPagePayload,
} from '../actions/activePickTour.types';

export const mapAPIPickTourPageAndMetadataToPaginationMetadata = (
  pickTourPage: APIPickTourPage,
  pickTourMetadata: APIPickTourMetadata,
  pageItemsPicked: number,
  pageItemsSkipped: number
): PaginationMetadata => ({
  initialPageItemsPicked: pageItemsPicked,
  initialPageItemsSkipped: pageItemsSkipped,
  nextPick:
    pickTourMetadata.nextPickTourItem &&
    (pickTourMetadata.nextItemPageNumber === pickTourPage.number && !pickTourMetadata.nextPickTourItem.skippedFlag
      ? null
      : mapAPIPickTourListItemToPick(pickTourMetadata.nextPickTourItem)),
  nextPickPageNumber:
    pickTourMetadata.nextPickTourItem &&
    pickTourMetadata.nextItemPageNumber === pickTourPage.number &&
    !pickTourMetadata.nextPickTourItem.skippedFlag
      ? null
      : pickTourMetadata.nextItemPageNumber,
  pageNumber: pickTourPage.number,
  totalElements: pickTourPage.totalElements,
  totalItems: pickTourMetadata.totalItems,
  totalItemsPicked: pickTourMetadata.totalItemsPicked,
  totalItemsSkipped: pickTourMetadata.totalItemsSkipped,
  totalPages: pickTourPage.totalPages,
  unhandledItemsRemaining: pickTourMetadata.unhandledItemsRemaining,
});

export const mapGetPickTourItemsResponseToSetActivePickTourPayload = (
  response: GetPickTourItemsResponse,
  pickTourData: APIPickTour
): SetActivePickTourPayload => {
  // Will potentially need to include other order types for this check in the future
  const isPaginated = response.content[0].orderType === 'OVERSTOCK' || response.content[0].orderType === 'RECALL';
  const partialActivePickTourState = {
    pickTour: {
      id: pickTourData.pickTourID,
      picks: mapAPIPickTourListToPicks(response.content),
    },
    data: {
      ...pickTourData,
      pickTourList: response.content,
    },
    activePickID: generatePickID(
      mapAPIPickTourListToPicks(response.content).find(pick => pick.pickedQuantity < calculateAmountToPick(pick)) ||
        mapAPIPickTourListToPicks(response.content)[0]
    ),
    hasJustScanned: false,
    isUpcScanRequired:
      response.pickTourMetadata.isUpcScanRequired === null || response.pickTourMetadata.isUpcScanRequired,
    isTourMixedValidation: response.pickTourMetadata.scanValidationMixed,
  };
  if (isPaginated) {
    return {
      ...partialActivePickTourState,
      isPaginated,
      paginationMetadata: mapAPIPickTourPageAndMetadataToPaginationMetadata(
        response.page,
        response.pickTourMetadata,
        response.content.reduce(
          (pageItemsPicked, pickTourListItem) => pageItemsPicked + pickTourListItem.pickedItemQuantity,
          0
        ),
        response.content.reduce(
          (pageItemsSkipped, pickTourListItem) =>
            pageItemsSkipped +
            (pickTourListItem.skippedFlag
              ? pickTourListItem.orderItemQuantity - pickTourListItem.pickedItemQuantity
              : 0),
          0
        )
      ),
    };
  }
  return { ...partialActivePickTourState, isPaginated };
};

export const mapGetPickTourItemsResponseToSetActivePickTourPagePayload = (
  response: GetPickTourItemsResponse
): SetActivePickTourPagePayload => ({
  picks: mapAPIPickTourListToPicks(response.content),
  pickTourListData: response.content,
  paginationMetadata: mapAPIPickTourPageAndMetadataToPaginationMetadata(
    response.page,
    response.pickTourMetadata,
    response.content.reduce(
      (pageItemsPicked, pickTourListItem) => pageItemsPicked + pickTourListItem.pickedItemQuantity,
      0
    ),
    response.content.reduce(
      (pageItemsSkipped, pickTourListItem) =>
        pageItemsSkipped +
        (pickTourListItem.skippedFlag ? pickTourListItem.orderItemQuantity - pickTourListItem.pickedItemQuantity : 0),
      0
    )
  ),
});

export const mapGetPickTourItemsResponseToAddPickTourPagePayload = (
  response: GetPickTourItemsResponse
): AddPickTourPagePayload => ({
  picks: mapAPIPickTourListToPicks(response.content),
  pickTourListData: response.content,
});
