import React, { ChangeEvent, KeyboardEvent, ReactElement } from 'react';

import { mergeClassNames } from '../../../utils';

import styles from './TextField.css';

type Props = {
  className?: string;
  disabled?: boolean;
  name?: string;
  placeholder?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  value: string;
  type?: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
};

const TextField = ({
  className,
  disabled,
  name,
  placeholder,
  inputRef,
  value,
  type,
  onChange,
  onKeyUp,
  autoFocus,
  ...other
}: Props): ReactElement => (
  <input
    className={mergeClassNames(styles.input, className)}
    disabled={disabled}
    name={name}
    placeholder={placeholder}
    ref={inputRef}
    type={type || 'text'}
    value={value}
    onChange={onChange}
    onKeyUp={onKeyUp}
    autoFocus={autoFocus}
    {...other}
  />
);

export default TextField;
