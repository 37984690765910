import React, { ReactElement } from 'react';

import { mergeClassNames } from '../../utils';

import styles from './icons.css';

const ReturnToQueueIcon = (): ReactElement => (
  <svg
    className={mergeClassNames(styles.svg, styles.returnToQueue)}
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    data-cy="arrow-back-button-icon"
  >
    <path d="M19 3H4.99c-1.11 0-1.98.9-1.98 2L3 19c0 1.1.88 2 1.99 2H19c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 12h-4c0 1.66-1.35 3-3 3s-3-1.34-3-3H4.99V5H19v10zm-3-5h-2V7h-4v3H8l4 4 4-4z" />
    <path d="M0 0h24v24H0V0z" fill="none" />
  </svg>
);

export default ReturnToQueueIcon;
