import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { getCompletedRecalls, setOrders } from '../actions/orders.actions';
import { setLoading } from '../actions/loading.actions';
import { mapGetCompletedRecallToursResponseToSetCompletedRecallToursPayload } from './getCompletedRecallTours.mappers';

import { BFF_ROOT, BFF_V1, BFF_STORES, BFF_COMPLETED_RECALLS, SAGA_GET_COMPLETED_RECALLS } from '../../constants';

import { GetCompletedRecallOrdersPayload } from '../actions/orders.types';
import { OrdersState } from '../reducers/types';
import { ConfigAuthValues } from '../../types';

import { callAPI } from '../../utils';

import { handleApiErrors, catchApiErrors } from './utils';

const callGetCompletedRecalls = (
  orderCallData: GetCompletedRecallOrdersPayload,
  configAuthValues: ConfigAuthValues,
  startDate: string,
  currentDate: string
) => {
  return callAPI({
    method: 'GET',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_STORES}/${orderCallData.fulfillmentStoreID}${BFF_COMPLETED_RECALLS}?startDate=${startDate}&endDate=${currentDate}&page=${orderCallData.pageNumber}&size=10`,
  });
};

export function* runGetCompletedRecallsSaga(action: { type: string; payload: GetCompletedRecallOrdersPayload }) {
  yield put(setLoading({ sagaName: SAGA_GET_COMPLETED_RECALLS, isLoading: true }));

  const configAuthValues = yield select((state: RootState) => state.config.authValues);
  const ordersState: OrdersState = yield select((state: RootState) => state.orders);

  const dateObject = new Date();
  const startDateObject = new Date(new Date().setDate(dateObject.getDate() - action.payload.daysAgo));
  const startDate = startDateObject.toISOString().split('T')[0];
  const currentDate = dateObject.toISOString().split('T')[0];

  try {
    const result = yield call(
      callGetCompletedRecalls,
      {
        fulfillmentStoreID: action.payload.fulfillmentStoreID,
        daysAgo: action.payload.daysAgo,
        pageNumber: action.payload.pageNumber
      },
      configAuthValues,
      startDate,
      currentDate
    );

    const completedRecalls = mapGetCompletedRecallToursResponseToSetCompletedRecallToursPayload(result);


    yield put(
      setOrders({
        orders: ordersState.orders,
        pickTours: ordersState.pickTours,
        completedRecalls,
      })
    );

    yield handleApiErrors(result.data.errors);
  } catch (error) {
    try {
      const orderCallData = {
        fulfillmentStoreID: action.payload.fulfillmentStoreID,
        daysAgo: action.payload.daysAgo,
        pageNumber: action.payload.pageNumber
      }
      const url = `${BFF_ROOT}${BFF_V1}${BFF_STORES}/${orderCallData.fulfillmentStoreID}${BFF_COMPLETED_RECALLS}?startDate=${startDate}&endDate=${currentDate}&page=${orderCallData.pageNumber}&size=10`
      const method = 'GET';
      const data = {};
      const config = configAuthValues;
      yield catchApiErrors(error, {url, method, data, config, saga: SAGA_GET_COMPLETED_RECALLS});
    } catch (_error) {
      yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_GET_COMPLETED_RECALLS, isLoading: false }));
}

export default function* watchGetCompletedRecalls() {
  yield takeEvery(getType(getCompletedRecalls), runGetCompletedRecallsSaga);
}
