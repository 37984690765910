import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import {
  getProximityStore,
  setPickerStore,
  storeDetectionError,
  getEmployeeMetadata,
} from '../actions/pickerStore.actions';
import { setLoading } from '../actions/loading.actions';
import {
  mapGetProximityOrdersPayloadToRequest,
  mapGetProximityOrdersResponseToPayload,
} from './getProximityStore.mappers';

import { SAGA_GET_PROXIMITY_STORE, ENTERPRISE_PROXIMITY_REQUEST, PROXIMITY_SERVICE_ERROR } from '../../constants';

import { GetProximityStoreRequest } from './types';
import { ConfigAuthValues , APIError} from '../../types';

import { callAPI } from '../../utils';

import { handleApiErrors } from './utils';

const callGetProximityStore = (data: GetProximityStoreRequest, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'POST',
    url: `${configAuthValues.API_ROOT}${ENTERPRISE_PROXIMITY_REQUEST}`,
    data,
  });
};

export function* runGetProximityStoreSaga(action: ReturnType<typeof getProximityStore>) {
  yield put(setLoading({ sagaName: SAGA_GET_PROXIMITY_STORE, isLoading: true }));
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  try {
    const result = yield call(
      callGetProximityStore,
      mapGetProximityOrdersPayloadToRequest(action.payload),
      configAuthValues
    );
    // TODO: Validate API response
    if (result.data.stores.length > 0) {
      yield put(setPickerStore(mapGetProximityOrdersResponseToPayload(result.data)));
      if (action.payload.employeeID) {
        yield put(getEmployeeMetadata({ employeeID: action.payload.employeeID, isStoreAssigned: true }));
      }
    } else if (action.payload.employeeID) {
      yield put(getEmployeeMetadata({ employeeID: action.payload.employeeID }));
    } else {
      yield put(storeDetectionError());
    }
    yield handleApiErrors(result.data.errors);
  } catch (error) {

    const orderError: APIError[] = [{id: '',
    code: PROXIMITY_SERVICE_ERROR,
    detail: JSON.stringify(error)}]

    yield handleApiErrors(orderError);

    // try {
    //   const data = mapGetProximityOrdersPayloadToRequest(action.payload)
    //   const url = `${ENTERPRISE_PROXIMITY_REQUEST}`
    //   const method = 'POST'
    //   const config = configAuthValues;
    //   yield catchApiErrors(error, {url, method, data, config, saga: SAGA_GET_PROXIMITY_STORE});
    // } catch (_error) {
    //   yield catchApiErrors(error);
    // }
    yield put(storeDetectionError());
  }

  yield put(setLoading({ sagaName: SAGA_GET_PROXIMITY_STORE, isLoading: false }));
}

export default function* watchProximityStore() {
  yield takeEvery(getType(getProximityStore), runGetProximityStoreSaga);
}
