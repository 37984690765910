import { getType } from 'typesafe-actions';

import { RootAction } from '../actions/types';
import { setEmployeeData} from "../actions/employeeData.actions";
import {EmployeeDataState} from "./types";

const initialState: EmployeeDataState = {
  ignitionID: null,
  name: null
};

export default (state: EmployeeDataState = initialState, action: RootAction): EmployeeDataState => {
  switch (action.type) {
    case getType(setEmployeeData): {
      return {
        ignitionID: action.payload.ignitionID,
        name: action.payload.name,
      };
    }
    default: {
      return state;
    }
  }
};

