import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { getRecallOrders, setOrders } from '../actions/orders.actions';
import { setLoading } from '../actions/loading.actions';
import { mapGetOrdersResponseToSetOrdersPayload } from './getOrders.mappers';

import {
  SAGA_GET_RECALL_ORDERS,
  BFF_ROOT,
  BFF_V1,
  BFF_ORDERS,
  BFF_STORES,
  BFF_RECALL_ORDERS_QUERY_PARAM,
  VERIFIED_RECALLS,
  RECALL_ERROR
} from '../../constants';

import { GetRecallOrdersPayload } from '../actions/orders.types';
import { OrdersState } from '../reducers/types';
import { APIError,ConfigAuthValues, RecallOrderLocalStorage } from '../../types';

import { callAPI, isToday, stripLeadingZeroes } from '../../utils';

import { handleApiErrors } from './utils';
import { setRecallData } from '../../recallStorageUtils';

const callGetRecallOrders = (orderCallData: GetRecallOrdersPayload, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'GET',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_STORES}/${orderCallData.fulfillmentStoreID}${BFF_ORDERS}${BFF_RECALL_ORDERS_QUERY_PARAM}`,
  });
};

export function* runGetRecallOrdersSaga(action: { type: string; payload: GetRecallOrdersPayload }) {
  yield put(setLoading({ sagaName: SAGA_GET_RECALL_ORDERS, isLoading: true }));

  const configAuthValues = yield select((state: RootState) => state.config.authValues);
  const ordersState: OrdersState = yield select((state: RootState) => state.orders);

  const localStorageData = localStorage.getItem('storeRecallData');
  const storeNumber = stripLeadingZeroes(action.payload.fulfillmentStoreID);

  try {
    const localStorageRecallData: RecallOrderLocalStorage = localStorageData && JSON.parse(localStorageData);
    const currentStoreRecallData = localStorageRecallData && localStorageRecallData[storeNumber];
    const timestampOfCurrentStoreOrders = currentStoreRecallData && currentStoreRecallData.receivedDate;
    if (currentStoreRecallData && timestampOfCurrentStoreOrders && isToday(timestampOfCurrentStoreOrders)) {
      const recallOrdersFromStorage = currentStoreRecallData.recallOrders;
      // Combine recall orders with orders already in redux
      yield put(
        setOrders({
          orders: [...ordersState.orders, ...recallOrdersFromStorage],
          pickTours: ordersState.pickTours,
        })
      );
    } else {
      let result;
      let cachedRecalls = localStorage.getItem(VERIFIED_RECALLS)
      if (cachedRecalls) {
        cachedRecalls = JSON.parse(cachedRecalls);
        result = { data: cachedRecalls }
        localStorage.removeItem(VERIFIED_RECALLS)
      }
      if (!cachedRecalls) {
        result = yield call(
          callGetRecallOrders,
          {
            fulfillmentStoreID: action.payload.fulfillmentStoreID,
          },
          configAuthValues
        );
      }

      yield put(
        setOrders({
          orders: [...ordersState.orders, ...mapGetOrdersResponseToSetOrdersPayload(result.data).orders],
          pickTours: ordersState.pickTours,
        })
      );

      yield setRecallData(
        action.payload.fulfillmentStoreID,
        mapGetOrdersResponseToSetOrdersPayload(result.data).orders
      );

      if (action.payload.setOrders) {
        const storeRecallData = JSON.parse(localStorage.getItem("storeRecallData") || "{}")
        if (storeRecallData[storeNumber]) {
          const orders = ordersState.orders.filter(order => order.type !== "RECALL")
          yield put(
            setOrders({
              orders: [...orders, ...storeRecallData[storeNumber].recallOrders],
              pickTours: ordersState.pickTours,
            })
          );
        }
      }


      if(result.data.errors.length > 0) {

        const recallerror: APIError[] = [{id: '',
        code: RECALL_ERROR,
        detail: JSON.stringify(result.data.error)}]

      yield handleApiErrors(recallerror);

      } else {

      yield handleApiErrors(result.data.errors);

      }
    }
  } catch (error) {


    const recallerror: APIError[] = [{id: '',
      code: RECALL_ERROR,
      detail: JSON.stringify(error)}]
    yield handleApiErrors(recallerror);

    try {
      // const orderCallData = {
      //   fulfillmentStoreID: action.payload.fulfillmentStoreID,
      // };
      // const url = `${BFF_ROOT}${BFF_V1}${BFF_STORES}/${orderCallData.fulfillmentStoreID}${BFF_ORDERS}${BFF_RECALL_ORDERS_QUERY_PARAM}`
      // const method = 'GET';
      // const data = {};
      // const config = configAuthValues;
      // yield catchApiErrors(error, {url, method, data, config, saga: SAGA_GET_RECALL_ORDERS});
    } catch (_error) {
      // yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_GET_RECALL_ORDERS, isLoading: false }));
}

export default function* watchGetRecallOrders() {
  yield takeEvery(getType(getRecallOrders), runGetRecallOrdersSaga);
}
