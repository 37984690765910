import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ConfigAuthValues } from '../types'
import { BFF_ORDERS, BFF_ROOT, BFF_STORES, BFF_V1, API_FETCH_ERROR, API_FETCH_FULLFILLED, API_FETCH_PENDING, API_FETCH_READY, VERIFIED_ORDERS, BFF_PICK_TOURS, BFF_USERS } from '../constants'
import { APIPickTour } from '../redux/sagas/types'
import { createToast } from '../redux/actions/toasts.actions'
import useFetch from './useFetch'

/* 
this custom hook works as a middleware to validate if picktour is already in progress by another user
and if so, display who´s working on in
*/
export default function () {
    const [status, setStatus] = useState({ pickTourID: "", status: API_FETCH_READY })
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const { get } = useFetch();

    async function getUser(configAuthValues: ConfigAuthValues, pickTourID: string){
        let user;
        const idResult = await get(`${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${pickTourID}`)
        if (idResult.json){
            user = idResult.json.pickTour?.assignedPickerEmployeeID
        }
        if (user){
            const userResult = await get(`${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_USERS}/${user}`)
            if (userResult.json) user = userResult.json.preferredName || user
        }
        return user
    }

    async function verify(storeID: string | null, configAuthValues: ConfigAuthValues | null, pickTourID: string, callback = () => { }, onFail = () => { }) {
        setStatus({ pickTourID, status: API_FETCH_PENDING })
        if (storeID && configAuthValues) {
            const pickToursResult = await get(`${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_STORES}/${storeID}${BFF_ORDERS}`);
            if (pickToursResult.json) {
                const pickTours = pickToursResult.json.pickTours.map((pickTour: APIPickTour) => pickTour.pickTourID)
                const match = pickTours.indexOf(pickTourID) !== -1
                if (match) callback()
                if (!match) {
                    localStorage.setItem(VERIFIED_ORDERS, JSON.stringify(pickToursResult.json))
                    let user = await getUser(configAuthValues, pickTourID);
                    user = user ? `${t('UTILS.USER')} ${user}` : t('UTILS.OTHER_USER')
                    onFail()
                    dispatch(createToast({ type: 'error', message: t('ERRORS.PICKTOUR_IN_PROGRESS').replace("_user_", user) }))
                }
                setStatus({ pickTourID: "", status: API_FETCH_FULLFILLED })
            }
            if (pickToursResult.error) {
                setStatus({ pickTourID: "", status: API_FETCH_ERROR })
                onFail()
                dispatch(createToast({ type: 'error', message: t('ERRORS.DEFAULT_ERROR_MESSAGE') }))
            }
        }
        else {
            setStatus({ pickTourID: "", status: API_FETCH_ERROR })
            onFail()
            dispatch(createToast({ type: 'error', message: t('ERRORS.DEFAULT_ERROR_MESSAGE') }))
        }
    }
    return { status, verify }
}
