import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ConfigAuthValues, Order } from '../types'
import { GetOrdersResponseItem } from '../redux/sagas/types'
import { BFF_ORDERS, BFF_ROOT, BFF_STORES, BFF_V1, API_FETCH_ERROR, API_FETCH_FULLFILLED, API_FETCH_PENDING, API_FETCH_READY, ORDER_TYPE_RECALL, STORE_RECALL_DATA, VERIFIED_ORDERS, VERIFIED_RECALLS, BFF_RECALL_ORDERS_QUERY_PARAM, ORDER_ID_OVERSTOCK_ORDER_GENERATE_PCKTOUR, ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR } from '../constants'
import { createToast } from '../redux/actions/toasts.actions'
import { getOrders, getRecallOrders } from '../redux/actions/orders.actions'
import { callAPI } from '../utils'

type Status = {
    ids: Array<string>,
    status: string
};

// This custom hook works as a middleware to validate if picktour is already created by another user
export default function () {
    const [status, setStatus] = useState<Status>({ ids: [], status: API_FETCH_READY })
    const dispatch = useDispatch()
    const { t } = useTranslation()

    function deleteStore(storeID: string) {
        const stores = JSON.parse(localStorage.getItem(STORE_RECALL_DATA) || "{}")
        delete stores[+storeID]
        localStorage.setItem(STORE_RECALL_DATA, JSON.stringify(stores))
    }

    // TODO: update to implement useFetch
    async function verify(storeID: string | null, configAuthValues: ConfigAuthValues | null, selectedOrders: Array<Order>, callback = () => { }, onFail = () => { }) {
        const filteredOrders = selectedOrders.filter(order => order.id !== ORDER_ID_OVERSTOCK_ORDER_GENERATE_PCKTOUR && order.id !== ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR)
        if (filteredOrders.length === 0) callback();
        if (filteredOrders.length !== 0) {
            const ids = selectedOrders.map(order => order.id);
            let orderTypes = selectedOrders.map(order => order.type)
            orderTypes = orderTypes.filter((orderType, index: number) => {
                return orderType.indexOf(orderType) === index;
            });
            setStatus({ ids, status: API_FETCH_PENDING })
            const loginData = sessionStorage.getItem('loginData') || "{}"
            const token = JSON.parse(loginData).access_token
            if (storeID && token && configAuthValues) {
                try {
                    const isRecall = orderTypes.indexOf(ORDER_TYPE_RECALL) !== -1
                    const url = `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_STORES}/${storeID}${BFF_ORDERS}${isRecall ? BFF_RECALL_ORDERS_QUERY_PARAM : ""}`
                    const result = await callAPI({
                      method: 'GET',
                      url,
                    });
                    const json = result.data
                    const { orders } = json;
                    const results: any = selectedOrders.map(selectedOrder => {
                        return orders.filter((order: GetOrdersResponseItem) =>
                            order.deliveryMethod === selectedOrder.deliveryMethod &&
                            order.destination === selectedOrder.destination &&
                            order.finalizationErrorCode === selectedOrder.finalizationErrorCode &&
                            order.finalizationErrorMessage === selectedOrder.finalizationErrorMessage &&
                            order.isRetryEnabled === selectedOrder.isRetryEnabled &&
                            order.isUpcScanRequired === selectedOrder.isUpcScanRequired &&
                            order.orderID === selectedOrder.id &&
                            order.orderSequenceNumber === selectedOrder.sequenceNumber &&
                            order.orderType === selectedOrder.type &&
                            order.quantity === selectedOrder.quantity &&
                            order.recallDescription === selectedOrder.recallDescription &&
                            order.recallType === selectedOrder.recallType &&
                            order.supplierID === selectedOrder.supplierID
                        );
                    })
                    const match = results.flat().length === selectedOrders.length
                    setStatus({ ids: [], status: API_FETCH_FULLFILLED })
                    if (match) callback()
                    if (!match) {
                        if (isRecall) {
                            localStorage.setItem(VERIFIED_RECALLS, JSON.stringify(json))
                            deleteStore(storeID)
                            dispatch(getRecallOrders({ fulfillmentStoreID: storeID, setOrders: true }));
                        }
                        if (!isRecall) {
                            localStorage.setItem(VERIFIED_ORDERS, JSON.stringify(json))
                            dispatch(getOrders({ fulfillmentStoreID: storeID, refreshOrders: true }));
                        }
                        onFail()
                        dispatch(createToast({ type: 'error', message: t('ERRORS.PICKTOUR_ALREADY_CREATED') }))
                    }
                } catch (error) {
                    setStatus({ ids: [], status: API_FETCH_ERROR })
                    onFail()
                    dispatch(createToast({ type: 'error', message: t('ERRORS.DEFAULT_ERROR_MESSAGE') }))
                }
            }
            else {
                setStatus({ ids: [], status: API_FETCH_ERROR })
                onFail()
                dispatch(createToast({ type: 'error', message: t('ERRORS.DEFAULT_ERROR_MESSAGE') }))
            }
        }
    }
    return { status, verify }
}
