import { useState, useRef, useEffect } from 'react';

// This custom hook is used to provide window sizes dynamically as window size changes
export default function () {

    const windowEvent = useRef<any>();

    const [size, setSize] = useState({innerWidth:window.innerWidth, innerHeight:window.innerHeight});

    useEffect(()=>{
        windowEvent.current = window.addEventListener("resize", ()=>{
            setSize({
                innerWidth: window.innerWidth,
                innerHeight: window.innerHeight
            })
        })
        return () => {
            window.removeEventListener("resize", windowEvent.current);
        }
    },[])

    return {...size}
}