import React, { ReactElement } from 'react';

import styles from './icons.css';

type Props = {
  disabled?: boolean;
};

const PageBackwardIcon = ({ disabled }: Props): ReactElement => (
  <svg className={styles.svg} width="12px" height="12px" viewBox="0 0 12 12">
    <title>F20AD9E5-1C1A-42DF-BE65-E847FC8F51C6</title>
    <g id="Updates---September-2020" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="UP---Pagination---Next-Disabled-State"
        transform="translate(-20.000000, -588.000000)"
        fill={disabled ? '#A9AAA8' : '#F26100'}
      >
        <g id="Pagination/mobile/default-state" transform="translate(0.000000, 569.000000)">
          <g id="Mobile/Next-Disable-State" transform="translate(20.000000, 11.000000)">
            <g id="_Elements/Mobile/Active/Previous" transform="translate(0.000000, 4.000000)">
              <g id="Controls/arrows/az-arrow-left" transform="translate(0.000000, 4.000000)">
                <polygon
                  id="↳-🎨Icon-Color"
                  points="5.39012151 7.95715601 4.60697631 8.83333333 2 5.91666667 4.60697631 3 5.39012151 3.87617732 4.12014134 5.29702217 11.0909091 5.29702217 11.0909091 6.53631117 4.12014134 6.53631117"
                 />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PageBackwardIcon;
