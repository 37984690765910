import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import i18n from '../../i18n';
import { RootState } from '../reducers/rootReducer';
import { printRecallReport } from '../actions/activePickTour.actions';
import { createToast } from '../actions/toasts.actions';
import { setLoading } from '../actions/loading.actions';

import { BFF_PICK_TOURS, BFF_ROOT, BFF_V1, PRINT_RECALL_REPORT, SAGA_PRINT_RECALL_REPORT, PRINT_SERVICE_ERROR } from '../../constants';

import { ConfigAuthValues, APIError } from '../../types';

import { callAPI } from '../../utils';

import {  handleApiErrors } from './utils';
import { PrintRecallReportResponse } from './types';

const callPrintLocationReport = (pickTourId: string, labelQty: number, configAuthValues: ConfigAuthValues) =>
  callAPI({
    method: 'POST',
    url: `${configAuthValues?.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${pickTourId}${PRINT_RECALL_REPORT}`,
    data: { labelQty },
  });

export function* runPrintRecallReportSaga(action: ReturnType<typeof printRecallReport>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  yield put(setLoading({ sagaName: SAGA_PRINT_RECALL_REPORT, isLoading: true }));

  try {
    const { data }: { data: PrintRecallReportResponse } = yield call(
      callPrintLocationReport,
      action.payload.pickTourId,
      action.payload.labelQty,
      configAuthValues
    );
    const { errors } = data;
    if (errors.length === 0) {
      yield put(createToast({ type: 'information', message: i18n.t('PRINT_REQUESTED') }));
    } else {

      const printerror: APIError[] = [{id: '',
      code: PRINT_SERVICE_ERROR,
      detail: JSON.stringify(errors)}]

    yield handleApiErrors(printerror);

      
    }
  } catch (error) {
    const printerror: APIError[] = [{id: '',
    code: PRINT_SERVICE_ERROR,
    detail: JSON.stringify(error)}]
    
  yield handleApiErrors(printerror);
  
    try {
      // const {pickTourId} = action.payload;
      // const {labelQty} = action.payload;
      // const data = {labelQty}
      // const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${pickTourId}${PRINT_RECALL_REPORT}`
      // const method = 'POST';
      // const config = configAuthValues;
      // yield catchApiErrors(error, {url, method, data, config, saga: SAGA_PRINT_RECALL_REPORT});
    } catch (_error) {
      // yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_PRINT_RECALL_REPORT, isLoading: false }));
}

export default function* watchPrintRecallReport() {
  yield takeEvery(getType(printRecallReport), runPrintRecallReportSaga);
}
