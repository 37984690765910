import React, { ReactElement } from 'react';

import SkipIcon from './SkipIcon';

import styles from './icons.css';

type Props = {
  small?: boolean;
};

const SkippedItemIcon = ({ small }: Props): ReactElement => <SkipIcon className={styles.skipItem} small={small} />;

export default SkippedItemIcon;
