import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { updatePickTour } from '../actions/activePickTour.actions';
import { setLoading } from '../actions/loading.actions';
import { setPickTourPage } from '../actions/pickTourPage.actions';

import { mapPickTourDataToUpdatePickTourRequest } from './updatePickTour.mappers';
import { callAPI } from '../../utils';
import { handleApiErrors, catchApiErrors } from './utils';

import {
  SAGA_UPDATE_PICK_TOUR,
  BFF_ROOT,
  BFF_V1,
  BFF_PICK_TOURS,
  PICK_TOUR_PAGE_COMPLETE,
  PICK_TOUR_PAGE_EXIT_REVIEW,
  PICK_TOUR_PAGE_PICK_TOUR,
  APP_PAGE_ORDER_LIST,
  PICK_TOUR_SUB_STATUS_PROGRESS
} from '../../constants';

import { ConfigAuthValues } from '../../types';
import { UpdatePickTourRequest } from './types';
import { ActivePickTourState } from '../reducers/types';
import { setAppPage } from '../actions/appPage.actions';

import { ActionTypes as MessagesActionTypes, Messages } from "../reducers/message.reducer";

const callUpdatePickTour = (data: UpdatePickTourRequest, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'PUT',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${data.pickTourID}`,
    data,
  });
};

export function* runUpdatePickTourSaga(action: ReturnType<typeof updatePickTour>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  const activePickTourFromState: ActivePickTourState = yield select((state: RootState) => state.activePickTour);
  if (activePickTourFromState) {
    yield put(setLoading({ sagaName: SAGA_UPDATE_PICK_TOUR, isLoading: true }));

    const { pickTourType } = mapPickTourDataToUpdatePickTourRequest(action.payload, activePickTourFromState.data)

    try {
      const result = yield call(
        callUpdatePickTour,
        mapPickTourDataToUpdatePickTourRequest(action.payload, activePickTourFromState.data),
        configAuthValues
      );
      if (action.payload.updateType === 'finish') {
        if (pickTourType === "RECALL") yield put({ type: MessagesActionTypes.SEND_MESSAGE, payload: Messages.PRINT_RECALL_REPORT })
        if (pickTourType === "OVERSTOCK") yield put({ type: MessagesActionTypes.SEND_MESSAGE, payload: Messages.PRINT_DISCREPANCY_REPORT })
        yield put(setPickTourPage({ name: PICK_TOUR_PAGE_COMPLETE }));
      }
      if (action.payload.updateType === 'inactive') {
        yield put(setAppPage({ name: APP_PAGE_ORDER_LIST }));
        yield put(setPickTourPage({ name: PICK_TOUR_PAGE_PICK_TOUR }));
      }
      if (action.payload.updateType === 'exit') {
        yield put(setPickTourPage({ name: PICK_TOUR_PAGE_EXIT_REVIEW }));
      }
      yield handleApiErrors(result?.data?.errors);
    } catch (error) {
      if (action.payload.updateStatus.pickTourSubStatus === PICK_TOUR_SUB_STATUS_PROGRESS) {
        yield put(setAppPage({ name: APP_PAGE_ORDER_LIST }));
      }
      // 404 with ORDER CLOSED detail is not considered an error, should proceed as successful
      if (error?.response?.data?.errors && [404, 400].includes(error?.response?.status)) {
        try {
          if (error?.response?.data?.errors?.some((e: { detail: string; }) => ["ORDER_CLOSED", "PICKTOUR_CLOSED"].includes(e.detail)) && action.payload.updateType === "finish") {
            if (pickTourType === "RECALL") yield put({ type: MessagesActionTypes.SEND_MESSAGE, payload: Messages.PRINT_RECALL_REPORT })
            if (pickTourType === "OVERSTOCK") yield put({ type: MessagesActionTypes.SEND_MESSAGE, payload: Messages.PRINT_DISCREPANCY_REPORT })
            yield put(setPickTourPage({ name: PICK_TOUR_PAGE_COMPLETE }));
          }
          else {
            try {
              yield catchApiErrors(error);
            } catch (_error) {
              yield catchApiErrors(error);
            }
          }
          // eslint-disable-next-line no-empty
        } catch (_error) { }
      } else {
        try {
          const data = mapPickTourDataToUpdatePickTourRequest(action.payload, activePickTourFromState.data)
          const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${data.pickTourID}`
          const method = 'PUT';
          const config = configAuthValues;
          yield catchApiErrors(error, {url, method, data, config, saga: SAGA_UPDATE_PICK_TOUR});
        } catch (_error) {
          yield catchApiErrors(error);
        }
      }
    }

    yield put(setLoading({ sagaName: SAGA_UPDATE_PICK_TOUR, isLoading: false }));
  }
}

export default function* watchUpdatePickTour() {
  yield takeEvery(getType(updatePickTour), runUpdatePickTourSaga);
}
