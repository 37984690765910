import { getType } from 'typesafe-actions';

import { setAppPage } from '../actions/appPage.actions';

import { AppPageState } from './types';
import { RootAction } from '../actions/types';

import { APP_PAGE_ORDER_LIST } from '../../constants';

const initialState: AppPageState = {
  name: APP_PAGE_ORDER_LIST,
};

export default (state: AppPageState = initialState, action: RootAction): AppPageState => {
  switch (action.type) {
    case getType(setAppPage): {
      return {
        name: action.payload.name,
      };
    }
    default: {
      return state;
    }
  }
};
