import React, { ReactElement, useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';

import { useTranslation } from 'react-i18next';
import Header from '../../layout/Header';
import Waffle from '../../buttons/Waffle';
import Logo from '../../layout/Logo/Logo';
import Main from '../../layout/Main';
import StoreLocation from '../StoreLocation';
import OrderItem from './OrderItem';
import CompletedRecallItem from './CompletedRecallItem';
import SlideUpFooter from '../../layout/SlideUpFooter';
import Footer from '../../layout/Footer';
import Button from '../../buttons/Button';
import IconButton from '../../buttons/IconButton';
import ArrowBackIcon from '../../../assets/icons/ArrowBackIcon';
import StoreVerification from '../StoreVerification';
import FinalizationErrorItem from './FinalizationErrorItem';
import PickTourItem from './PickTourItem';
import PaginationBar from '../../layout/PaginationBar'

import { createPickTour, getPickTourItems, printRecallReport } from '../../../redux/actions/activePickTour.actions';
import { getExternalConfigValues } from '../../../redux/actions/config.actions';
import { getOrders, retryOrder, setHasManualOverstockOrder , getCompletedRecalls, clearSelected as clearSelectedAction, setOrders } from '../../../redux/actions/orders.actions';

import useVerifyPickTour from '../../../hooks/useVerifyPickTour';
import useVerifyOrderItem from '../../../hooks/useVerifyOrderItem';
import useWindowSize from '../../../hooks/useWindowSize';

import {
  getOrdersFromState,
  determineIsLoadingFromState,
  selectIsGetOrdersLoading,
  selectPickerStore,
  selectRefreshOrdersShortInterval,
  selectRefreshOrdersLongInterval,
  selectIsOverstockEnabled,
  selectCurrentLanguage,
  getConfigAuthValuesFromState,
  getEmployee
} from '../../../redux/selectors';
import {
  mergeClassNames,
  padTo2LeadingZeroes,
  padTo3LeadingZeroes,
  setInputIfDigits,
} from '../../../utils';

import {
  MQ_MAX_WIDTH,
  MQ_MIN_WIDTH,
  ORDER_ID_OVERSTOCK_ORDER_GENERATE_PCKTOUR,
  ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR,
  ORDER_TYPE_OVERSTOCK,
  ORDER_TYPE_RECALL,
  API_FETCH_PENDING,
  SHOULD_UPDATE_PICK_TOUR_SUB_STATUS,
  FALSE,
  TRUE,
} from '../../../constants';

import { Order, CompletedRecallTour, NavMenuTab } from '../../../types';
import { APIPickTourListItem, APIPickTour } from '../../../redux/sagas/types';

import styles from './OrderList.css';
import ConfirmationScreen from '../../layout/ConfirmationScreen';
import ManualEntryScreen from '../../layout/ManualEntryScreen';

import { createToast } from '../../../redux/actions/toasts.actions';
import RefreshButton from '../../buttons/RefreshButton';
import XIcon from '../../../assets/icons/XIcon';
import trimUserName from '../../../utils/trimUserName';




const determineIsOrderSelected = (params: { order: Order; selectedOrders: Order[] }) =>
  params.selectedOrders.some(selectedOrder => selectedOrder.id === params.order.id);

const OrderList = (): ReactElement => {
  const [activeNavTab, setActiveNavTab] = useState<NavMenuTab>(null);
  const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
  const [storeInputValue, setStoreInputValue] = useState('');
  const [firstStoreID, setFirstStoreID] = useState('');
  const [needsAcknowledgement, setNeedsAcknowledgement] = useState(false);
  const [shouldShowPrintMenu, setShouldShowPrintMenu] = useState(false);
  const [containerInputValue, setContainerInputValue] = useState('');

  const refreshOrdersShortInterval = useSelector(selectRefreshOrdersShortInterval);
  const refreshOrdersLongInterval = useSelector(selectRefreshOrdersLongInterval);
  const isOverstockEnabled = useSelector(selectIsOverstockEnabled);
  const isLoading = useSelector(determineIsLoadingFromState);
  const isGetOrdersLoading = useSelector(selectIsGetOrdersLoading);
  const ordersState = useSelector(getOrdersFromState);
  const pickerStore = useSelector(selectPickerStore);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const employee = useSelector(getEmployee);
  const configAuthValues = useSelector(getConfigAuthValuesFromState);
  const [selectedPickTour, setSelectedPickTour] = useState<APIPickTour | null>(null);
  const [selectedCompletedRecallTour, setSelectedCompletedRecallTour] = useState<CompletedRecallTour | null>(null);

  const verifyPickTour = useVerifyPickTour();
  const verifyOrderItem = useVerifyOrderItem();
  const { innerWidth } = useWindowSize();

  const { orders } = ordersState;
  const { pickTours } = ordersState;
  const { completedRecalls } = ordersState;
  const { hasManualOverstockOrder } = ordersState;

  const dispatch = useDispatch();

  const refreshOrdersShortTimer = useRef<number>();
  const refreshOrdersLongTimer = useRef<number>();

  const { t } = useTranslation();
  const translationRoot = 'ORDER_LIST';

  const {
    storeID: pickerStoreID,
    shouldPromptStoreSelection,
    shouldPromptStoreVerification,
    storeToVerify,
  } = pickerStore;

  const isAnyOrderSelected =
    selectedOrders.length > 0 || selectedPickTour !== null || selectedCompletedRecallTour !== null;

  const currentDate = new Date();

  const overstockPlaceholder: Order | undefined =
    hasManualOverstockOrder && pickerStoreID
      ? {
          id: ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR,
          type: ORDER_TYPE_OVERSTOCK,
          deliveryMethod: null,
          destination: pickerStoreID,
          quantity: null,
          finalizationErrorCode: 0,
          finalizationErrorMessage: null,
          isRetryEnabled: false,
          isUpcScanRequired: true,
          date: `${currentDate.getUTCFullYear()}-${padTo2LeadingZeroes(
            (currentDate.getUTCMonth() + 1).toString()
          )}-${padTo2LeadingZeroes(currentDate.getUTCDate().toString())}T${padTo2LeadingZeroes(
            currentDate.getUTCHours().toString()
          )}:${padTo2LeadingZeroes(currentDate.getUTCMinutes().toString())}:${padTo2LeadingZeroes(
            currentDate.getUTCSeconds().toString()
          )}.${padTo3LeadingZeroes(currentDate.getUTCMilliseconds().toString())}000Z`,
          sequenceNumber: null,
          recallDescription: null,
          recallType: null,
          supplierID: null,
        }
      : orders.find(
          order =>
            order.id === ORDER_ID_OVERSTOCK_ORDER_GENERATE_PCKTOUR ||
            order.id === ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR
        );

  const sortedOrders = overstockPlaceholder
    ? [
        overstockPlaceholder,
        ...orders.filter(
          order =>
            order.id !== ORDER_ID_OVERSTOCK_ORDER_GENERATE_PCKTOUR &&
            order.id !== ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR
        ),
      ]
    : orders;

  const filteredOrders =
    selectedOrders.length > 0
      ? sortedOrders.filter(order => {
          if (order.finalizationErrorCode === 0) {
            if (order.type === ORDER_TYPE_RECALL) {
              return order.id === selectedOrders[0].id;
            }
            return order.type === selectedOrders[0].type;
          }
          return false;
        })
      : sortedOrders.filter(order => {
          return order.finalizationErrorCode === 0;
        });

  const finalizationErrorOrders = sortedOrders.filter(order => order.finalizationErrorCode !== 0);

  const clearSelected = () => {
    setSelectedOrders([]);
    setSelectedPickTour(null);
    setSelectedCompletedRecallTour(null);
    setShouldShowPrintMenu(false)
  };

  const clearAndFetchOrders = useCallback(() => {
    if (pickerStoreID) {
      clearSelected();
      dispatch(getOrders({ fulfillmentStoreID: pickerStoreID, refreshOrders: true }));
    }
  }, [pickerStoreID, dispatch]);

  useEffect(() => {
    if (pickerStoreID) {
      clearSelected();
      dispatch(getExternalConfigValues({ storeID: pickerStoreID }));
    }
    dispatch(setHasManualOverstockOrder({ hasManualOverstockOrder: false }));
  }, [pickerStoreID, clearAndFetchOrders, dispatch]);

  useEffect(() => {
    if (!hasManualOverstockOrder) {
      setSelectedOrders(prevSelectedOrders =>
        prevSelectedOrders.filter(
          prevSelectedOrder => prevSelectedOrder.id !== ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR
        )
      );
    }
  }, [hasManualOverstockOrder]);

  useEffect(() => {
    if (!isGetOrdersLoading) {
      if (selectedOrders.length < 1 && selectedPickTour === null && activeNavTab!=='recallsHistory'  ) {
        if (refreshOrdersLongTimer.current) {
          window.clearTimeout(refreshOrdersShortTimer.current);
          refreshOrdersLongTimer.current = 0;
        }
        // Start the short timer
        refreshOrdersShortTimer.current = window.setTimeout(() => {
          clearAndFetchOrders();
        }, refreshOrdersShortInterval);
      } else {
        // Cancel the short timer
        if (refreshOrdersShortTimer.current) {
          window.clearTimeout(refreshOrdersShortTimer.current);
          refreshOrdersShortTimer.current = 0;
        }
        // Start the long timer
        refreshOrdersLongTimer.current = window.setTimeout(() => {
          clearAndFetchOrders();
        }, refreshOrdersLongInterval);
      }
    }

    return () => {
      if (refreshOrdersShortTimer.current) {
        window.clearTimeout(refreshOrdersShortTimer.current);
      }
      if (refreshOrdersLongTimer.current) {
        window.clearTimeout(refreshOrdersLongTimer.current);
      }
    };
  }, [refreshOrdersShortInterval, refreshOrdersLongInterval, isGetOrdersLoading, pickerStoreID, selectedOrders, dispatch, clearAndFetchOrders, selectedPickTour, activeNavTab]);

  // handle clear selected and remove overstock order after 503
  useEffect(()=>{
    if (ordersState.clearSelected === true){
      dispatch(clearSelectedAction(false));
      const selected = selectedOrders.map(order => order.id);
      if (selected.some(order => order === ORDER_ID_OVERSTOCK_ORDER_GENERATE_MANUAL_PCKTOUR)) dispatch(setHasManualOverstockOrder({ hasManualOverstockOrder: false }));
      const newOrdersState = {...ordersState};
      newOrdersState.orders = ordersState.orders.filter(order => selected.every(item => order.id !== item))
      dispatch(setOrders({orders: newOrdersState.orders, pickTours: newOrdersState.pickTours}));
      clearSelected();
    }
  },[dispatch, ordersState, ordersState.clearSelected, selectedOrders])

  const footerButtons: ReactElement = (
    <>
      <Button
        disabled={!isAnyOrderSelected}
        color="secondary"
        onClick={() => {
          clearSelected();
        }}
        data-cy="clear-button"
      >
        {t(`${translationRoot}.BUTTONS.CLEAR`)}
      </Button>
      {activeNavTab === 'recallsHistory' ? (
        <Button
          disabled={!isAnyOrderSelected}
          onClick={() => {
            setShouldShowPrintMenu(true);
          }}
          data-cy="create-tour-button"
        >
          {t(`${translationRoot}.BUTTONS.REPRINT`)}
        </Button>
      ) : (
        <Button
          disabled={
            !isAnyOrderSelected ||
            verifyOrderItem.status.status === API_FETCH_PENDING ||
            verifyPickTour.status.status === API_FETCH_PENDING
          }
          onClick={() => {
            if (selectedPickTour) {
              localStorage.setItem(SHOULD_UPDATE_PICK_TOUR_SUB_STATUS, TRUE);
              verifyPickTour.verify(
                pickerStore.storeID,
                configAuthValues,
                selectedPickTour ? selectedPickTour.pickTourID : '',
                () => {
                  localStorage.setItem(SHOULD_UPDATE_PICK_TOUR_SUB_STATUS, TRUE);
                  const updatedPickTour = { ...selectedPickTour };
                  const userData = JSON.parse(sessionStorage.getItem('userData') || '{}');
                  const { uid } = userData.claims || {uid: ""}
                  // eslint-disable-next-line no-param-reassign
                  updatedPickTour.assignedPickerEmployeeID = uid;
                  dispatch(
                    getPickTourItems({
                      isPickTourCreation: true,
                      page: 0,
                      pickTourData: updatedPickTour,
                      pickTourType: selectedPickTour.pickTourType,
                    })
                  );
                },
                () => {
                  clearAndFetchOrders();
                }
              );
            } else if (selectedOrders[0].type === ORDER_TYPE_RECALL && selectedOrders[0].recallType === 'EXCESS') {
              setNeedsAcknowledgement(true);
            } else {
              verifyOrderItem.verify(
                pickerStore.storeID,
                configAuthValues,
                selectedOrders,
                () => {
                  localStorage.setItem(SHOULD_UPDATE_PICK_TOUR_SUB_STATUS, FALSE);
                  dispatch(
                    createPickTour({
                      orders: selectedOrders.map(order => ({
                        id: order.id,
                        type: order.type,
                        isUpcScanRequired: order.isUpcScanRequired,
                        supplierID: pickerStoreID,
                      })),
                    })
                  );
                },
                () => {
                  clearSelected();
                }
              );
            }
          }}
          data-cy="create-tour-button"
        >
          {`${t(`${translationRoot}.BUTTONS.CREATE`)} (${
            selectedOrders.length > 0 && selectedOrders[0].type === 'OVERSTOCK'
              ? t(`${translationRoot}.LIST_ITEM.TBD`)
              : selectedOrders.reduce((acc: number, order: Order) => acc + (order.quantity || 0), 0) +
                (selectedPickTour
                  ? selectedPickTour.pickTourList.reduce(
                      (acc: number, order: APIPickTourListItem) => acc + order.orderItemQuantity,
                      0
                    )
                  : 0)
          })`}
        </Button>
      )}
    </>
  );

  const handleRetry = (order: Order) => {
    dispatch(retryOrder({ order }));
  };


  const recallsPageChange = (pageNumber : any) => {
    dispatch(getCompletedRecalls({ fulfillmentStoreID: pickerStore.storeID || '', daysAgo: 90, pageNumber: pageNumber-1 }));
  }


  const handleCloseButton = () => {
    if (selectedCompletedRecallTour) {
      setActiveNavTab('recallsHistory');
    }
    else {
      setActiveNavTab(null);
    }
    setSelectedCompletedRecallTour(null)
    setShouldShowPrintMenu(false);
  }

  return (
    <>
      <Header>
        <Waffle
          activeNavTab={activeNavTab}
          storeInputValue={storeInputValue}
          firstStoreID={firstStoreID}
          hasOverstockOrder={
            !!overstockPlaceholder ||
            pickTours.some(pickTour => pickTour.pickTourType === 'OVERSTOCK') ||
            hasManualOverstockOrder
          }
          setStoreInputValue={setStoreInputValue}
          setFirstStoreID={setFirstStoreID}
          setActiveNavTab={setActiveNavTab}
          createOverstockPlaceholder={() => {
            dispatch(setHasManualOverstockOrder({ hasManualOverstockOrder: true }));
            setActiveNavTab(null);
          }}
          isOverstockEnabled={isOverstockEnabled}
          clearSelected={() => {
            clearSelected();
          }}
        />
        <Logo />
        {activeNavTab === 'generateOverstock' ? (
          <h1 className={styles.pageHeader}>{t(`${translationRoot}.BUTTONS.GENERATE_ORDER_BUTTON`)}</h1>
        ) : (
          <>
            <h1 className={mergeClassNames(styles.pageHeader, currentLanguage === 'es-MX' && styles.pageHeaderSpanish)}>
              {t(`${translationRoot}.PICK`)}
            </h1>
            <span className={mergeClassNames(styles.divider, currentLanguage === 'es-MX' && styles.dividerSpanish)} />
            {/* TODO: Replace Store with store type if call is made that gets store type */}
            <div>
              <p>
                {pickerStoreID && !shouldPromptStoreVerification
                  ? `${t(`${translationRoot}.LIST_ITEM.STORE`)}#: ${String(Number(pickerStoreID)).padStart(5,"0")}`
                  : `${t(`${translationRoot}.DETECTING`)}`}
              </p>
              <p>{trimUserName(employee.name || "", innerWidth)}</p>
            </div>
          </>
        )}

        {activeNavTab !== 'recallsHistory' && activeNavTab!=='generateOverstock' && activeNavTab!=='storeLocation' &&  pickerStoreID && <RefreshButton/> }
        {activeNavTab === 'recallsHistory' && pickerStoreID && <IconButton icon={<XIcon />} onClick={ handleCloseButton}/>}
       
        
        {((!shouldPromptStoreSelection && activeNavTab === 'storeLocation') ||
          firstStoreID ||
          activeNavTab === 'generateOverstock') && (
          <IconButton
            icon={<ArrowBackIcon />}
            onClick={() => {
              if (activeNavTab === 'storeLocation') {
                if (firstStoreID) {
                  setStoreInputValue('');
                  setFirstStoreID('');
                } else {
                  setActiveNavTab(null);
                  setStoreInputValue('');
                }
              }
              if (activeNavTab === 'generateOverstock') {
                setActiveNavTab(null);
              }
            }}
            data-cy="nav-menu-close-button"
          />
        )}
      </Header>
      <Main>
        {// eslint-disable-next-line no-nested-ternary
        shouldPromptStoreVerification ? (
          <StoreVerification storeToVerify={storeToVerify} />
        ) : // eslint-disable-next-line no-nested-ternary
        shouldPromptStoreSelection ? (
          <StoreLocation
            storeInputValue={storeInputValue}
            firstStoreID={firstStoreID}
            setStoreInputValue={setStoreInputValue}
            setFirstStoreID={setFirstStoreID}
          />
        ) : // eslint-disable-next-line no-nested-ternary
        needsAcknowledgement ? (
          <ConfirmationScreen
            confirmationText={`${t(`${translationRoot}.RECALL_ACKNOWLEDGEMENT`)}`}
            confirmationButtonText={`${t(`${translationRoot}.RECALL_UNDERSTAND`)}`}
            handleConfirm={() =>
              dispatch(
                createPickTour({
                  orders: selectedOrders.map(order => ({
                    id: order.id,
                    type: order.type,
                    isUpcScanRequired: order.isUpcScanRequired,
                    supplierID: pickerStoreID,
                  })),
                })
              )
            }
            backButtonText={`${t(`${translationRoot}.RECALL_BACK`)}`}
            handleBack={() => setNeedsAcknowledgement(false)}
          />
        ) : shouldShowPrintMenu ? (
          <ManualEntryScreen
            buttonText={t(`ACTIVE_PICK_TOUR.DETAILS_VIEW.CONFIRM`)}
            disabled={containerInputValue === ''}
            inputValue={containerInputValue}
            type="number"
            handleInputChange={value => setInputIfDigits({ inputSetter: setContainerInputValue, value })}
            label={t(`ACTIVE_PICK_TOUR.DETAILS_VIEW.QTY_INPUT_MOBILE.PLACEHOLDER`)}
            memo={t(`ACTIVE_PICK_TOUR.DETAILS_VIEW.PLEASE_ENTER_CONTAINERS`)}
            placeholder={t(`ACTIVE_PICK_TOUR.DETAILS_VIEW.QTY_INPUT_MOBILE.PLACEHOLDER`)}
            handleSubmit={() => {
              if (selectedCompletedRecallTour && parseInt(containerInputValue, 10) > 0) {
                setShouldShowPrintMenu(false);
                dispatch(
                  printRecallReport({
                    pickTourId: selectedCompletedRecallTour.recallPickTourId,
                    labelQty: parseInt(containerInputValue, 10),
                  })
                );
              } else if (
                parseInt(containerInputValue, 10) === 0 &&
                selectedCompletedRecallTour &&
                parseInt(`${selectedCompletedRecallTour.totalQtyPicked}`, 10) === 0
              ) {
                setShouldShowPrintMenu(false);
              } else {
                dispatch(
                  createToast({
                    type: 'error',
                    message: t(`ACTIVE_PICK_TOUR.DETAILS_VIEW.LABEL_VALIDATION`),
                  })
                );
              }
            }}
          />
        ) : (
          !isLoading &&
          (filteredOrders.length ||
          finalizationErrorOrders.length ||
          pickTours.length ||
          (activeNavTab === 'recallsHistory' && completedRecalls && completedRecalls.content.length) ? (
            <>
              <ul className={isAnyOrderSelected ? styles.listSelected : styles.listNotSelected}>
                {finalizationErrorOrders.map(order => (
                  <FinalizationErrorItem
                    key={`${order.id}-${order.sequenceNumber}`}
                    order={order}
                    retryOrder={() => handleRetry(order)}
                  />
                ))}
                {activeNavTab !== 'recallsHistory' &&
                  !selectedOrders.length &&
                  pickTours.map(
                    pickTour =>
                      (!selectedPickTour ||
                        (selectedPickTour && selectedPickTour.pickTourID === pickTour.pickTourID)) && (
                        <PickTourItem
                          key={pickTour.pickTourID}
                          pickTour={pickTour}
                          isSelected={selectedPickTour !== null && selectedPickTour.pickTourID === pickTour.pickTourID}
                          verifying={
                            verifyPickTour.status.status === API_FETCH_PENDING &&
                            verifyPickTour.status.pickTourID === pickTour.pickTourID
                          }
                          onSelect={() => {
                            if (verifyPickTour.status.status !== API_FETCH_PENDING) {
                              if (selectedPickTour && selectedPickTour.pickTourID === pickTour.pickTourID) {
                                setSelectedPickTour(null);
                              } else {
                                setSelectedPickTour(pickTour);
                              }
                            }
                          }}
                          currentLanguage={currentLanguage}

                        />
                      )
                  )}
                {activeNavTab !== 'recallsHistory' &&
                  !selectedPickTour &&
                  filteredOrders.map((order: Order) => (
                    <OrderItem
                      key={`${order.id}-${order.sequenceNumber}}`}
                      order={order}
                      isSelected={determineIsOrderSelected({ order, selectedOrders })}
                      verifying={
                        verifyOrderItem.status.status === API_FETCH_PENDING &&
                        verifyOrderItem.status.ids.indexOf(order.id) !== -1
                      }
                      onSelect={() => {
                        setSelectedOrders(prevSelectedOrders =>
                          determineIsOrderSelected({ order, selectedOrders: prevSelectedOrders })
                            ? prevSelectedOrders.filter(selectedOrder => selectedOrder.id !== order.id)
                            : [...prevSelectedOrders, order]
                        );
                      }}
                      currentLanguage={currentLanguage}
                    />
                  ))}
                {activeNavTab === 'recallsHistory' &&
                  completedRecalls &&
                  completedRecalls.content
                    .sort((a: CompletedRecallTour, b: CompletedRecallTour) => {
                      const dateA = new Date(a.completedDate);
                      const dateB = new Date(b.completedDate);
                      if (dateA.getTime() === dateB.getTime()) {
                        return 0;
                      }
                      return dateA > dateB ? -1 : 1;
                    })
                    .map(
                      (completedRecall: CompletedRecallTour) => 
                        (!selectedCompletedRecallTour ||
                          (selectedCompletedRecallTour &&
                            selectedCompletedRecallTour.recallPickTourId === completedRecall.recallPickTourId)) && (
                              
                          <CompletedRecallItem
                            key={completedRecall.recallPickTourId}
                            isSelected={
                              selectedCompletedRecallTour !== null &&
                              selectedCompletedRecallTour.recallPickTourId === completedRecall.recallPickTourId
                            }
                            completedRecall={completedRecall}
                            onSelect={() => {
                              if (
                                selectedCompletedRecallTour &&
                                selectedCompletedRecallTour.recallPickTourId === completedRecall.recallPickTourId
                              ) {
                                setSelectedCompletedRecallTour(null);
                              } else {
                                setSelectedCompletedRecallTour(completedRecall);
                              }
                            }}
                          />

                          
                        )
                    )}

                    <li style={{marginTop: 'auto'}}>
                    {activeNavTab === 'recallsHistory' &&
                  completedRecalls  && selectedCompletedRecallTour == null && completedRecalls.content.length > 0 &&
                
                    <PaginationBar isDisabled={false} currentPage={completedRecalls.page.number + 1} totalPages={completedRecalls.page.totalPages} onPageChange ={recallsPageChange}/>
                  
              }
                    </li>


              </ul>

              {activeNavTab === 'recallsHistory' &&
                  completedRecalls && completedRecalls.content.length <=0  && selectedCompletedRecallTour == null &&
                  

                  <article className={styles.noOrdersContainer}>
              <p>
                {t(
                  `${translationRoot}.${'NO_RECALL_ORDERS'}`
                )}
              </p>
            </article>
              }

              
            </>
          ) : (
            <article className={styles.noOrdersContainer}>
              <p>
                {t(
                  `${translationRoot}.${
                    pickerStoreID === null
                      ? 'NO_STORE_ID'
                      : `${activeNavTab === 'recallsHistory' ? 'NO_RECALL_ORDERS' : 'NO_PENDING_ORDERS'}`
                  }`
                )}
              </p>
            </article>
          ))
        )}
      </Main>
      <MediaQuery maxWidth={MQ_MAX_WIDTH}>
        <SlideUpFooter
          className={styles.footer}
          isOpen={
            isAnyOrderSelected &&
            !shouldPromptStoreSelection &&
            !shouldPromptStoreVerification &&
            !needsAcknowledgement &&
            !shouldShowPrintMenu
          }
        >
          {footerButtons}
        </SlideUpFooter>
      </MediaQuery>
      <MediaQuery minWidth={MQ_MIN_WIDTH}>
        {!shouldPromptStoreSelection &&
        !shouldPromptStoreVerification &&
        !shouldShowPrintMenu &&
        !needsAcknowledgement ? (
          <Footer className={styles.footer}>{footerButtons}</Footer>
        ) : null}
      </MediaQuery>
    </>
  );
};

export default OrderList;
