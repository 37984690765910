import React, { ReactElement } from 'react';

import ErrorCircle from '../../../../assets/icons/ErrorCircle';
import RefreshIcon from '../../../../assets/icons/RefreshIcon';

import { Order } from '../../../../types';

import styles from './FinalizationErrorItem.css';

type Props = {
  order: Order;
  retryOrder: (order: Order) => void;
};

const FinalizationErrorItem = ({ order, retryOrder }: Props): ReactElement => {
  const { finalizationErrorMessage, destination, isRetryEnabled } = order;
  return (
    <li className={styles.li}>
      <ErrorCircle className={styles.errorCircle} />
      <div className={styles.content}>
        <p className={styles.destination}>{destination}</p>
        <p className={styles.message}>{finalizationErrorMessage}</p>
      </div>
      {isRetryEnabled ? (
        <span className={styles.buttonContainer} onClick={() => retryOrder(order)}>
          <RefreshIcon />
          <p className={styles.retry}>Retry</p>
        </span>
      ) : null}
    </li>
  );
};

export default FinalizationErrorItem;
