import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { mergeClassNames } from '../../../utils';

import styles from './TourQuantityBox.css';

type Props = {
  pickedQuantity: number;
  totalQuantity: number;
  hasJustScanned: boolean;
  currentLanguage: string;
};

const TourQuantityBox = ({ pickedQuantity, totalQuantity, hasJustScanned, currentLanguage }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.LIST_VIEW';
  return (
    <article
      className={mergeClassNames(
        styles.article,
        hasJustScanned && styles.green,
        currentLanguage === 'es-MX' && styles.articleSpanish
      )}
    >
      <p className={mergeClassNames(styles.p, currentLanguage === 'es-MX' && styles.pSpanish)}>
        <span>{t(`${translationRoot}.TOUR_QTY`)}</span>
        <span data-cy="pick-tour-quantity-status">{`${pickedQuantity}/${totalQuantity}`}</span>
      </p>
    </article>
  );
};
export default TourQuantityBox;
