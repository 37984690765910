import { GetProximityStoreRequest, GetProximityStoreResponse } from './types';
import { GetProximityStorePayload, SetPickerStorePayload } from '../actions/pickerStore.types';

export const mapGetProximityOrdersPayloadToRequest = (payload: GetProximityStorePayload): GetProximityStoreRequest => ({
  // The "lattitude" typo is in the API
  lattitude: payload.latitude,
  longitude: payload.longitude,
  maxMilesRange: 10,
  getStoresByAddress: false,
  address: '',
});

export const mapGetProximityOrdersResponseToPayload = (response: GetProximityStoreResponse): SetPickerStorePayload => ({
  storeID: response.stores.reduce((currentStore, store) => (store.miles < currentStore.miles ? store : currentStore))
    .storeId,
});
