import React, { ReactElement } from 'react';

import styles from './icons.css';

const CheckmarkSquareIcon = (): ReactElement => (
  <svg className={styles.svg} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="4" fill="#F26D0D" />
    <path
      d="M16.7869 7.29306L9.72694 14.3464L6.94802 11.5675L5.29175 13.2121L9.72677 17.6471L18.4339 8.94L16.7869 7.29306Z"
      fill="white"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="7" width="14" height="11">
      <path
        d="M16.7869 7.29306L9.72694 14.3464L6.94802 11.5675L5.29175 13.2121L9.72677 17.6471L18.4339 8.94L16.7869 7.29306Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)" />
  </svg>
);

export default CheckmarkSquareIcon;
