import { GetExternalConfigResponse } from './types';
import { SetRefreshOrdersIntervalPayload } from '../actions/config.types';

export const mapGetExternalConfigResponseToSetRefreshOrdersIntervalPayload = (
  response: GetExternalConfigResponse
): SetRefreshOrdersIntervalPayload => ({
  shortInterval: response.config['refresh-interval'],
  longInterval: response.config['refresh-interval-long'],
  satToSatEnabled: response.config['sat-to-sat-enabled'],
  smartSourcingEnabled: response.config['smart-sourcing-enabled'],
  osPickingLocationsEnabled: response.config['os-picking-locations-enabled'],
  dsPickingLocationsEnabled: response.config['ds-picking-locations-enabled'],
  overstockPickingEnabled: response.config['overstock-picking-enabled'],
  storeToStorePickingEnabled: response.config['store-to-store-picking-enabled'],
  recallPickingEnabled: response.config['recall-picking-enabled'],
});
