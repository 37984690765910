import React from 'react';

import styles from './CheckBoxSpinner.css';

export default function CheckBoxSpinner() {
  return (
    <svg className={styles.checkBoxSpinner} height="24" width="24">
      <circle cx="12" cy="12" r="9" strokeWidth="3" fill="none" strokeDasharray="40" />
    </svg>
  );
}
