import React, { ReactElement, ReactNode } from 'react';

import { mergeClassNames } from '../../../utils';

import Footer from '../Footer';

import styles from './SlideUpFooter.css';

type Props = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
};

const SlideUpFooter = ({ children, className, isOpen }: Props): ReactElement => (
  <Footer className={mergeClassNames(className, isOpen ? styles.open : styles.closed)}>{children}</Footer>
);

export default SlideUpFooter;
