import {
  UpdatePickTourItemRequest,
  APIPickTourListItem,
  APIPickTourListItemLocation,
  UpdatePickTourItemResponse,
} from './types';
import { UpdatePickTourItemPayload, SetActivePickPayload } from '../actions/activePickTour.types';
import { Pick, PickLocation, PreviousPickDetails } from '../../types';
import { ORDER_TYPE_RECALL } from '../../constants';

export const mapUpdatePickTourItemPayloadWithLocationsAndDataToUpdatePickTourItemRequest = (
  payload: UpdatePickTourItemPayload,
  pickLocations: PickLocation[],
  pickData: APIPickTourListItem
): UpdatePickTourItemRequest => {
  const updatePickedAmount =
    pickData.orderType === ORDER_TYPE_RECALL &&
    (payload.updatedQuantity || payload.updatedQuantity === 0) &&
    (pickData.keepQuantityOnHand || pickData.keepQuantityOnHand === 0) &&
    payload.pickedQuantity !== Math.max(payload.updatedQuantity - pickData.keepQuantityOnHand, 0)
      ? 0
      : payload.pickedQuantity;
  return [
    {
      ...pickData,
      location: pickLocations
        .map((pickedLocation, index) => {
          const dataLocation = pickData.location.find(
            location =>
              location.planoDescription === pickedLocation.planogramName &&
              location.itemSequenceNumber === pickedLocation.itemSequence &&
              location.planoID === pickedLocation.planoID
          );
          if (dataLocation) {
            if (index === pickLocations.length - 1) {
              return {
                ...dataLocation,
                updatedQuantity:
                  payload.updatedQuantity || payload.updatedQuantity === 0
                    ? payload.updatedQuantity
                    : pickedLocation.updatedQuantity,
                expectedQuantity: dataLocation.expectedQuantity === null ? null : pickedLocation.pickedQuantity,
                pickedQuantity: updatePickedAmount,
              };
            }
            return {
              ...dataLocation,
              updatedQuantity:
                payload.updatedQuantity || payload.updatedQuantity === 0 ? 0 : dataLocation.updatedQuantity,
              expectedQuantity: dataLocation.expectedQuantity === null ? null : pickedLocation.pickedQuantity,
              pickedQuantity: updatePickedAmount,
            };
          }
          return null;
        })
        .filter(location => location) as APIPickTourListItemLocation[],
      pickedItemQuantity: updatePickedAmount,
      skipReason: payload.skipReason,
      skippedFlag: payload.isSkipped,
    },
  ];
};

export const mapUpdatePickTourItemResponseToSetActivePickPayload = (
  response: UpdatePickTourItemResponse,
  previousPickDetails: PreviousPickDetails,
  activePick: Pick
): SetActivePickPayload => ({
  previousPickDetails,
  activePick,
  data: response.pickTourItems[0],
});
