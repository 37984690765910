import { getType } from 'typesafe-actions';
import { setCurrentLanguage } from '../actions/currentLanguage.actions';

import { RootAction } from '../actions/types';
import { CurrentLanguageState } from './types';

const initialState: CurrentLanguageState = 'en-US';

export default (state: CurrentLanguageState = initialState, action: RootAction): CurrentLanguageState => {
  switch (action.type) {
    case getType(setCurrentLanguage): {
      return action.payload;
    }
    default: {
      return state;
    }
  }
};
