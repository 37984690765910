import React, { ReactElement } from 'react';
import MediaQuery from 'react-responsive';

import AZLogo from '../../../assets/img/azlogo.png';

import { MQ_MIN_WIDTH } from '../../../constants';

import styles from './Logo.css';

const Logo = (): ReactElement => (
  <MediaQuery minWidth={MQ_MIN_WIDTH}>
    <img className={styles.img} src={AZLogo} alt="Autozone Logo" />
  </MediaQuery>
);

export default Logo;
