import React, { ReactElement } from 'react';

import styles from './icons.css';

type Props = {
  small?: boolean;
};

const RecallIcon = ({ small }: Props): ReactElement => (
  <svg
    className={small ? styles.svgBlueSmall : styles.svgBlue}
    viewBox="0 -4 24 24"
    preserveAspectRatio="none"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.33333 2.66667V4L10 2L7.33333 0V1.33333H0V14.6667H4L4 13.3333H1.33333V2.66667H7.33333Z" fill="#B7C3CE" />
    <path d="M4.80002 5.33333H6.40002V6.08H4.80002V5.33333Z" fill="#B7C3CE" />
    <path
      d="M8.66667 13.3333V12L6 14L8.66667 16V14.6667H16V1.33333H12V2.66667H14.6667V13.3333H8.66667Z"
      fill="#B7C3CE"
    />
    <path d="M9.59999 5.33333H11.2V6.08H9.59999V5.33333Z" fill="#B7C3CE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6 6.45334C11.8208 6.45334 12 6.62022 12 6.82667V10.56C12 10.7665 11.8208 10.9333 11.6 10.9333H4.4C4.1792 10.9333 4 10.7665 4 10.56V6.82667C4 6.62022 4.1792 6.45334 4.4 6.45334H11.6ZM7.19998 9.06666V8.32H5.19998V9.06666H7.19998ZM10.8 9.06667V8.32H10V7.57333H9.20001V8.32H8.40001V9.06667H9.20001V9.81333H10V9.06667H10.8Z"
      fill="#B7C3CE"
    />
  </svg>
);

export default RecallIcon;
