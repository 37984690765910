import React, { ReactNode, ReactElement } from 'react';

import { mergeClassNames } from '../../../utils';

import styles from './Button.css';

export type Props = {
  children: ReactNode;
  className?: string;
  color?: 'primary' | 'secondary';
  disabled?: boolean;
  name?: string;
  variant?: 'default' | 'round';
  onClick: () => void;
};

const Button = ({
  children,
  className,
  color = 'primary',
  disabled = false,
  variant = 'default',
  name,
  onClick,
  ...other
}: Props): ReactElement => (
  <button
    type="button"
    name={name}
    className={mergeClassNames(styles.root, styles[variant], styles[color], className)}
    disabled={disabled}
    onClick={onClick}
    {...other}
  >
    {children}
  </button>
);

export default Button;
