import React, { ReactElement } from 'react';

const ScanNotRequired = (): ReactElement => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.635254"
      y="14.2318"
      width="11.3304"
      height="1.69956"
      transform="rotate(-90 0.635254 14.2318)"
      fill="#DD3900"
    />
    <path d="M4.03442 3.37071V14.2318H5.16746V4.50375L4.03442 3.37071Z" fill="#DD3900" />
    <path
      d="M13.6652 13.0014V14.2317H14.8956L13.6652 13.0014ZM15.3648 11.4962V2.90132H13.6652V9.79663L15.3648 11.4962Z"
      fill="#DD3900"
    />
    <path
      d="M10.8326 10.1688V14.2317H11.9656V11.3019L10.8326 10.1688ZM11.9656 8.09715V2.90132H10.8326V6.96412L11.9656 8.09715Z"
      fill="#DD3900"
    />
    <path
      d="M8.56653 7.90272V14.2317H9.69957V9.03576L8.56653 7.90272ZM9.69957 5.83104V2.90132H8.56653V4.698L9.69957 5.83104Z"
      fill="#DD3900"
    />
    <path
      d="M6.30048 5.6366V14.2317H7.43351V6.76964L6.30048 5.6366ZM7.43351 3.56493V2.90132H6.76991L7.43351 3.56493Z"
      fill="#DD3900"
    />
    <path d="M0 1.20177L1.20177 -1.84378e-06L16 14.7982L14.7982 16L0 1.20177Z" fill="#DD3900" />
  </svg>
);

export default ScanNotRequired;
