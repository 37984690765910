import React, { ReactElement } from 'react';

const CheckmarkCircleIcon = (): ReactElement => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.056 29.3333C8.70399 29.3333 2.72266 23.352 2.72266 16C2.72266 8.64801 8.70399 2.66667 16.056 2.66667C23.408 2.66667 29.3893 8.64801 29.3893 16C29.3893 23.352 23.408 29.3333 16.056 29.3333ZM16.056 5.33334C10.1747 5.33334 5.38932 10.1187 5.38932 16C5.38932 21.8813 10.1747 26.6667 16.056 26.6667C21.9373 26.6667 26.7227 21.8813 26.7227 16C26.7227 10.1187 21.9373 5.33334 16.056 5.33334Z"
      fill="#1F7400"
    />
    <path
      d="M14.9346 22.5707L9.54398 17.18L11.9 14.8227L14.5093 17.4307L20.032 9.69867L22.7453 11.636L14.9346 22.5707Z"
      fill="#1F7400"
    />
  </svg>
);

export default CheckmarkCircleIcon;
