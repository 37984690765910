import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LAST_LOGIN, LAST_LOGOUT } from "../constants";
import { sendLog } from "../redux/actions/log.actions";
import { AppLogType } from "../types";
import { getUID } from "../utils";

/*
This custom hook was implemented in order to retrieve login and logout information from users when
logged out due to not valid token.
In order to reach whispers we need a valid token but if token is no longer valid and logout happens
user last session login and logout data will be saved in local storage and sent in a future login with
a valid token
*/
export default function (isAuthenticated:boolean){

    const dispatch = useDispatch();

    useEffect(()=>{

        const uid = getUID();

        if (isAuthenticated && uid){
            if (!localStorage.getItem(`${LAST_LOGIN}_${uid}`)){
                const now = new Date();
                const end = `${now.toDateString()} ${now.toTimeString()} ms:${now.getTime()}`;
                localStorage.setItem(`${LAST_LOGIN}_${uid}`, String(end));
            }
            if (localStorage.getItem(`${LAST_LOGIN}_${uid}`) && localStorage.getItem(`${LAST_LOGOUT}_${uid}`)){
                const lastSession = {
                    login: localStorage.getItem(`${LAST_LOGIN}_${uid}`),
                    logout: localStorage.getItem(`${LAST_LOGOUT}_${uid}`)
                  };
                dispatch(sendLog({ logEvent: AppLogType.SESSION_INFO, logMessage: "Recovered last session details", logDetails: lastSession }));
                const now = new Date();
                const end = `${now.toDateString()} ${now.toTimeString()} ms:${now.getTime()}`;
                localStorage.setItem(`${LAST_LOGIN}_${uid}`, String(end));
                localStorage.removeItem(`${LAST_LOGOUT}_${uid}`);
            }
        }
    },[dispatch, isAuthenticated])
}