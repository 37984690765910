import { UpdatePickTourPayload } from '../actions/activePickTour.types';
import { APIPickTour, UpdatePickTourRequest } from './types';

export const mapPickTourDataToUpdatePickTourRequest = (
  payload: UpdatePickTourPayload,
  pickTourData: APIPickTour
): UpdatePickTourRequest => ({
  ...pickTourData,
  pickTourList: [],
  pickTourStatus: payload.updateStatus.pickTourStatus,
  pickTourSubStatus: payload.updateStatus.pickTourSubStatus,
});
