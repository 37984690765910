import i18n from './i18n';
import { APIError } from './types';
import { COMMERCIAL_ORDER_SERVICE_DOWN_REPLENISHMENT_ORDER_SERVICE_DOWN, REPLENISHMENT_ORDER_SERVICE_DOWN_COMMERCIAL_ORDER_SERVICE_DOWN } from './constants';

const translationRoot = 'ERRORS';

const mapMultipleAPIError = (errors: APIError[]) => {

    let combinedErrors = errors.reduce((previousValue, currentValue) => `${previousValue }_${ currentValue.code}`, '')

    combinedErrors = combinedErrors.substring(1)

    switch(combinedErrors) {
        case COMMERCIAL_ORDER_SERVICE_DOWN_REPLENISHMENT_ORDER_SERVICE_DOWN: {
            return i18n.t(`${translationRoot}.API_ERRORS.COMMERCIAL_ORDER_SERVICE_DOWN_REPLENISHMENT_ORDER_SERVICE_DOWN`);
          }
        case REPLENISHMENT_ORDER_SERVICE_DOWN_COMMERCIAL_ORDER_SERVICE_DOWN: {
          return i18n.t(`${translationRoot}.API_ERRORS.COMMERCIAL_ORDER_SERVICE_DOWN_REPLENISHMENT_ORDER_SERVICE_DOWN`);
        }
          default: {
            return i18n.t(`${translationRoot}.DEFAULT_ERROR_MESSAGE`);
          }
    }
};

export default mapMultipleAPIError;