import { call, put, select, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import i18n from '../../i18n';
import { RootState } from '../reducers/rootReducer';
import { printPickList } from '../actions/activePickTour.actions';
import { createToast } from '../actions/toasts.actions';
import { setLoading } from '../actions/loading.actions';

import { BFF_PICK_TOURS, BFF_ROOT, BFF_V1, PRINT_PICK_TOUR, SAGA_PRINT_PICK_LIST } from '../../constants';

import { APIError, ConfigAuthValues, PickTour } from '../../types';

import { callAPI } from '../../utils';

import { catchApiErrors, handleApiErrors } from './utils';

const callPrintPickList = (activePickTour: PickTour, configAuthValues: ConfigAuthValues) =>
  callAPI({
    method: 'POST',
    url: `${configAuthValues?.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${activePickTour.id}${PRINT_PICK_TOUR}`,
    data: {},
  });

export function* runPrintPickListSaga(action: ReturnType<typeof printPickList>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  yield put(setLoading({ sagaName: SAGA_PRINT_PICK_LIST, isLoading: true }));

  try {
    const response = yield call(callPrintPickList, action.payload.activePickTour, configAuthValues);
    const { errors }: { errors: APIError[] } = response.data;
    if (errors.length === 0) {
      // TODO: Add message to new textConstant file that's not merged
      yield put(createToast({ type: 'success', message: i18n.t('PRINT_REQUEST_SUCCESS') }));
    } else {
      yield handleApiErrors(errors);
    }
  } catch (error) {
    try {
      const {activePickTour} = action.payload;
      const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${activePickTour.id}${PRINT_PICK_TOUR}`
      const method = 'POST';
      const data = {};
      const config = configAuthValues;
      yield catchApiErrors(error, {url, method, data, config, saga: SAGA_PRINT_PICK_LIST});
    } catch (_error) {
      yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_PRINT_PICK_LIST, isLoading: false }));
}

export default function* watchPrintPickList() {
  yield takeEvery(getType(printPickList), runPrintPickListSaga);
}
