import React, { useEffect, useRef, ReactElement, ReactNode } from 'react';

import TextField from '../../inputs/TextField';
import Button from '../../buttons/Button';

import styles from './ManualEntryScreen.css';

export type Props = {
  buttonText: string;
  children?: ReactNode;
  disabled: boolean;
  inputValue: string;
  type?: string;
  label: string;
  memo: string;
  placeholder: string;
  handleSubmit: () => void;
  handleInputChange: (value: string) => void;
  autoFocus?: boolean;
  asideBar?: boolean;
};

const ManualEntryScreen = ({
  buttonText,
  children,
  disabled,
  inputValue,
  type,
  label,
  memo,
  placeholder,
  handleSubmit,
  handleInputChange,
  autoFocus,
  asideBar,
}: Props): ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [label]);

  return (
    <section className={asideBar ? styles.sectionAside : styles.section}>
      {children}
      <article className={styles.article}>
        <p className={styles.p} data-cy="manual-entry-memo">
          {memo}
        </p>
      </article>
      <footer className={styles.footer}>
        <label htmlFor="sku-field" className={styles.label} data-cy="manual-entry-label">
          {label}
          <div className={styles.inputButtonRow}>
            <TextField
              className={styles.input}
              data-cy="manual-entry-input"
              name="sku-field"
              placeholder={placeholder}
              inputRef={inputRef}
              value={inputValue}
              type={type || 'text'}
              onChange={event => handleInputChange(event.target.value)}
              onKeyUp={event => {
                if (!disabled && event.keyCode === 13) {
                  handleSubmit();
                }
              }}
              autoFocus={autoFocus}
            />
            {!asideBar && (
              <Button
                className={styles.button}
                disabled={disabled}
                data-cy="manual-entry-button"
                onClick={handleSubmit}
              >
                {buttonText}
              </Button>
            )}
          </div>
        </label>
      </footer>
    </section>
  );
};

export default ManualEntryScreen;
