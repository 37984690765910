import { useSelector } from 'react-redux';
import axios from 'axios';
import { getConfigAuthValuesFromState } from "../redux/selectors";
import { callAPI } from '../utils';

/*
This custom hook is made to perform service calls with timeout w/o involving redux sagas,
in order to avoid creating a saga for components that do no require to mutate global state
implement this hook
*/
export default function () {
  const configAuthValues = useSelector(getConfigAuthValuesFromState);

  async function get(url: string) {
    const source = axios.CancelToken.source();
    setTimeout(() => source.cancel(), configAuthValues?.FETCH_TIMEOUT || 9000);

    try {
      const response = await callAPI({
        method: 'GET',
        url,
        cancelToken: source.token,
      });
    
      const json = response.data;
      return { status: response.status, json }
    } catch (error) {
      return { error }
    }
  }

  // TO DO: implement POST, PUT, etc... methods as needed

  return { get }
}