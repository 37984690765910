import { createAction } from 'typesafe-actions';

import {
  GetProximityStorePayload,
  SetPickerStorePayload,
  GetEmployeeMetadataPayload,
  PromptForStoreVerificationPayload,
} from './pickerStore.types';

export const getProximityStore = createAction('GET_PROXIMITY_STORE')<GetProximityStorePayload>();

export const getEmployeeMetadata = createAction('GET_EMPLOYEE_METADATA')<GetEmployeeMetadataPayload>();

export const promptForStoreVerification = createAction('PROMPT_FOR_STORE_VERIFICATION')<
  PromptForStoreVerificationPayload
>();

export const setPickerStore = createAction('SET_PICKER_STORE')<SetPickerStorePayload>();

export const storeDetectionError = createAction('STORE_DETECTION_ERROR')();
