import { getType } from 'typesafe-actions';

import { setDriverLogoffStatus } from '../actions/driverLogoff.actions';

import { DriverLogoffState } from './types';
import { RootAction } from '../actions/types';

const initialState: DriverLogoffState = { success : null };

export default (state: DriverLogoffState = initialState, action: RootAction): DriverLogoffState => {
  switch (action.type) {
    case getType(setDriverLogoffStatus): {
      return {...state, ...action.payload};
    }
    default: {
      return state;
    }
  }
};
