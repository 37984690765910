import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { setForwardingStore, forwardOrders } from '../actions/activePickTour.actions';
import { setLoading } from '../actions/loading.actions';
import { setPickTourPage } from '../actions/pickTourPage.actions';

import { mapPickTourDataToForwardOrdersRequest } from './forwardOrders.mappers';
import { callAPI } from '../../utils';
import { handleApiErrors, catchApiErrors } from './utils';

import {
  SAGA_FORWARD_ORDERS,
  BFF_ROOT,
  BFF_V1,
  BFF_FORWARD,
  BFF_STORES,
  PICK_TOUR_PAGE_COMPLETE,
} from '../../constants';

import { ConfigAuthValues } from '../../types';
import { UpdatePickTourRequest } from './types';
import { ActivePickTourState } from '../reducers/types';

const callForwardOrders = (data: UpdatePickTourRequest, storeID: string, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'POST',
    // TODO: Send padded store number when back end is ready for it
    // url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_STORES}/${padTo8LeadingZeroes(storeID)}${BFF_FORWARD}`,
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_STORES}/${storeID}${BFF_FORWARD}`,
    data,
  });
};

export function* runForwardOrdersSaga(action: ReturnType<typeof setForwardingStore>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  const activePickTourFromState: ActivePickTourState = yield select((state: RootState) => state.activePickTour);
  if (activePickTourFromState) {
    yield put(setLoading({ sagaName: SAGA_FORWARD_ORDERS, isLoading: true }));

    try {
      const result = yield call(
        callForwardOrders,
        mapPickTourDataToForwardOrdersRequest(activePickTourFromState.data),
        action.payload.storeID,
        configAuthValues
      );
      yield put(setPickTourPage({ name: PICK_TOUR_PAGE_COMPLETE }));
      yield handleApiErrors(result?.data?.errors);
    } catch (error) {
      yield catchApiErrors(error);
    }

    yield put(setLoading({ sagaName: SAGA_FORWARD_ORDERS, isLoading: false }));
  }
}

export default function* watchForwardOrders() {
  yield takeEvery(getType(forwardOrders), runForwardOrdersSaga);
}
