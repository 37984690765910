import { AdjustSlotQtyRequest } from './types';
import { AdjustSlotQtyPayload } from '../actions/activePickTour.types';

export const mapAdjustSlotQtyPayloadAndStoreIDToAdjustSlotQtyRequest = (
  payload: AdjustSlotQtyPayload,
  storeID: string
): AdjustSlotQtyRequest => ({
  storeID,
  itemID: payload.sku,
  slotID: payload.slotID.toString(),
  previousQty: payload.slotQty,
  updatedQty: 0,
  pickTourID: payload.pickTourID,
  pickTourItemDetailLineNumber: parseInt(payload.pickTourItemDetailLineNumber, 10),
});
