import React, { ReactElement } from 'react';
import { mergeClassNames } from '../../../utils';

import Button from '../../buttons/Button';

import styles from './ConfirmationScreen.css';

export type Props = {
  confirmationText: string;
  confirmationButtonText: string;
  handleConfirm: () => void;
  handleBack?: () => void;
  backButtonText?: string;
};

const ConfirmationScreen = ({
  confirmationText,
  confirmationButtonText,
  handleConfirm,
  handleBack,
  backButtonText,
}: Props): ReactElement => (
  <section className={styles.section}>
    <article className={styles.article}>
      <p className={styles.p} data-cy="confirmation-text">
        {confirmationText}
      </p>
    </article>
    <footer className={mergeClassNames(styles.footer, handleBack ? styles.doubleButtonFooter : '')}>
      {handleBack ? (
        <Button
          className={styles.doubleButton}
          color="secondary"
          data-cy="confirmation-back-button"
          onClick={handleBack}
        >
          {backButtonText}
        </Button>
      ) : null}
      <Button
        className={handleBack ? styles.doubleButton : styles.button}
        data-cy="confirmation-button"
        onClick={handleConfirm}
      >
        {confirmationButtonText}
      </Button>
    </footer>
  </section>
);

export default ConfirmationScreen;
