import React, { ReactElement } from 'react';

import styles from './icons.css';

type Props = {
  disabled?: boolean;
};

const PageForwardIcon = ({ disabled }: Props): ReactElement => (
  <svg className={styles.svg} width="12px" height="12px" viewBox="0 0 12 12">
    <title>BD4D3B55-BE60-4D79-BD79-89DFF13DCEE5</title>
    <g id="Updates---September-2020" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="UP---Pagination---Jump-State"
        transform="translate(-344.000000, -588.000000)"
        fill={disabled ? '#A9AAA8' : '#F26100'}
      >
        <g id="Pagination/mobile/default-state" transform="translate(0.000000, 569.000000)">
          <g id="Mobile/Jump" transform="translate(20.000000, 11.000000)">
            <g id="_Elements/Mobile/Active/Next" transform="translate(282.000000, 4.000000)">
              <g id="Controls/arrows/az-arrow-right" transform="translate(42.000000, 4.000000)">
                <polygon
                  id="↳-🎨Icon-Color"
                  points="6.60987849 7.95715601 7.39302369 8.83333333 10 5.91666667 7.39302369 3 6.60987849 3.87617732 7.87985866 5.29702217 0.909090909 5.29702217 0.909090909 6.53631117 7.87985866 6.53631117"
                 />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default PageForwardIcon;
