import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import i18n from '../../i18n';
import { RootState } from '../reducers/rootReducer';
import {
  adjustSlotQty,
  getPickTourItems,
  updatePickTourItem,
  setActivePick,
  updateQuantityOnHand,
} from '../actions/activePickTour.actions';
import { setLoading } from '../actions/loading.actions';
import {
  mapUpdatePickTourItemPayloadWithLocationsAndDataToUpdatePickTourItemRequest,
  mapUpdatePickTourItemResponseToSetActivePickPayload,
} from './updatePickTourItem.mappers';

import { setPickTourPage } from '../actions/pickTourPage.actions';
import { createToast } from '../actions/toasts.actions';

import {
  SAGA_UPDATE_PICK_TOUR_ITEM,
  BFF_ROOT,
  BFF_V1,
  BFF_ITEMS,
  BFF_PICK_TOURS,
  PICK_TOUR_PAGE_UPDATE_FAILED,
} from '../../constants';

import { APIPickTourListItem, UpdatePickTourItemRequest, UpdatePickTourItemResponse } from './types';
import { ConfigAuthValues, PickLocation } from '../../types';

import { callAPI, generatePickID, updatedQuantityTotal } from '../../utils';

import { handleApiErrors, catchApiErrors } from './utils';
import { ActivePickTourState } from '../reducers/types';

const callUpdatePickTourItem = (data: UpdatePickTourItemRequest, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'PUT',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${data[0].pickTourID}${BFF_ITEMS}`,
    data,
  });
};

export function* runUpdatePickTourItemSaga(action: ReturnType<typeof updatePickTourItem>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  const pickedLocations: PickLocation[] = yield select((state: RootState) => {
    if (state.activePickTour) {
      const activePick = state.activePickTour.pickTour.picks.find(
        pick => generatePickID(pick) === action.payload.pickID
      );
      if (activePick) {
        return activePick.locations.filter(location => location.pickedQuantity > 0);
      }
    }
    return [];
  });

  const allLocations: PickLocation[] = yield select((state: RootState) => {
    if (state.activePickTour) {
      const activePick = state.activePickTour.pickTour.picks.find(
        pick => generatePickID(pick) === action.payload.pickID
      );
      if (activePick) {
        return activePick.locations;
      }
    }
    return [];
  });

  const pickData: APIPickTourListItem = yield select(
    (state: RootState) =>
      state.activePickTour &&
      state.activePickTour.data.pickTourList.find(
        pickTourListItem =>
          `${pickTourListItem.orderType}:${pickTourListItem.orderID}:${pickTourListItem.pickTourItemDetailLineNumber}` ===
          action.payload.pickID
      )
  );

  yield put(setLoading({ sagaName: SAGA_UPDATE_PICK_TOUR_ITEM, isLoading: true }));

  const hasUpdatedQoh = action.payload.updatedQuantity !== null && action.payload.updatedQuantity !== undefined;
  const updatedQoh =
    action.payload.updatedQuantity !== null &&
    action.payload.updatedQuantity !== undefined &&
    action.payload.updatedQuantity;

  try {
    const { data }: { data: UpdatePickTourItemResponse } = yield call(
      callUpdatePickTourItem,
      mapUpdatePickTourItemPayloadWithLocationsAndDataToUpdatePickTourItemRequest(
        action.payload,
        hasUpdatedQoh ? allLocations : pickedLocations,
        pickData
      ),
      configAuthValues
    );
    // TODO: Validate API response
    const responsePickTourItem = data.pickTourItems[0];
    // TODO: Determine if slot information is needed here (is orderItemQuantity ever > current slot qty?)
    if (
      responsePickTourItem.orderType === 'OVERSTOCK' &&
      action.payload.pickedQuantity < responsePickTourItem.orderItemQuantity &&
      pickData.location[0].expectedQuantity !== null &&
      action.payload.pickedQuantity < pickData.location[0].expectedQuantity
    ) {
      yield put(
        adjustSlotQty({
          sku: responsePickTourItem.itemID,
          slotID: responsePickTourItem.location[0].itemSequenceNumber,
          slotQty: pickData.location[0].expectedQuantity - action.payload.pickedQuantity,
          pickTourID: pickData.pickTourID,
          pickTourItemDetailLineNumber: pickData.pickTourItemDetailLineNumber.toString(),
        })
      );
    } else if (
      action.payload.paginationData.isPaginated &&
      responsePickTourItem.orderType === 'OVERSTOCK' &&
      pickData.location[0].expectedQuantity !== null &&
      action.payload.pickedQuantity >= pickData.location[0].expectedQuantity
    ) {
      yield put(
        getPickTourItems({
          isPickTourCreation: false,
          page: action.payload.paginationData.pageNumber,
          pickTourID: responsePickTourItem.pickTourID,
          orderType: responsePickTourItem.orderType,
        })
      );
    } else if (
      action.payload.paginationData.isPaginated &&
      action.payload.isLastPick &&
      action.payload.paginationData.nextPickPageNumber !== null
    ) {
      yield put(
        getPickTourItems({
          isPickTourCreation: false,
          page: action.payload.paginationData.nextPickPageNumber || 0,
          pickTourID: responsePickTourItem.pickTourID,
          orderType: responsePickTourItem.orderType,
        })
      );
    } else if (
      responsePickTourItem.orderType === 'RECALL' &&
      hasUpdatedQoh &&
      updatedQoh !== false &&
      pickData.keepQuantityOnHand !== null
    ) {
      const activePickTour: ActivePickTourState = yield select((state: RootState) => {
        return state.activePickTour
      })

      const activePick = activePickTour && activePickTour.pickTour.picks.find(pick => generatePickID(pick) === action.payload.pickID);
      const totalItems = activePickTour && activePickTour.isPaginated && activePickTour.paginationMetadata.totalItems ? activePickTour.paginationMetadata.totalItems : 0

      let tmp = 0
      if (activePick) {
        const keepQuantityOnHand = activePick.keepQuantityOnHand ? activePick?.keepQuantityOnHand : 0
        const itemTotalQuantity = activePick.totalQuantity
        const oldUpdatedQty = updatedQuantityTotal(activePick)

        if (oldUpdatedQty) {
          tmp = updatedQoh - oldUpdatedQty
        }

        else {
          tmp = updatedQoh - itemTotalQuantity - keepQuantityOnHand
        }
      }

      const newTotal = totalItems + tmp
      yield put(
        updateQuantityOnHand({
          activePickID: action.payload.pickID,
          resetPickedQuantity: action.payload.pickedQuantity === 0,
          data: responsePickTourItem,
          updatedQuantity: updatedQoh,
          totalItems: newTotal < 0 ? 0 : newTotal // Never let total get below  0
        })
      );
      if (
        action.payload.pickedQuantity === updatedQoh - pickData.keepQuantityOnHand ||
        updatedQoh <= pickData.keepQuantityOnHand
      ) {
        yield put(
          setActivePick(
            mapUpdatePickTourItemResponseToSetActivePickPayload(
              data,
              {
                pickID: action.payload.pickID,
                pickedQuantity: action.payload.pickedQuantity,
                skipReason: action.payload.skipReason,
                isSkipped: action.payload.isSkipped,
                locationIndex: action.payload.locationIndex,
              },
              action.payload.nextPick
            )
          )
        );
      } else if (action.payload.pickedQuantity === 0) {
        yield put(
          createToast({
            type: 'information',
            message: i18n.t('ACTIVE_PICK_TOUR.RECALL_RESCAN'),
          })
        );
      } else {
        yield put(
          createToast({
            type: 'information',
            message: i18n.t('ACTIVE_PICK_TOUR.RECALL_UPDATED'),
          })
        );
      }
    } else {
      yield put(
        setActivePick(
          mapUpdatePickTourItemResponseToSetActivePickPayload(
            data,
            {
              pickID: action.payload.pickID,
              pickedQuantity: action.payload.pickedQuantity,
              skipReason: action.payload.skipReason,
              isSkipped: action.payload.isSkipped,
              locationIndex: action.payload.locationIndex,
            },
            action.payload.nextPick
          )
        )
      );
    }
    yield handleApiErrors(data.errors);
  } catch (error) {
    yield put(setPickTourPage({ name: PICK_TOUR_PAGE_UPDATE_FAILED }));
    try {
      const data = mapUpdatePickTourItemPayloadWithLocationsAndDataToUpdatePickTourItemRequest(
        action.payload,
        hasUpdatedQoh ? allLocations : pickedLocations,
        pickData
      )
      const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${data[0].pickTourID}${BFF_ITEMS}`
      const method = 'PUT';
      const config = configAuthValues;
      yield catchApiErrors(error, {url, method, data, config, saga: SAGA_UPDATE_PICK_TOUR_ITEM});
    } catch (_error) {
      yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_UPDATE_PICK_TOUR_ITEM, isLoading: false }));
}

export default function* watchUpdatePickTourItem() {
  yield takeEvery(getType(updatePickTourItem), runUpdatePickTourItemSaga);
}
