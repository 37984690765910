import React, { ReactElement, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';
import MediaQuery from 'react-responsive';

import { useTranslation } from 'react-i18next';
import Button from '../../buttons/Button';
import SkipMenu from '../../pages/PickTourFlow/ActivePickTour/SkipMenu';

import { setActivePickID } from '../../../redux/actions/activePickTour.actions';

import useComponentVisible from '../../../hooks/useComponentVisible';

import {
  MQ_MAX_WIDTH,
  ORDER_TYPE_SAT_TO_SAT,
  ORDER_TYPE_COMMERCIAL,
  ORDER_TYPE_COMMERCIAL_EP,
  ORDER_TYPE_OVERSTOCK,
  ORDER_TYPE_RECALL,
  CONFIRM_SKIP,
} from '../../../constants';

import KeyboardIcon from '../../../assets/icons/KeyboardIcon';
import SkipIcon from '../../../assets/icons/SkipIcon';
import SkipLocationIcon from '../../../assets/icons/SkipLocationIcon';
import PreviousLocationIcon from '../../../assets/icons/PreviousLocationIcon';
import NextLocationIcon from '../../../assets/icons/NextLocationIcon';
import AdjustSlotQtyIcon from '../../../assets/icons/AdjustSlotQtyIcon';
import XIcon from '../../../assets/icons/XIcon';

import styles from './DetailsViewCardFooter.css';
import { ManualEntry, SkipReason, Pick } from '../../../types';
import CheckSquare from '../../../assets/icons/CheckSquare';

import { mergeClassNames, generatePickID, calculateAmountToPick, isCommercialOrder } from '../../../utils';

type Props = {
  activePick: Pick;
  isTourMixedValidation: boolean;
  currentLanguage: string;
  handlePick: (qty: number) => void;
  onSkip: (skipOption: SkipReason) => void;
  setShouldShowManualEntry: (value: SetStateAction<ManualEntry>) => void;
  skipLocation: () => void;
  handleAdjustSlotQty: () => void;
  handleAdjustRecallQty: () => void;
};

const DetailsViewCardFooter = ({
  activePick,
  isTourMixedValidation,
  currentLanguage,
  handlePick,
  onSkip,
  setShouldShowManualEntry,
  skipLocation,
  handleAdjustSlotQty,
  handleAdjustRecallQty,
}: Props): ReactElement => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.DETAILS_VIEW.ACTION_BUTTONS';

  // For now only use this for SAT2SAT, COMMERCIAL and RECALL pick types
  const isLastLocation =
    [ORDER_TYPE_SAT_TO_SAT, ORDER_TYPE_COMMERCIAL, ORDER_TYPE_COMMERCIAL_EP, ORDER_TYPE_RECALL].includes(
      activePick.orderType
    ) && activePick.locationIndex + 1 >= activePick.locations.length;

  const isOverstockPickSkipped = activePick.orderType === 'OVERSTOCK' && activePick.isSkipped;

  const displaySkipIcon = () => {
    if (isLastLocation) {
      if (isComponentVisible) {
        return <XIcon />;
      }
      return <SkipIcon />;
    }
    return <SkipLocationIcon />;
  };

  const shouldSkipValidation = () => {
    if (activePick && (activePick.orderType === ORDER_TYPE_RECALL || isCommercialOrder(activePick.orderType))) {
      const updatedQuantity =
        activePick.locations.length > 0 && activePick.locations[activePick.locations.length - 1].updatedQuantity;
      const quantityOnHand =
        updatedQuantity !== null && updatedQuantity !== undefined ? updatedQuantity : activePick.quantityOnHand;
      return (
        (!activePick.isUpcScanRequired && !isTourMixedValidation && activePick.pickedQuantity === 0) ||
        (quantityOnHand !== null &&
          quantityOnHand !== undefined &&
          activePick.keepQuantityOnHand !== null &&
          Number(quantityOnHand) <= activePick.keepQuantityOnHand)
      );
    }
    return false;
  };

  const onClickSkip = () => {
      if (isLastLocation) {
        setIsComponentVisible(flag => !flag);
      } else {
        skipLocation();
      }
  }

  return (
    <footer className={styles.footer}>
      <section className={styles.buttons}>
        <MediaQuery maxWidth={MQ_MAX_WIDTH}>
          {activePick.isUpcScanRequired || isTourMixedValidation ? (
            <label
              className={mergeClassNames(
                styles.label,
                activePick.orderType === ORDER_TYPE_SAT_TO_SAT && currentLanguage === 'es-MX' && styles.labelSpanish
              )}
              htmlFor="key-in-item"
            >
              <Button
                name="key-in-item"
                variant="round"
                disabled={activePick.pickedQuantity === calculateAmountToPick(activePick) || isOverstockPickSkipped}
                onClick={() => setShouldShowManualEntry('quantity')}
                data-cy="pick-tour-key-in-item-button"
              >
                <KeyboardIcon />
              </Button>
              {t(`${translationRoot}.KEY_ITEM_OR_QTY`)}
            </label>
          ) : (
            <label
              className={mergeClassNames(
                styles.label,
                activePick.orderType === ORDER_TYPE_SAT_TO_SAT && currentLanguage === 'es-MX' && styles.labelSpanish
              )}
              htmlFor="key-in-qty"
            >
              <Button
                name="key-in-qty"
                variant="round"
                disabled={activePick.pickedQuantity === calculateAmountToPick(activePick) || isOverstockPickSkipped}
                onClick={() => setShouldShowManualEntry('quantity')}
                data-cy="pick-tour-key-in-item-button"
              >
                <KeyboardIcon />
              </Button>
              {t(`${translationRoot}.KEY_QTY`)}
            </label>
          )}
        </MediaQuery>
        {activePick.orderType === ORDER_TYPE_OVERSTOCK && (
          <label className={styles.label} htmlFor="adjust">
            <div className={styles.buttonContainer}>
              <Button
                name="adjust"
                variant="round"
                disabled={activePick.isSkipped || activePick.pickedQuantity === calculateAmountToPick(activePick)}
                onClick={handleAdjustSlotQty}
                data-cy="pick-tour-skip-button"
              >
                <AdjustSlotQtyIcon />
              </Button>
            </div>
            {t(`${translationRoot}.ADJUST_SLOT_QTY`)}
          </label>
        )}
        {activePick.orderType === ORDER_TYPE_RECALL && (
          <>
            <label className={styles.label} htmlFor="adjust">
              <div className={styles.buttonContainer}>
                <Button
                  name="adjust"
                  variant="round"
                  // SDSACC-455:The calculated amount to pick is zero (no items left to pick) disable to the Adjust RECALL Button.
                  disabled={
                    !isLastLocation ||
                    calculateAmountToPick(activePick) === 0 ||
                    activePick.pickedQuantity === calculateAmountToPick(activePick)
                  }
                  onClick={() => handleAdjustRecallQty()}
                  data-cy="pick-tour-adjust-button"
                >
                  <AdjustSlotQtyIcon />
                </Button>
              </div>
              {t(`${translationRoot}.ADJUST_RECALL`)}
            </label>
            {activePick.locations.length > 1 && (
              <>
                <label className={styles.label} htmlFor="prev">
                  <div className={styles.buttonContainer}>
                    <Button
                      name="prev"
                      variant="round"
                      disabled={activePick.locationIndex === 0}
                      onClick={() => {
                        dispatch(
                          setActivePickID({
                            id: generatePickID(activePick),
                            locationIndex: activePick.locationIndex - 1,
                          })
                        );
                      }}
                      data-cy="pick-tour-next-button"
                    >
                      <PreviousLocationIcon />
                    </Button>
                  </div>
                  {t(`${translationRoot}.PREVIOUS_LOCATION`)}
                </label>
                {isLastLocation && shouldSkipValidation() ? (
                  <MediaQuery maxWidth={MQ_MAX_WIDTH}>
                    <label className={styles.label} htmlFor="complete">
                      <div className={styles.buttonContainer}>
                        <Button
                          name="complete"
                          variant="round"
                          disabled={
                            activePick.pickedQuantity === calculateAmountToPick(activePick) || isOverstockPickSkipped
                          }
                          onClick={() => handlePick(calculateAmountToPick(activePick))}
                          data-cy="pick-tour-complete-button"
                        >
                          <CheckSquare />
                        </Button>
                        <span>{t(`${translationRoot}.COMPLETE`)}</span>
                      </div>
                    </label>
                  </MediaQuery>
                ) : (
                  <label className={styles.label} htmlFor="next">
                    <div className={styles.buttonContainer}>
                      <Button
                        name="next"
                        variant="round"
                        disabled={isLastLocation}
                        onClick={() => {
                          dispatch(
                            setActivePickID({
                              id: generatePickID(activePick),
                              locationIndex: activePick.locationIndex + 1,
                            })
                          );
                        }}
                        data-cy="pick-tour-next-button"
                      >
                        <NextLocationIcon />
                      </Button>
                    </div>
                    {t(`${translationRoot}.NEXT_LOCATION`)}
                  </label>
                )}
              </>
            )}
          </>
        )}
        {activePick.orderType !== ORDER_TYPE_RECALL && activePick.orderType !== ORDER_TYPE_OVERSTOCK && (
          <label
            className={mergeClassNames(
              styles.label,
              activePick.orderType === ORDER_TYPE_SAT_TO_SAT && currentLanguage === 'es-MX' && styles.labelSpanish
            )}
            htmlFor="skip"
          >
            <div className={styles.buttonContainer}>
              <Button
                name="skip"
                variant="round"
                disabled={activePick.isSkipped || activePick.pickedQuantity === calculateAmountToPick(activePick)}
                onClick={onClickSkip}
                data-cy="pick-tour-skip-button"
              >
                {displaySkipIcon()}
              </Button>
              <span>{t(`${translationRoot}.${isLastLocation ? 'SKIP' : 'NEXT_LOCATION'}`)}</span>
              {isComponentVisible ? (
                <span ref={ref} className={styles.skipList}>
                  <SkipMenu
                    onSkip={skipReason => {
                      setIsComponentVisible(flag => !flag);
                      if (skipReason !== null) {
                        onSkip(skipReason);
                      }
                    }}
                    options={[
                      {
                        name: t(`${translationRoot}.CONFIRM_SKIP`),
                        skipReason: CONFIRM_SKIP,
                      },
                      {
                        name: t(`${translationRoot}.CANCEL`),
                        skipReason: null,
                      },
                    ]}
                  />
                </span>
              ) : null}
            </div>
          </label>
        )}
        {shouldSkipValidation() && (isCommercialOrder(activePick.orderType) || activePick.locations.length === 1) ? (
          <MediaQuery maxWidth={MQ_MAX_WIDTH}>
            <label className={styles.label} htmlFor="complete">
              <div className={styles.buttonContainer}>
                <Button
                  name="complete"
                  variant="round"
                  disabled={activePick.pickedQuantity === calculateAmountToPick(activePick) || isOverstockPickSkipped}
                  onClick={() => handlePick(calculateAmountToPick(activePick))}
                  data-cy="pick-tour-complete-button"
                >
                  <CheckSquare />
                </Button>
                <span>{t(`${translationRoot}.COMPLETE`)}</span>
              </div>
            </label>
          </MediaQuery>
        ) : null}
      </section>
    </footer>
  );
};

export default DetailsViewCardFooter;
