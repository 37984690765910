import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { getExternalConfigValues, setRefreshOrdersInterval } from '../actions/config.actions';
import { setLoading } from '../actions/loading.actions';
import { mapGetExternalConfigResponseToSetRefreshOrdersIntervalPayload } from './getExternalConfig.mappers';

import { SAGA_GET_EXTERNAL_CONFIG, BFF_ROOT, BFF_EXTERNAL_CONFIG, BFF_V1 } from '../../constants';

import { ConfigAuthValues } from '../../types';

import { callAPI } from '../../utils';

import { handleApiErrors, catchApiErrors } from './utils';
import { getOrders } from '../actions/orders.actions';

const callGetExternalConfig = (storeID: string, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'GET',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_EXTERNAL_CONFIG}/${storeID}`,
  });
};

export function* runGetExternalConfigSaga(action: ReturnType<typeof getExternalConfigValues>) {
  yield put(setLoading({ sagaName: SAGA_GET_EXTERNAL_CONFIG, isLoading: true }));
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  try {
    const result = yield call(callGetExternalConfig, action.payload.storeID, configAuthValues);
    // TODO: Validate API response
    yield put(setRefreshOrdersInterval(mapGetExternalConfigResponseToSetRefreshOrdersIntervalPayload(result.data)));
    yield handleApiErrors(result.data.errors);
    yield put(getOrders({ fulfillmentStoreID: action.payload.storeID }));
  } catch (error) {
    try {
      const {storeID} = action.payload;
      const url = `${BFF_ROOT}${BFF_V1}${BFF_EXTERNAL_CONFIG}/${storeID}`
      const method = 'GET'
      const data = {};
      const config = configAuthValues;
      yield catchApiErrors(error, {url, method, data, config, saga: SAGA_GET_EXTERNAL_CONFIG});
    } catch (_error) {
      yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_GET_EXTERNAL_CONFIG, isLoading: false }));
}

export default function* watchGetOrders() {
  yield takeEvery(getType(getExternalConfigValues), runGetExternalConfigSaga);
}
