import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { mergeClassNames } from '../../../utils';

import styles from './ItemQuantityBox.css';

type Props = {
  pickedQuantity: number;
  totalQuantity: number;
  hasJustScanned: boolean;
};

const ItemQuantityBox = ({ pickedQuantity, totalQuantity, hasJustScanned }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.DETAILS_VIEW';

  return (
    <article className={mergeClassNames(styles.article, hasJustScanned && styles.green)}>
      <p className={styles.itemQtyLabel}>{t(`${translationRoot}.ITEM_QTY`)}</p>
      <h6 className={styles.itemQty} data-cy="pick-tour-item-quantity-status">
        {pickedQuantity}/{totalQuantity}
      </h6>
    </article>
  );
};

export default ItemQuantityBox;
