import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { skipPickTour, setForwardingStore } from '../actions/activePickTour.actions';
import { setLoading } from '../actions/loading.actions';
import { setPickTourPage } from '../actions/pickTourPage.actions';

import {
  mapPickTourDataToSkipPickTourRequest,
  mapSkipPickTourResponseToSetForwardingStorePayload,
} from './skipPickTour.mappers';
import { callAPI } from '../../utils';
import { handleApiErrors, catchApiErrors } from './utils';

import {
  SAGA_SKIP_PICK_TOUR,
  BFF_ROOT,
  BFF_V1,
  BFF_PICK_TOURS,
  BFF_SKIP,
  PICK_TOUR_PAGE_COMMERCIAL_SKIP,
} from '../../constants';

import { ConfigAuthValues } from '../../types';
import { UpdatePickTourRequest } from './types';
import { ActivePickTourState } from '../reducers/types';

const callSkipPickTour = (data: UpdatePickTourRequest, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'POST',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${data.pickTourID}${BFF_SKIP}`,
    data,
  });
};

export function* runSkipPickTourSaga() {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  const activePickTourFromState: ActivePickTourState = yield select((state: RootState) => state.activePickTour);
  if (activePickTourFromState) {
    yield put(setLoading({ sagaName: SAGA_SKIP_PICK_TOUR, isLoading: true }));

    try {
      const result = yield call(
        callSkipPickTour,
        mapPickTourDataToSkipPickTourRequest(activePickTourFromState.data),
        configAuthValues
      );
      yield put(setForwardingStore(mapSkipPickTourResponseToSetForwardingStorePayload(result.data)));
      yield put(setPickTourPage({ name: PICK_TOUR_PAGE_COMMERCIAL_SKIP }));
      yield handleApiErrors(result?.data?.errors);
    } catch (error) {
      try {
        const data = mapPickTourDataToSkipPickTourRequest(activePickTourFromState.data)
        const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}/${data.pickTourID}${BFF_SKIP}`
        const method = 'POST'
        const config = configAuthValues;
        yield catchApiErrors(error, {url, method, data, config, saga: SAGA_SKIP_PICK_TOUR});
      } catch (_error) {
        yield catchApiErrors(error);
      }
    }

    yield put(setLoading({ sagaName: SAGA_SKIP_PICK_TOUR, isLoading: false }));
  }
}

export default function* watchSkipPickTour() {
  yield takeEvery(getType(skipPickTour), runSkipPickTourSaga);
}
