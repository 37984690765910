import { getType } from 'typesafe-actions';

import { setOrders, setHasManualOverstockOrder,setOrderStatus, clearSelected } from '../actions/orders.actions';

import { OrdersState } from './types';
import { RootAction } from '../actions/types';

const initialState: OrdersState = {
  orders: [],
  pickTours: [],
  completedRecalls: {
    links: [],
    content: [],
    page: {
      number: 0,
      size: 0,
      totalElements:0,
      totalPages: 0
    }
  },
  hasManualOverstockOrder: false,
  lastAttemptSuccess:false,
  lastAttemptTimeStamp:Date.now(),
  clearSelected: false,
};

export default (state: OrdersState = initialState, action: RootAction): OrdersState => {
  switch (action.type) {
    case getType(setOrders): {
      return { ...state, ...action.payload };
    }
    case getType(setHasManualOverstockOrder): {
      return { ...state, ...action.payload };
    }

    case getType(setOrderStatus): {
      return { ...state, ...action.payload };
    }

    case getType(clearSelected): {
      return {...state, ...{clearSelected: action.payload}}
    }
    default: {
      return state;
    }
  }
};
