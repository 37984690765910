import React, { useState, useEffect, useRef, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../../../inputs/TextField';
import Button from '../../../../buttons/Button';

import { setInputIfDigits } from '../../../../../utils';

import styles from './AdjustRecallQuantity.css';

type Props = {
  partNumber: string;
  systemQoH: number;
  keepQoH: number;
  amountScanned: number;
  handleConfirmQoH: (quantity: number) => void;
  handleConfirmAmount: (quantity: number) => void;
};

const AdjustRecallQuantity = ({
  partNumber,
  systemQoH,
  keepQoH,
  amountScanned,
  handleConfirmQoH,
  handleConfirmAmount,
}: Props): ReactElement => {
  const [inputValue, setInputValue] = useState('');
  const [isAmountConfirmation, setIsAmountConfirmation] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();

  const translationRoot = 'ADJUST_RECALL_QUANTITY';

  const inputQuantity = parseInt(inputValue, 10);

  const handleConfirm = () => {
    if (inputValue.length > 0) {
      if (isAmountConfirmation) {
        handleConfirmAmount(inputQuantity);
      } else if (Math.max(inputQuantity - keepQoH, 0) < amountScanned) {
        setIsAmountConfirmation(true);
      } else {
        handleConfirmQoH(inputQuantity);
      }
    }
  };

  useEffect(() => {
    if (inputRef.current && !isAmountConfirmation) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [isAmountConfirmation]);

  return (
    <section className={styles.section}>
      <header className={styles.header}>
        {isAmountConfirmation ? (
          <div className={styles.detailsContainer}>
            <div className={styles.amountScannedContainer}>
              <p className={styles.amountScannedLabel}>{t(`${translationRoot}.AMOUNT_SCANNED`)}</p>
              <p className={styles.amountScanned}>{amountScanned}</p>
            </div>
            <div className={styles.newPickAmountContainer}>
              <p className={styles.newPickAmountLabel}>{t(`${translationRoot}.NEW_PICK_AMOUNT`)}</p>
              <p className={styles.newPickAmount}>{Math.max(inputQuantity - keepQoH, 0)}</p>
            </div>
          </div>
        ) : (
          <div className={styles.detailsContainer}>
            <div className={styles.partNumberContainer}>
              <p className={styles.partNumberLabel}>{t(`${translationRoot}.PART_NUMBER`)}</p>
              <p className={styles.partNumber}>{partNumber}</p>
            </div>
            <div className={styles.systemQoHContainer}>
              <p className={styles.systemQoHLabel}>{t(`${translationRoot}.SYSTEM_QOH`)}</p>
              <p className={styles.systemQoH}>{systemQoH}</p>
            </div>
            <div className={styles.keepQoHContainer}>
              <p className={styles.keepQoHLabel}>{t(`${translationRoot}.KEEP_QTY_OF`)}</p>
              <p className={styles.keepQoH}>{keepQoH}</p>
            </div>
          </div>
        )}
        <p className={styles.message}>
          {t(`${translationRoot}.${isAmountConfirmation ? 'NEW_AMOUNT_MESSAGE' : 'ENTER_QTY_MESSAGE'}`)}
        </p>
      </header>
      <footer className={styles.footer}>
        {isAmountConfirmation ? (
          <Button className={styles.button} color="secondary" onClick={() => setIsAmountConfirmation(false)}>
            {t(`${translationRoot}.BACK`)}
          </Button>
        ) : (
          <label htmlFor="quantity" className={styles.label}>
            {t(`${translationRoot}.ENTER_QUANTITY`)}
            <TextField
              className={styles.input}
              name="quantity"
              inputRef={inputRef}
              value={inputValue}
              type="number"
              onChange={event => setInputIfDigits({ inputSetter: setInputValue, value: event.target.value })}
              onKeyUp={event => {
                if (event.keyCode === 13) {
                  handleConfirm();
                }
              }}
            />
          </label>
        )}
        <Button
          className={isAmountConfirmation ? styles.button : styles.buttonNarrow}
          disabled={inputValue.length < 1}
          onClick={handleConfirm}
        >
          {t(`${translationRoot}.CONFIRM`)}
        </Button>
      </footer>
    </section>
  );
};

export default AdjustRecallQuantity;
