import React, { useState, useEffect, ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Authentication } from '@az/auth';
import { useTranslation } from 'react-i18next';

import { useIdleTimer } from 'react-idle-timer'
import ToastContainer from './layout/ToastContainer';
import Spinner from './layout/Spinner';
import OrderList from './pages/OrderList';
import PickTourFlow from './pages/PickTourFlow';
import Switch, { Page } from './layout/Switch';
import FullScreenPopup from './layout/FullScreenPopup';
import Main from './layout/Main';

import { createToast, removeToastsOnInactivity } from '../redux/actions/toasts.actions';
import {
  setPickerStore,
  getProximityStore,
  getEmployeeMetadata,
  storeDetectionError
} from '../redux/actions/pickerStore.actions';
import { setConfigAuthValues } from '../redux/actions/config.actions';
import { sendLog } from '../redux/actions/log.actions';
import {
  getAppPageNameFromState,
  getConfigAuthValuesFromState,
  selectPickerStore,
  selectInactivityTimeoutForToasts
} from '../redux/selectors';

import { APP_PAGE_ORDER_LIST, APP_PAGE_PICK_TOUR } from '../constants';

import { determineDeviceType, getAppVersion, getSystemDetails, padTo8LeadingZeroes } from '../utils';

import { AppLogType, ConfigAuthValues } from '../types';

import '../styles/main.css';
import styles from './App.css';

import { setEmployeeData } from "../redux/actions/employeeData.actions";
import useRecoverLastSessionDetails from '../hooks/useRecoverLastSessionDetails';

const App = (): ReactElement => {

  const [hasConfigError, setHasConfigError] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isWaitingForLocationPrompt, setIsWaitingForLocationPrompt] = useState(false);
  const [isStartUpLogSent, setIsStartUpLogSent] = useState(false);


  const { t } = useTranslation();

  const currentPageName = useSelector(getAppPageNameFromState);
  const configAuthValues = useSelector(getConfigAuthValuesFromState);
  const pickerStore = useSelector(selectPickerStore);
  const inactivityTimeoutForToasts = useSelector(selectInactivityTimeoutForToasts)
  const loginData = sessionStorage.getItem('loginData');
  const deviceType = determineDeviceType();

  const devices = configAuthValues?.SSO_DEVICES || [];
  const isMobile = devices.indexOf(deviceType.toUpperCase()) !== -1

  const dispatch = useDispatch();

  useRecoverLastSessionDetails(isAuthenticated);


  const handleOnActive = () => {
    dispatch(removeToastsOnInactivity())

  }

  useIdleTimer({
    timeout: inactivityTimeoutForToasts,
    onActive: handleOnActive,
  })

  const setScripts = (env: string) => {
    const head = document.getElementsByTagName('head');
    if (!(window.frameElement && window.frameElement.id === 'embed')) {
      if (head && head.length !== 0) {
        const script = document.createElement('script');
        if (env === 'pr-ext') {
          script.setAttribute(
            'src',
            'https://cdn.whatfix.com/prod/abb6f400-cff2-11e9-ba18-c63d85ed437c/embed/embed.nocache.js'
          );
          script.setAttribute('type', 'text/javascript');
        } else {
          script.setAttribute('src', '//whatfix.com/abb6f400-cff2-11e9-ba18-c63d85ed437c/embed/embed.nocache.js');
          script.setAttribute('type', 'text/javascript');
        }
        head[0].appendChild(script);
      }
    }
  };

  useEffect(() => {
    
    if (configAuthValues && loginData) {
      if (!isStartUpLogSent) {
        getAppVersion().then(appVersion => {
          dispatch(
            sendLog({
              logEvent: AppLogType.START_UP,
              logDetails: {
                ...getSystemDetails(),
                appVersion,
              },
            })
          );
        });

        setIsStartUpLogSent(true);
      }
    }
  }, [configAuthValues, dispatch, isStartUpLogSent, loginData]);

  useEffect(() => {
    const isLocalDev = JSON.parse(process.env.IS_LOCAL_DEV || 'false');
    const setRefreshData = (configModule: any) => {
      sessionStorage.setItem(
        'refreshData',
        JSON.stringify({
          AUTH_ROOT: configModule.AUTH_ROOT,
          REDIRECT_URI: configModule.REDIRECT_URI,
          CLIENT_ID: configModule.CLIENT_ID,
          SSO_DEVICES: configModule.SSO_DEVICES,
        })
      );
    };
    if (isLocalDev) {
      import('../../config.local.js').then(configModule => {
        setScripts(configModule.ENV);
        dispatch(setConfigAuthValues({ values: configModule.default as ConfigAuthValues }));
        setRefreshData(configModule);
      });
    } else {
      fetch('/config.json')
        .then(response => response.json())
        .then(json => {
          setScripts(json.ENV);
          dispatch(setConfigAuthValues({ values: json }));
          setRefreshData(json);
        })
        .catch(() => setHasConfigError(true));
    }
  }, [dispatch]);

  const ConfigDisplay = () => (
    <Main>
      {hasConfigError ? (
        <>
          <h1 className={styles.message}>Configuration failed to load</h1>
          <h1 className={styles.message}>Please refresh the page</h1>
        </>
      ) : (
        <h1 className={styles.message}>Loading configuration...</h1>
      )}
    </Main>
  );

  return (
    <>
      <Spinner
        isInitialSetup={(!configAuthValues || !isAuthenticated || isWaitingForLocationPrompt) && !hasConfigError}
      />
      {configAuthValues?.AUTH_ROOT_MOBILE ? (
        <Authentication
          baseUrl={configAuthValues.AUTH_ROOT}
          publicClientId={configAuthValues.CLIENT_ID}
          redirectUri={configAuthValues.REDIRECT_URI}
          scope={configAuthValues.AUTH_SCOPE}
          skipAuthorization
          getUserData
          mobileUrl={
            isMobile && configAuthValues.AUTH_ROOT_MOBILE !== configAuthValues.AUTH_ROOT
              ? configAuthValues.AUTH_ROOT_MOBILE
              : undefined
          }
          pkceLoginURL={configAuthValues.PKCE_LOGIN_URL}
        >
          {({ authenticated, disabledForDevelopment, userData }) => {
            if (!isAuthenticated && (authenticated || disabledForDevelopment)) {
              setIsAuthenticated(true);
              dispatch(setEmployeeData({
                ignitionID: userData.claims.uid,
                name: userData.claims.cn}));
              if(isMobile) {
                setIsWaitingForLocationPrompt(true);
              }
              if (!isMobile) {
                /**
                 * Supply Chain expects store number to be *exactly* 8 digits, so store numbers
                 * shorter than that need to be left-padded.  As additional safety checks, make sure
                 * we got a string to begin with and store numbers longer than 8 should have left
                 * padding removed down to length 8.
                 */
                let storeNumber: string | null | undefined = userData.claims.departmentnumber;
                if (storeNumber === undefined || storeNumber === null) {
                  storeNumber = "00000000";
                } else if (storeNumber.length < 8) {
                  storeNumber = padTo8LeadingZeroes(storeNumber);
                } else if (storeNumber.length > 8) {
                  storeNumber = storeNumber.slice(-8);
                }
                dispatch(setPickerStore({ storeID: storeNumber }));
              }
              dispatch(sendLog({ logEvent: AppLogType.LOG_IN }));
            } else if (
              isAuthenticated &&
              !pickerStore.storeID &&
              !pickerStore.isDetectingStore &&
              !pickerStore.shouldPromptStoreSelection &&
              isWaitingForLocationPrompt
            ) {
              const getCurrentPositionSuccessCallback: PositionCallback = position => {
                setIsWaitingForLocationPrompt(false);
                if (userData) {
                  dispatch(
                    getProximityStore({
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                      employeeID: userData.claims.uid,
                    })
                  );
                } else {
                  dispatch(
                    getProximityStore({
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                    })
                  );
                }
              };
              const getCurrentPositionErrorCallback: PositionErrorCallback = error => {
                setIsWaitingForLocationPrompt(false);
                if (userData) {
                  dispatch(
                    getEmployeeMetadata({
                      employeeID: userData.claims.uid,
                      positionError: error.code,
                    })
                  );
                } else {
                  // Will only happen locally
                  dispatch(storeDetectionError());
                  if (error.code === 1) {
                    dispatch(
                      createToast({
                        type: 'error',
                        message: t(`ERRORS.ENABLE_LOCATION`),
                      })
                    );
                  }
                }
              };
              window.navigator.geolocation.getCurrentPosition(
                getCurrentPositionSuccessCallback,
                getCurrentPositionErrorCallback,
                {
                  timeout: 5000,
                }
              );
            }

            return isAuthenticated && !isWaitingForLocationPrompt ? (
              <>
                <FullScreenPopup />
                <ToastContainer />
                <Switch currentPageName={currentPageName}>
                  <Page name={APP_PAGE_ORDER_LIST}>
                    <OrderList />
                  </Page>
                  <Page name={APP_PAGE_PICK_TOUR}>
                    <PickTourFlow />
                  </Page>
                </Switch>
              </>
            ) : (
              <Main>
                <h1 className={styles.message}>Authenticating...</h1>
              </Main>
            );
          }}
        </Authentication>
      ) : (
        <ConfigDisplay />
      )}
    </>
  );
};

export default App;
