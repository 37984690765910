import React, { ReactElement } from 'react';

const NextLocationIcon = (): ReactElement => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="20" fill="#DD3900" />
    <path d="M20 12L18.59 13.41L24.17 19H12V21H24.17L18.59 26.59L20 28L28 20L20 12Z" fill="white" />
  </svg>
);

export default NextLocationIcon;
