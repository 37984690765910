import { getType } from 'typesafe-actions';

import { setPickTourPage } from '../actions/pickTourPage.actions';

import { PickTourPageState } from './types';
import { RootAction } from '../actions/types';

import { PICK_TOUR_PAGE_PICK_TOUR } from '../../constants';

const initialState: PickTourPageState = {
  name: PICK_TOUR_PAGE_PICK_TOUR,
};

export default (state: PickTourPageState = initialState, action: RootAction): PickTourPageState => {
  switch (action.type) {
    case getType(setPickTourPage): {
      return {
        name: action.payload.name,
      };
    }
    default: {
      return state;
    }
  }
};
