import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import CheckmarkSquareIcon from '../../../../assets/icons/CheckmarkSquareIcon';
import HighPriorityIcon from '../../../../assets/icons/HighPriorityIcon';
import CheckBoxSpinner from '../../../layout/Spinner/CheckBoxSpinner';

import { APIPickTour } from '../../../../redux/sagas/types';

import {
  mergeClassNames,
  displayOrderType,
  determineIconByOrderType,
  displayDestination,
  displayDate,
  epRegEx,
} from '../../../../utils';

import styles from './PickTourItem.css';

type Props = {
  pickTour: APIPickTour;
  isSelected: boolean;
  onSelect: () => void;
  currentLanguage: string;
  verifying?: boolean;
};

const PickTourItem = ({ pickTour, isSelected, onSelect, currentLanguage, verifying }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ORDER_LIST.LIST_ITEM';
  const pickTourHasNoItems = pickTour.pickTourList.length === 0;

  const getDestination = (): string | null => {
    if (pickTour.metadata.ordersSummaryData.length > 0 && pickTour.metadata.ordersSummaryData[0].destination) {
      const { destination } = pickTour.metadata.ordersSummaryData[0];
      return destination;
    }
    return null;
  };

  const pickTourDestination = getDestination();

  function determinePickTourType() {
    let destination = '';
    if (pickTour.metadata.ordersSummaryData.length > 0 && pickTour.metadata.ordersSummaryData[0].destination) {
      destination = pickTour.metadata.ordersSummaryData[0].destination;
      destination =
        String(Number(destination)) === 'NaN'
          ? ` | ${destination}`
          : ` | ${String(Number(destination)).padStart(5, '0')}`;
    }
    return `${t(`${translationRoot}.RESUME`)}${destination}`;
  }

  return (
    <li className={styles.li} onClick={() => onSelect()} data-cy={`pickTour-list-item-${pickTour.pickTourID}`}>
      <div className={styles.pickInfo}>
        {verifying ? (
          <div className={styles.checkboxArea}>
            <CheckBoxSpinner />
          </div>
        ) : (
          <div className={styles.checkboxArea}>
            {isSelected ? <CheckmarkSquareIcon /> : <div className={styles.circle} data-cy="circle-icon" />}
          </div>
        )}
        <div className={styles.mainArea}>
          <div className={styles.destination} data-cy="pickTour-destination">
            {determinePickTourType()}
          </div>
          <div className={styles.content}>
            <div className={styles.orderType}>
              <div className={styles.icon}>
                {determineIconByOrderType({
                  orderType: pickTourHasNoItems ? pickTour.pickTourType : pickTour.pickTourList[0].orderType,
                })}
              </div>
              <p data-cy="pickTour-type">
                {displayOrderType(
                  pickTourHasNoItems ? pickTour.pickTourType : pickTour.pickTourList[0].orderType,
                  pickTourDestination?.match(epRegEx) !== null ? 'Commercial - Delivery' : null,
                  pickTourDestination
                )}
              </p>
            </div>
            <div className={mergeClassNames(styles.itemsPick, currentLanguage === 'es-MX' && styles.itemsPickSpanish)}>
              <span>{t(`${translationRoot}.ITEMS_TO_PICK`)}</span>
              <span className={styles.quantity}>{pickTour.metadata.unhandledItemsRemaining}</span>
            </div>

            <div>
              <span className={styles.textLight}>{displayDate({ date: pickTour.createTS }).split('-')[0]}</span>
              <span className={styles.pickTourTime}>{displayDate({ date: pickTour.createTS }).split('-')[1]}</span>
            </div>
            <div className={styles.highPriority}>
              <span className={styles.highPriorityIcon}>
                <HighPriorityIcon />
              </span>
              <span className={styles.textLight}>{t(`${translationRoot}.HIGH_PRIORITY`)}</span>
            </div>
          </div>
        </div>
      </div>

      {isSelected && (
        <div className={styles.pickOrdersWrapper}>
          {pickTour.metadata.ordersSummaryData.map(order => (
            <div key={order.orderID} className={styles.pickOrders}>
              <span className={styles.pickOrderDestination}>
                {displayDestination({
                  orderType: pickTour.pickTourType,
                  destination: order.destination,
                })}
              </span>
              <span className={styles.textLight}>{t(`${translationRoot}.QTY`)}</span>
              <span className={styles.textLight}>{order.qty}</span>
            </div>
          ))}
        </div>
      )}
    </li>
  );
};

export default PickTourItem;
