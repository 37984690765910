import { AppPage } from "../../types";

type State = {
  title: string,
  text: string,
  textVariables: any,
  show: undefined | boolean,
  redirectPage: AppPage | undefined
}

export enum ActionTypes {
  SHOW_FULLSCREEN_POPUP = "SHOW_FULLSCREEN_POPUP",
  HIDE_FULLSCREEN_POPUP = "HIDE_FULLSCREEN_POPUP",
}

const initialState: State = { title: "", text: "", textVariables: {}, show: undefined, redirectPage: undefined };

export default (state = initialState, action: { type: ActionTypes; payload: State; }) => {
  switch (action.type) {
    case (ActionTypes.SHOW_FULLSCREEN_POPUP): {
      return { ...state, ...action.payload };
    }
    case (ActionTypes.HIDE_FULLSCREEN_POPUP): {
      return { ...state, ...{show: false} };
    }
    default: {
      return state;
    }
  }
};