import { getType } from 'typesafe-actions';

import { createToast, removeToast,removeToastsOnInactivity } from '../actions/toasts.actions';

import { ToastsState } from './types';
import { RootAction } from '../actions/types';

export const initialState: ToastsState = [];

export default (state: ToastsState = initialState, action: RootAction): ToastsState => {
  switch (action.type) {
    case getType(createToast): {
      return [...state, action.payload];
    }
    case getType(removeToast): {
      return state.filter(toast => toast.id !== action.payload.id);
    }

    case getType(removeToastsOnInactivity): {
      return state.slice(-2, state.length);
    }

    default: {
      return state;
    }
  }
};
