import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { mergeClassNames, parseDynamicSlotDetails, stripLeadingZeroes, padTo6LeadingZeroes } from '../../../utils';

import { ORDER_TYPE_OVERSTOCK, POG_OVERSTOCK, POG_DYNAMIC_SLOT } from '../../../constants';

import { OrderType } from '../../../types';

import styles from './DetailsViewCardItemDetails.css';

type Props = {
  planogramName: string;
  sequence: string;
  partNumber: string;
  altPartNumber: string;
  sku: string;
  orderType: OrderType;
};

const DetailsViewCardItemDetails = ({
  planogramName,
  sequence,
  partNumber,
  altPartNumber,
  sku,
  orderType,
}: Props): ReactElement => {
  const displayedPogFogZone =
    planogramName === POG_DYNAMIC_SLOT ? parseDynamicSlotDetails(sequence).zone : planogramName;
  const displayedSequence = planogramName === POG_DYNAMIC_SLOT ? parseDynamicSlotDetails(sequence).slot : sequence;

  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.DETAILS_VIEW';

  return (
    <article className={styles.article}>
      <header className={styles.header}>
        <div className={mergeClassNames(styles.leftContainer, planogramName === POG_DYNAMIC_SLOT ? styles.zone : '')}>
          <div className={styles.pogFogZone}>
            <p>{t(`${translationRoot}.${planogramName === POG_DYNAMIC_SLOT ? 'ZONE' : 'POG_FOG'}`)}</p>
            <h6 className={styles.pog} data-cy="pick-tour-item-pog-fog">
              {orderType === ORDER_TYPE_OVERSTOCK
                ? t(`${translationRoot}.OVERSTOCK`)
                : displayedPogFogZone || t(`${translationRoot}.NO_LOCATION_DATA`)}
            </h6>
          </div>
          {planogramName === POG_DYNAMIC_SLOT ? (
            <div className={styles.aisle}>
              <p>{t(`${translationRoot}.AISLE`)}</p>
              <h6>{parseDynamicSlotDetails(sequence).aisle}</h6>
            </div>
          ) : null}
        </div>
        <div className={styles.slot}>
          <p>
            {t(
              `${translationRoot}.${
                orderType === ORDER_TYPE_OVERSTOCK || [POG_OVERSTOCK, POG_DYNAMIC_SLOT].includes(planogramName)
                  ? 'SLOT'
                  : 'SEQ'
              }`
            )}
          </p>
          <h6 data-cy="pick-tour-item-seq">{displayedSequence}</h6>
        </div>
      </header>
      <section className={styles.section}>
        <p>{t(`${translationRoot}.PART_NUMBER`)}</p>
        <h1 data-cy="pick-tour-item-part-number">{partNumber}</h1>
      </section>
      <footer className={styles.footer}>
        <p>
          {t(`${translationRoot}.ALT_PART_NUMBER`)}:
          <span data-cy="pick-tour-item-alt-part-number">{altPartNumber}</span>
        </p>
        <p>
          {t(`${translationRoot}.SKU`)}:
          <span data-cy="pick-tour-item-sku-number">{padTo6LeadingZeroes(stripLeadingZeroes(sku))}</span>
        </p>
      </footer>
    </article>
  );
};

export default DetailsViewCardItemDetails;
