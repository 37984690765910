import React, { ReactElement } from 'react';

import styles from './icons.css';
import { mergeClassNames } from '../../utils';

type Props = {
  className?: string;
};

const SkipIcon = ({ className }: Props): ReactElement => (
  <svg
    className={mergeClassNames(styles.svg, className)}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1752 20.5282L12 21.3333L12.8248 20.5282C13.0768 20.2826 19 14.4534 19 9.48968C19 5.72792 15.8605 2.66666 12 2.66666C8.1395 2.66666 5 5.72792 5 9.48968C5 14.4534 10.9232 20.2826 11.1752 20.5282ZM12 11.764C13.2887 11.764 14.3333 10.7458 14.3333 9.48968C14.3333 8.2336 13.2887 7.21534 12 7.21534C10.7113 7.21534 9.66667 8.2336 9.66667 9.48968C9.66667 10.7458 10.7113 11.764 12 11.764Z"
      fill="white"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="2" width="14" height="20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1752 20.5282L12 21.3333L12.8248 20.5282C13.0768 20.2826 19 14.4534 19 9.48968C19 5.72792 15.8605 2.66666 12 2.66666C8.1395 2.66666 5 5.72792 5 9.48968C5 14.4534 10.9232 20.2826 11.1752 20.5282ZM12 11.764C13.2887 11.764 14.3333 10.7458 14.3333 9.48968C14.3333 8.2336 13.2887 7.21534 12 7.21534C10.7113 7.21534 9.66667 8.2336 9.66667 9.48968C9.66667 10.7458 10.7113 11.764 12 11.764Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)" />
  </svg>
);

export default SkipIcon;
