import React, { ReactElement } from 'react';

import { SkipReason } from '../../../../../types';

import styles from './SkipMenu.css';

type SkipOption = {
  name: string;
  skipReason: SkipReason;
};

type Props = {
  options: SkipOption[];
  onSkip: (skipReason: SkipReason) => void;
};

const SkipMenu = ({ options, onSkip }: Props): ReactElement | null => (
  <ul className={styles.list}>
    {options.map((option: SkipOption) => (
      <li
        key={option.name}
        className={styles.item}
        onClick={() => onSkip(option.skipReason)}
        data-cy={`${option.name}_skip-option`}
      >
        {option.name}
      </li>
    ))}
  </ul>
);

export default SkipMenu;
