import { createStore, applyMiddleware } from 'redux';
import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './reducers/rootReducer';
import rootSaga from './sagas/rootSaga';

const nodeEnv = process.env.NODE_ENV;
const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware);
const store = createStore(rootReducer, nodeEnv === 'development' ? composeWithDevTools(middleware) : middleware);

// This circumvents an issue when testing sagas that causes an import to occur before it's exported
if (nodeEnv !== 'test') {
  sagaMiddleware.run(rootSaga);
}

export default store;
