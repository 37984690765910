import React, { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import Button from '../../../buttons/Button';
import { mergeClassNames } from '../../../../utils';

import { updatePickTour } from '../../../../redux/actions/activePickTour.actions';

import { UpdateType, UpdateStatus } from '../../../../redux/actions/activePickTour.types';

import styles from './ExitPickTour.css';
import useInactivePickTourKickout from '../../../../hooks/useInactivePickTourKickout';
import { getConfigAuthValuesFromState } from '../../../../redux/selectors';

type Props = {
  determinePickTourStatus: (updateType: UpdateType) => UpdateStatus;
  currentLanguage: string;
};

const ExitPickTour = ({ determinePickTourStatus, currentLanguage }: Props): ReactElement => {
  const configAuthValues = useSelector(getConfigAuthValuesFromState);
  const { t } = useTranslation();
  const translationRoot = 'PICK_TOUR_COMPLETE_OR_EXIT';
  const INACTIVITY_TIMEOUT = 1000 * 60 * (configAuthValues?.INACTIVITY_TIMEOUT || 10);
  const dispatch = useDispatch();

  useInactivePickTourKickout(INACTIVITY_TIMEOUT)

  return (
    <section className={styles.section}>
      <h1 className={mergeClassNames(styles.h1, currentLanguage === 'es-MX' && styles.h1Spanish)}>
        {t(`${translationRoot}.EXIT_PICK_TOUR`)}
      </h1>
      <p className={styles.p}>{t(`${translationRoot}.EXIT_PICK_TOUR_CONFIRMATION`)}</p>
      <Button
        className={styles.button}
        onClick={() => {
          dispatch(updatePickTour({ updateType: 'exit', updateStatus: determinePickTourStatus('exit') }));
          // TODO: It's possible we need to re-implement the routing to CONFIRM_STAGED.
          // if (resolvedPickPickedQuantity === 0) {
          //   dispatch(updatePickTour({ updateType: 'exit', updateStatus: determinePickTourStatus('exit') }));
          // } else {
          //   dispatch(setPickTourPage({ name: PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED }));
          // }
        }}
        data-cy="pick-tour-confirm-exit-button"
      >
        {t(`${translationRoot}.EXIT_PICK_TOUR`)}
      </Button>
    </section>
  );
};

export default ExitPickTour;
