import React, { ReactElement, SetStateAction } from 'react';
import { useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import ManualEntryScreen from '../../layout/ManualEntryScreen';

import { setPickerStore } from '../../../redux/actions/pickerStore.actions';
import { createToast } from '../../../redux/actions/toasts.actions';

import { setInputIfDigits, stripLeadingZeroes, padTo8LeadingZeroes } from '../../../utils';

import { NavMenuTab } from '../../../types';

type Props = {
  storeInputValue: string;
  firstStoreID: string;
  setStoreInputValue: (value: SetStateAction<string>) => void;
  setFirstStoreID: (value: SetStateAction<string>) => void;
  setActiveNavTab?: (value: SetStateAction<NavMenuTab>) => void;
};

const StoreLocation = ({
  storeInputValue,
  firstStoreID,
  setStoreInputValue,
  setFirstStoreID,
  setActiveNavTab,
}: Props): ReactElement => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const translationRoot = 'WAFFLE_MENU';

  return (
    <ManualEntryScreen
      buttonText={t(`${translationRoot}.${firstStoreID ? 'CONFIRM' : 'SUBMIT'}`)}
      disabled={storeInputValue === '' || Number(storeInputValue) === 0}
      inputValue={storeInputValue}
      type="number"
      label={t(`${translationRoot}.${firstStoreID ? 'CONFIRM_STORE_NUMBER' : 'ENTER_STORE_NUMBER'}`)}
      memo={
        firstStoreID
          ? t(`${translationRoot}.CONFIRM_ENTER_STORE_NUMBER`, { storeID: stripLeadingZeroes(firstStoreID) })
          : t(`${translationRoot}.ENTER_STORE_LOCATION`)
      }
      handleSubmit={() => {
        if (firstStoreID) {
          if (stripLeadingZeroes(firstStoreID) === stripLeadingZeroes(storeInputValue)) {
            dispatch(setPickerStore({ storeID: padTo8LeadingZeroes(storeInputValue) }));
            setStoreInputValue('');
            setFirstStoreID('');
            if (setActiveNavTab) {
              setActiveNavTab(null);
            }
          } else {
            dispatch(
              createToast({
                type: 'error',
                message: t('ERRORS.STORE_NO_MATCH'),
              })
            );
            setStoreInputValue('');
            setFirstStoreID('');
          }
        } else {
          setFirstStoreID(storeInputValue);
          setStoreInputValue('');
        }
      }}
      placeholder={t(`${translationRoot}.STORE_NUMBER`)}
      handleInputChange={value => {
        if (value.length < 9) setInputIfDigits({ inputSetter: setStoreInputValue, value })
      }}
    />
  );
};

export default StoreLocation;
