import i18n from './i18n';
import { APIError } from './types';
import {
  COMMERCIAL_ORDER_SERVICE_DOWN,
  CUSTOMER_FILE_SERVICE_DOWN,
  EDFS_SERVICE_DOWN,
  GENERIC_NON_SERVICE_ERROR,
  INVALID_PICKTOUR,
  ITEM_SERVICE_DOWN,
  NO_ITEMS_NO_PRINT,
  NO_OVERSTOCK_LOCATIONS,
  NO_OVERSTOCK_ORDERS_GENERATED,
  ORDERTYPE_NOT_ENABLED,
  OVERSTOCK_PICKING_NOT_ENABLED,
  PICKTOUR_CLOSED,
  PICKTOUR_NOT_AVAILABLE,
  PLANOGRAM_SERVICE_DOWN,
  PREVIOUS_OVERSTOCK_TOUR_PENDING,
  PRINTING_SERVICE_DOWN,
  PRINT_SERVICE_ERROR,
  REPLENISHMENT_ORDER_SERVICE_DOWN,
  SAT2SAT_PICKING_NOT_ENABLED,
  SMART_SOURCING_SERVICE_DOWN,
  STORE_INVENTORY_MANAGEMENT_SERVICE_DOWN,
  CENTRALIZED_INVENTORY_SERVICE_DOWN,
  RECALL_ERROR,
  ORDER_SERVICE_ERROR,
  PROXIMITY_SERVICE_ERROR
} from './constants';

const translationRoot = 'ERRORS';

const mapGenericError = (error: APIError) => {
  switch (error.detail) {
    case NO_OVERSTOCK_ORDERS_GENERATED: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.NO_OVERSTOCK_ORDERS_GENERATED`);
    }
    case PREVIOUS_OVERSTOCK_TOUR_PENDING: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.PREVIOUS_OVERSTOCK_TOUR_PENDING`);
    }
    case ORDERTYPE_NOT_ENABLED: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.ORDERTYPE_NOT_ENABLED`);
    }
    case OVERSTOCK_PICKING_NOT_ENABLED: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.OVERSTOCK_PICKING_NOT_ENABLED`);
    }
    case SAT2SAT_PICKING_NOT_ENABLED: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.SAT2SAT_PICKING_NOT_ENABLED`);
    }
    case INVALID_PICKTOUR: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.INVALID_PICKTOUR`);
    }
    case PICKTOUR_NOT_AVAILABLE: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.PICKTOUR_NOT_AVAILABLE`);
    }
    case PICKTOUR_CLOSED: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.PICKTOUR_CLOSED`);
    }
    case NO_ITEMS_NO_PRINT: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.NO_ITEMS_NO_PRINT`);
    }
    case NO_OVERSTOCK_LOCATIONS: {
      return i18n.t(`${translationRoot}.GENERIC_NON_SERVICE_ERRORS.OVERSTOCK_MISSING_LOCATIONS`);
    }
    default:
      return i18n.t(`${translationRoot}.DEFAULT_ERROR_MESSAGE`);
  }
};

const mapAPIError = (error: APIError) => {
  switch (error.code) {
    case COMMERCIAL_ORDER_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.COMMERCIAL_ORDER_SERVICE_DOWN`);
    }
    case PROXIMITY_SERVICE_ERROR: {
      return i18n.t(`${translationRoot}.API_ERRORS.PROXIMITY_SERVICE_ERROR`);
    }
    case ORDER_SERVICE_ERROR: {
      return i18n.t(`${translationRoot}.API_ERRORS.ORDER_SERVICE_ERROR`);
    }
    case REPLENISHMENT_ORDER_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.REPLENISHMENT_ORDER_SERVICE_DOWN`);
    }
    case STORE_INVENTORY_MANAGEMENT_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.STORE_INVENTORY_MANAGEMENT_SERVICE_DOWN`);
    }
    case CENTRALIZED_INVENTORY_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.CENTRALIZED_INVENTORY_SERVICE_DOWN`);
    }
    case PLANOGRAM_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.PLANOGRAM_SERVICE_DOWN`);
    }
    case ITEM_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.ITEM_SERVICE_DOWN`);
    }
    case SMART_SOURCING_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.SMART_SOURCING_SERVICE_DOWN`);
    }
    case PRINTING_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.PRINTING_SERVICE_DOWN`);
    }
    case GENERIC_NON_SERVICE_ERROR: {
      return mapGenericError(error);
    }
    case CUSTOMER_FILE_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.CUSTOMER_FILE_SERVICE_DOWN`);
    }
    case EDFS_SERVICE_DOWN: {
      return i18n.t(`${translationRoot}.API_ERRORS.EDFS_SERVICE_DOWN`);
    }
    case RECALL_ERROR: {
      return i18n.t(`${translationRoot}.API_ERRORS.RECALL_ERROR`);
    }
    case PRINT_SERVICE_ERROR: {
      return i18n.t(`${translationRoot}.API_ERRORS.PRINT_SERVICE_ERROR`);
    }
    default: {
      return i18n.t(`${translationRoot}.DEFAULT_ERROR_MESSAGE`);
    }
  }
};

export default mapAPIError;
