import { createAction } from 'typesafe-actions';

import {
  GetOrdersPayload,
  GetRecallOrdersPayload,
  GetCompletedRecallOrdersPayload,
  SetOrdersPayload,
  RetryOrderPayload,
  SetHasManualOverstockOrderPayload,
  SetOrderStatusPayload
} from './orders.types';

export const getOrders = createAction('GET_ORDERS')<GetOrdersPayload>();

export const getRecallOrders = createAction('GET_RECALL_ORDERS')<GetRecallOrdersPayload>();

export const getCompletedRecalls = createAction('GET_COMPLETED_RECALL_ORDERS')<GetCompletedRecallOrdersPayload>();

export const setOrders = createAction('SET_ORDERS')<SetOrdersPayload>();

export const retryOrder = createAction('RETRY_ORDER')<RetryOrderPayload>();

export const clearSelected = createAction("CLEAR_SELECTED")<boolean>();

export const setHasManualOverstockOrder = createAction('SET_HAS_MANUAL_OVERSTOCK_ORDER')<
  SetHasManualOverstockOrderPayload
>();


export const setOrderStatus = createAction('SET_ORDER_STATUS')<SetOrderStatusPayload>();