import React, { ReactElement, Fragment, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import IconButton from '../../buttons/IconButton';
import Logo from '../Logo';
import ArrowForwardIcon from '../../../assets/icons/ArrowForwardIcon';
import XIcon from '../../../assets/icons/XIcon';
import { mergeClassNames } from '../../../utils';

import styles from './NavMenu.css';
import LanguageToggle from '../LanguageToggle';


type Props = {
  isOpened: boolean;
  navTabs: { id: string; text: string; disabled?: boolean; hasArrow?: boolean; onClick: () => void }[];
  handleOnClick: () => void;
  setIsAnimating: (value: SetStateAction<boolean>) => void;
};

const NavMenu = ({ isOpened, navTabs, setIsAnimating, handleOnClick }: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'WAFFLE_MENU';
  return (
    <div className={styles.container}>
      <aside
        onAnimationEnd={() => setIsAnimating(false)}
        className={mergeClassNames(styles.aside, isOpened ? styles.open : styles.closed)}
      >
        <header className={styles.header} onAnimationEnd={() => setIsAnimating(false)}>
          {isOpened ? (
            <>
              <Logo />
              <h1 className={styles.h1}>{t(`${translationRoot}.MENU`)}</h1>
              <IconButton
                className={styles.iconButton}
                icon={<XIcon />}
                onClick={handleOnClick}
                data-cy="nav-menu-close-button"
              />
            </>
          ) : null}
        </header>
        <nav className={styles.nav}>
          <div>
          {navTabs.map(navTab => (
            <Fragment key={navTab.id}>
              <hr className={styles.hr} />
              <button
                disabled={navTab.disabled}
                type="button"
                className={styles.button}
                onClick={navTab.onClick}
                data-cy={`nav-button-${navTab.id}`}
              >
                {navTab.text}
                {navTab.hasArrow && !navTab.disabled && <ArrowForwardIcon />}
              </button>
            </Fragment>
          ))}
          <hr className={styles.hr} /></div>
         
         <div className={styles.languageToggleContainer}>
         <LanguageToggle />
         </div>
         
        </nav>
      </aside>
    </div>
  );
};

export default NavMenu;
