import React, { ReactElement } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18next from 'i18next';

import LanguageIcon from '../../../assets/icons/LanguageIcon';

import { setCurrentLanguage } from '../../../redux/actions/currentLanguage.actions';


import { selectCurrentLanguage } from '../../../redux/selectors';

import { Language } from '../../../types';

import { mergeClassNames } from '../../../utils';

import styles from './LanguageToggle.css';


const LanguageToggle = (): ReactElement => {


  const currentLanguage = useSelector(selectCurrentLanguage);

  const dispatch = useDispatch();


  const assignLanguage = (language: Language) => {
    i18next.changeLanguage(language);
    dispatch(setCurrentLanguage(language));
  };



  return (
    <div className={styles.ovalRow}>

      <div className={mergeClassNames(styles.languageButton, currentLanguage === 'en-US' && styles.selected)} onClick={() => {
        assignLanguage('en-US');

      }}>
        <LanguageIcon isSelected={currentLanguage === 'en-US'} />
        <span className={styles.languageTitle}> ENGLISH</span>
      </div>
      <div className={mergeClassNames(styles.languageButton, currentLanguage === 'es-MX' && styles.selected)} onClick={() => {
        assignLanguage('es-MX');

      }}>
        <LanguageIcon isSelected={currentLanguage === 'es-MX'} />
        <span className={styles.languageTitle}>ESPAÑOL</span>
      </div>
    </div>
  )
};

export default LanguageToggle;
