import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import i18next from 'i18next';

import { RootState } from '../reducers/rootReducer';
import { setCurrentLanguage } from '../actions/currentLanguage.actions';
import { getEmployeeMetadata, storeDetectionError, promptForStoreVerification } from '../actions/pickerStore.actions';
import { setLoading } from '../actions/loading.actions';
import { createToast } from '../actions/toasts.actions';

import { SAGA_GET_EMPLOYEE_METADATA, BFF_ROOT, BFF_USERS, BFF_V1 } from '../../constants';

import { ConfigAuthValues } from '../../types';

import { callAPI, stripLeadingZeroes } from '../../utils';

const callGetEmployeeMetadata = (employeeID: string, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'GET',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_USERS}/${employeeID}`,
  });
};

export function* runGetEmployeeMetadataSaga(action: ReturnType<typeof getEmployeeMetadata>) {
  yield put(setLoading({ sagaName: SAGA_GET_EMPLOYEE_METADATA, isLoading: true }));
  const configAuthValues = yield select((state: RootState) => state.config.authValues);

  try {
    const result = yield call(callGetEmployeeMetadata, action.payload.employeeID, configAuthValues);
    const languageToSet = result.data.countryCd === 'MX' ? 'es-MX' : 'en-US';
    yield put(setCurrentLanguage(languageToSet));
    i18next.changeLanguage(languageToSet);
    if (result.data.locationCode === 'STORES') {
      if (!action.payload.isStoreAssigned) {
        yield put(promptForStoreVerification({ storeToVerify: stripLeadingZeroes(result.data.storeID) }));
      }
    } else if (!action.payload.isStoreAssigned) {
      yield put(storeDetectionError());
    }
  } catch (error) {
    if (!action.payload.isStoreAssigned) {
      yield put(storeDetectionError());
    }
  }
  if (action.payload.positionError === 1) {
    yield put(
      createToast({
        type: 'error',
        message: i18next.t(`ERRORS.ENABLE_LOCATION`),
      })
    );
  }

  yield put(setLoading({ sagaName: SAGA_GET_EMPLOYEE_METADATA, isLoading: false }));
}

export default function* watchEmployeeMetadata() {
  yield takeEvery(getType(getEmployeeMetadata), runGetEmployeeMetadataSaga);
}
