import {  CompletedRecallTourPagination } from '../../types';

export const mapGetCompletedRecallToursResponseToSetCompletedRecallToursPayload = (
  response: any
): CompletedRecallTourPagination => {
  
  if(response.status === 204) {
    return {
      links: [],
      content: [],
      page: {
        number: 0,
        totalPages: 0,
        size: 0,
        totalElements: 0
      }
    }
  } 
    return response.data
  
  
}