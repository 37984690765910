import React, { ReactElement } from 'react';

import styles from './icons.css';
import { mergeClassNames } from '../../utils';

type Props = {
  className?: string;
  small?: boolean;
};

const SkipIcon = ({ className, small }: Props): ReactElement => (
  <svg
    className={mergeClassNames(small ? styles.svgSmall : styles.svg, className)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    data-cy="skip-icon"
  >
    <path d="M6 18l8.5-6L6 6v12zM16 6v12h2V6h-2z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
);

export default SkipIcon;
