import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { setDriverLogoffId, setDriverLogoffStatus } from '../actions/driverLogoff.actions';

import { DRIVER_LOGOFF } from '../../constants';

import { ConfigAuthValues } from '../../types';

import { callAPI } from '../../utils';

const postDriverLogoff = (id:string, configAuthValues:ConfigAuthValues) => {
  return callAPI({
    method: 'POST',
    url: `${configAuthValues.AUTH_ROOT}${DRIVER_LOGOFF}`,
    data: {external_id: id}
  });
};

export function* runPostDriverLogoff(action: ReturnType<typeof setDriverLogoffId>) {
  const configAuthValues = yield select((state: RootState) => state.config.authValues);
    try {
        const result = yield call(postDriverLogoff, action.payload, configAuthValues);
        if(result.data.success){
            yield put (setDriverLogoffStatus(result.data))
        }
        else {
            yield put (setDriverLogoffStatus({success: false}))
        }
    } catch (error) {
        yield put (setDriverLogoffStatus({success: false}))
    }
}

export default function* watchPostDriverLogoff() {
  yield takeEvery(getType(setDriverLogoffId), runPostDriverLogoff);
}
