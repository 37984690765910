import { call, put, takeEvery, select } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { RootState } from '../reducers/rootReducer';
import { adjustSlotQty, getPickTourItems } from '../actions/activePickTour.actions';
import { setLoading } from '../actions/loading.actions';
import { mapAdjustSlotQtyPayloadAndStoreIDToAdjustSlotQtyRequest } from './adjustSlotQty.mappers';
import { selectPickerStoreID } from '../selectors';

import { setPickTourPage } from '../actions/pickTourPage.actions';

import {
  SAGA_ADJUST_SLOT_QTY,
  BFF_ROOT,
  BFF_V1,
  BFF_PICK_TOURS,
  BFF_SLOTS,
  PICK_TOUR_PAGE_UPDATE_FAILED,
} from '../../constants';

import { AdjustSlotQtyPayload } from '../actions/activePickTour.types';
import { AdjustSlotQtyRequest, AdjustSlotQtyResponse } from './types';
import { ConfigAuthValues } from '../../types';

import { callAPI } from '../../utils';
import { handleApiErrors, catchApiErrors } from './utils';

const callAdjustSlotQty = (data: AdjustSlotQtyRequest, configAuthValues: ConfigAuthValues) => {
  return callAPI({
    method: 'PUT',
    url: `${configAuthValues.API_ROOT}${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}${BFF_SLOTS}`,
    data,
  });
};

export function* runAdjustSlotQty(action: { type: string; payload: AdjustSlotQtyPayload }) {
  yield put(setLoading({ sagaName: SAGA_ADJUST_SLOT_QTY, isLoading: true }));
  const configAuthValues = yield select((state: RootState) => state.config.authValues);
  const storeID: string = yield select(selectPickerStoreID);
  const pickTourID: string = yield select((state: RootState) => state.activePickTour?.data.pickTourID);
  const currentPage: number = yield select((state: RootState) =>
    state.activePickTour?.isPaginated ? state.activePickTour.paginationMetadata.pageNumber : 0
  );

  try {
    const { data }: { data: AdjustSlotQtyResponse } = yield call(
      callAdjustSlotQty,
      mapAdjustSlotQtyPayloadAndStoreIDToAdjustSlotQtyRequest(action.payload, storeID),
      configAuthValues
    );
    // TODO: Validate API response
    yield put(
      getPickTourItems({
        isPickTourCreation: false,
        page: currentPage,
        pickTourID,
        orderType: 'OVERSTOCK',
      })
    );
    yield handleApiErrors(data.errors);
  } catch (error) {
    // May need some kind of special error handling?
    yield put(setPickTourPage({ name: PICK_TOUR_PAGE_UPDATE_FAILED }));
    try {
      const data = mapAdjustSlotQtyPayloadAndStoreIDToAdjustSlotQtyRequest(action.payload, storeID);
      const url = `${BFF_ROOT}${BFF_V1}${BFF_PICK_TOURS}${BFF_SLOTS}`
      const method = 'PUT';
      const config = configAuthValues;
      yield catchApiErrors(error, {url, method, data, config, saga: SAGA_ADJUST_SLOT_QTY});
    } catch (_error) {
      yield catchApiErrors(error);
    }
  }

  yield put(setLoading({ sagaName: SAGA_ADJUST_SLOT_QTY, isLoading: false }));
}

export default function* watchAdjustSlotQty() {
  yield takeEvery(getType(adjustSlotQty), runAdjustSlotQty);
}
