import React, { ReactElement, ReactNode } from 'react';

import styles from './Tabs.css';

type TabProps = {
  children: ReactNode;
  icon?: ReactNode;
  label: string;
  displayName: string;
};

export type TabsProps<T extends string> = {
  activeTab: T;
  children: ReactElement<TabProps>[];
  setActiveTab: (label: T) => void;
};

export const Tab = ({ children }: TabProps): ReactElement => <>{children}</>;

const Tabs = <T extends string>({ activeTab, children, setActiveTab }: TabsProps<T>): ReactElement => {
  return (
    <>
      <section className={styles.section}>
        {React.Children.map(children, child => {
          const { children: tabChildren, icon, displayName, label, ...other } = child.props;
          return (
            <button
              type="button"
              className={activeTab === label ? styles.buttonActive : styles.button}
              // TODO: Need to properly type this.
              onClick={() => setActiveTab(label as T)}
              {...other}
            >
              {icon}
              {displayName}
            </button>
          );
        })}
      </section>
      {React.Children.toArray(children).find(child => child.props.label === activeTab)}
    </>
  );
};

export default Tabs;
