import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import PickItemList from '../../pages/PickTourFlow/ActivePickTour/PickItemList';
import TourQuantityBox from '../TourQuantityBox';
import ListIcon from '../../../assets/icons/ListIcon';
import PrinterIcon from '../../../assets/icons/PrinterIcon';
import PaginationBar from '../PaginationBar';

import { Pick, PickTourPage, PaginationData } from '../../../types';
import { mergeClassNames } from '../../../utils';

import { PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED } from '../../../constants';

import styles from './Aside.css';

type Props = {
  pickTourPage: PickTourPage;
  picks: Pick[];
  pickedQuantity: number;
  totalQuantity: number;
  hasJustScanned: boolean;
  paginationData: PaginationData;
  isTourComplete: boolean;
  printPickList: () => void;
  onPageChange: (page: number) => void;
  currentLanguage: string;
};

const Aside = ({
  pickTourPage,
  picks,
  pickedQuantity,
  totalQuantity,
  hasJustScanned,
  paginationData,
  isTourComplete,
  printPickList,
  onPageChange,
  currentLanguage,
}: Props): ReactElement => {
  const { t } = useTranslation();
  const translationRoot = 'ACTIVE_PICK_TOUR.LIST_VIEW';
  return (
    <aside className={styles.container}>
      <header className={mergeClassNames(styles.header, currentLanguage === 'es-MX' && styles.headerSpanish)}>
        <div className={styles.left}>
          <ListIcon />
          <h2>{t(`${translationRoot}.LIST_VIEW_TAB`)}</h2>
        </div>
        <TourQuantityBox
          pickedQuantity={pickedQuantity}
          totalQuantity={totalQuantity}
          hasJustScanned={hasJustScanned}
          currentLanguage={currentLanguage}
        />
        <div className={styles.right} onClick={printPickList}>
          <PrinterIcon />
          <h2>{t(`${translationRoot}.PRINT`)}</h2>
        </div>
      </header>
      <PickItemList
        picks={picks}
        isTourComplete={isTourComplete}
        isActivePickTourPage={pickTourPage === 'pickTour'}
        isPaginated={paginationData.isPaginated}
      />
      {paginationData.isPaginated && (
        <PaginationBar
          isDisabled={pickTourPage !== PICK_TOUR_PAGE_EXIT_CONFIRM_STAGED && !isTourComplete}
          currentPage={paginationData.pageNumber + 1}
          totalPages={paginationData.totalPages}
          onPageChange={onPageChange}
        />
      )}
    </aside>
  );
};

export default Aside;
