import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import Button from '../../buttons/Button';

import styles from './ItemValidation.css';

type Props = {
  onContinue: () => void;
};

const ItemValidation = (props: Props): ReactElement => {
  const { onContinue } = props;
  const { t } = useTranslation();
  const translationRoot = 'PICK_TOUR_COMPLETE_OR_EXIT';

  return (
    <section className={styles.section}>
      <article className={styles.article}>
        <h1 className={styles.h1}>{t(`${translationRoot}.ORDER_VALIDATION`)}</h1>
        <p className={styles.p}>{t(`${translationRoot}.REQUIRE_VALIDATION`)}</p>
      </article>
      <footer className={styles.footer}>
        <Button className={styles.button} onClick={onContinue}>
          {t(`${translationRoot}.CONTINUE`)}
        </Button>
      </footer>
    </section>
  );
};

export default ItemValidation;
