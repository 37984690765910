
/* RefreshButton.container.ts
 * Copyright 2022 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */

import { connect } from 'react-redux';
import { RootState } from '../../../redux/reducers/rootReducer';
import RefreshButton, { StateProps } from "./RefreshButton";

const mapStateToProps = (state: RootState): StateProps => ({
    lastAttemptSuccess: state.orders.lastAttemptSuccess,
    lastAttemptTimeStamp: state.orders.lastAttemptTimeStamp,
    pickerStoreID: state.pickerStore.storeID
})

export default connect(mapStateToProps)(RefreshButton)