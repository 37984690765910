import { createAction } from 'typesafe-actions';
import uuidv1 from 'uuid/v1';

import { CreateToastPayload, CreateToastEnhancedPayload, RemoveToastPayload } from './toasts.types';

export const createToast = createAction('CREATE_TOAST', (payload: CreateToastPayload) => ({
  ...payload,
  id: uuidv1(),
}))<CreateToastEnhancedPayload>();

export const removeToast = createAction('REMOVE_TOAST')<RemoveToastPayload>();

export const removeToastsOnInactivity = createAction('REMOVE_TOAST_ON_INACTIVITY')();
